import React, { useState } from 'react';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../core/components/Button/Button';
import SimpleInput from '../../core/components/Forms/SimpleInput';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import CreditorFileUploadModuleApi from './api/CreditorFileUploadModuleApi';
import { Status } from '../../core/api/Enums/Status';

const CreditorFileUpload = (props: IExtendedModuleProps) => {
  const module = flatModuleOptions<any>(props.module);
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [isUploading, setIsUploading] = useState(false);
  //@ts-ignore
  const [comment, setComment] = useState('');
  //@ts-ignore
  const [files, setFiles] = useState<FileList>();

  const submitFiles = async () => {
    try {
      if (files) {
        setIsUploading(true);
        const status = await CreditorFileUploadModuleApi.uploadFiles(props.module.id.toString(), comment, files);
        setIsUploading(false);
        InfoMessageService.displayActionStatus(status);
        if (status.status === Status.Success) {
          if (props.exit) {
            props.exit();
          }
        }
      }
    } catch {
      InfoMessageService.error(TranslationService.translate('GeneralErrorOccured'));
    }
  };

  return (
    <div className="cfu">
      <p>
        <input
          type="file"
          ref={fileInputRef}
          multiple
          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();
            if (event.currentTarget.files) {
              if (event.currentTarget.files.length > module.MaximumFilesNumber) {
                InfoMessageService.error(
                  TranslationService.translateModule(
                    'MaximumNumberOfUploadedFilesError',
                    ModuleNamesList.CreditorFileUpload
                  )
                );
                if (fileInputRef && fileInputRef.current) {
                  fileInputRef.current.value = '';
                }
              } else {
                setFiles(event.currentTarget.files);
              }
            }
          }}
        />
      </p>
      <p>
        <SimpleInput
          id="creditor-file-upload-comment"
          value={comment}
          placeholder={TranslationService.translateModule('FileUploadComment', ModuleNamesList.CreditorFileUpload)}
          onChange={(value: string, e: any) => {
            setComment(value);
          }}
        />
      </p>
      <div className="action-buttons">
        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          size={BUTTON_SIZE.MD}
          type="button"
          showLoader={isUploading}
          label={TranslationService.translate('Save')}
          disabled={isUploading}
          onClick={submitFiles}
        />
      </div>
    </div>
  );
};

export default CreditorFileUpload;
