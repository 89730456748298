import React, { useState } from 'react';
import { connect } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import './_DayPickerInput.scss';
// @ts-ignore
import LocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import TranslationService from '../../../core/services/TranslationService';
import { IStore } from '../../../reducers/IStore';
import LocaleCode from 'locale-code';
import styles from './DatePicker.module.scss';
import moment from 'moment';

type PropsType = {
  inputId: string;
  locale: string;
  placeholder?: string;
  inputName?: string;
  className?: string;
  disabledDays?: DisabledDaysType;
  value?: string | Date;
  onChange: (date: Date) => void;
};

type DisabledDaysType = {
  before: Date;
};

function CustomOverlay({ classNames, selectedDay, children, ...props }: any) {
  return (
    <div className={`daypicker-overlay ${classNames.overlayWrapper}`} {...props}>
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

const DatePicker = (props: PropsType) => {
  const [validationMsg, setValidationMsg] = useState<string>('');

  const validationDate = (selectedDay: Date) => {
    const { disabledDays } = props;
    const isInvalid = validationMsg.length > 0;
    let newValidationMsg = '';

    if (selectedDay) {
      if (disabledDays && disabledDays.before) {
        if (moment(selectedDay).isBefore(disabledDays.before, 'day')) {
          newValidationMsg = `${TranslationService.translate('StartDateFrom')} ${moment(disabledDays.before).format('L')}`;
        }
      } else if (isInvalid) {
        newValidationMsg = '';
      }
    } else {
      newValidationMsg = TranslationService.translate('InvalidDateFormat');
    }

    return newValidationMsg;
  };

  const handleOnChange = (selectedDay: Date) => {
    const validationMsg = validationDate(selectedDay);
    const isInvalid = validationMsg.length > 0;

    if (!isInvalid) {
      props.onChange(selectedDay);
    }

    setValidationMsg(validationMsg);
  };

  return (
    <>
      <DayPickerInput
        format="L"
        placeholder={props.placeholder ? props.placeholder : TranslationService.translate('ChooseDate')}
        formatDate={formatDate}
        parseDate={parseDate}
        overlayComponent={CustomOverlay}
        dayPickerProps={{
          disabledDays: props.disabledDays ? props.disabledDays : undefined,
          locale: LocaleCode.getLanguageCode(props.locale),
          localeUtils: LocaleUtils,
        }}
        value={props.value}
        onDayChange={handleOnChange}
        onDayPickerShow={() => {
          const dayPicker = document.getElementById(props.inputId) as HTMLElement;
          const dayPickerRect = dayPicker.getBoundingClientRect();
          const dayPickerOverlay = dayPicker.nextSibling as HTMLElement;

          if (window.innerHeight < dayPickerRect.bottom + dayPickerOverlay.offsetHeight) {
            dayPickerOverlay.style.bottom = '100%';
          }
        }}
        inputProps={{
          id: props.inputId,
          name: props.inputName,
          className: props.className,
          autoComplete: 'off',
        }}
      />

      {validationMsg.length > 0 && <div className={styles.errorField}>{validationMsg}</div>}
    </>
  );
};

const mapStateToProps = (state: IStore) => {
  const splittedLocale = state.currentCultureCode.split('-');
  return {
    locale: splittedLocale[0] && splittedLocale[1] ? `${splittedLocale[0]}-${splittedLocale[1].toUpperCase()}` : '',
  };
};
export default connect(mapStateToProps)(DatePicker);
