import React from 'react';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE, BUTTON_COLORS } from '../../core/components/Button/Button';
import { emptyComponentTable, IComponentTable } from '../../core/types/IComponentTable';
import { emptyFilterRequest, ICreditorsFilterRequest } from './types/ICreditorsFilterRequest';
import { IBaseCreditorDetails } from './types/IBaseCreditorDetails';
import { ICreditorIdentyfication } from './types/ICreditorIdentyfication';
import { IListInputElement } from 'core/components/Forms/types/IListInputElement';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IRole } from './types/IRole';
import { ModuleNamesList } from 'core/lists/ModuleNamesList';
import { Status } from 'core/api/Enums/Status';
import AssignCreditors from './components/AssignCreditors';
import CreditorsAssignmentApiClient from './CreditorsAssignmentApiClient';
import ErrorResponseHandler from '../../core/services/ErrorResponseHandler';
import InfoMessageService from 'core/services/InfoMessageService';
import Loader from '../../core/components/Loading/Loader';
import ManageCreditors from './components/ManageCreditors';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import TranslationService from 'core/services/TranslationService';

interface ICreditorsAssignment extends IModuleProps {
  creditors?: IBaseCreditorDetails[];
  userUId: string;
}

interface IState {
  assignedCreditorsTable: IComponentTable<IBaseCreditorDetails>;
  availableCreditorsRoles: IListInputElement[];
  creditorsTable: IComponentTable<IBaseCreditorDetails>;
  creditorsTableDisplayedData: IBaseCreditorDetails[];
  filterRequest: ICreditorsFilterRequest;
  isLoading: boolean;
  hasAccess: boolean;
}
class CreditorsAssignment extends React.Component<ICreditorsAssignment, IState> {
  assignCreditors = React.createRef<any>();
  removeAssignment = React.createRef<any>();

  state: IState = {
    assignedCreditorsTable: { ...emptyComponentTable },
    availableCreditorsRoles: [],
    creditorsTable: { ...emptyComponentTable },
    creditorsTableDisplayedData: [],
    filterRequest: { ...emptyFilterRequest },
    isLoading: true,
    hasAccess: true,
  };

  componentDidMount = async () => {
    try {
      const initialData = await CreditorsAssignmentApiClient.getInitialData(this.props.module.id, this.props.userUId);

      let assignedCreditorsUIDs = initialData.assignedCreditors.map((item: ICreditorIdentyfication) => item.domainId);


      const availableCreditorsRoles = initialData.availableCreditorRoles.map((item: IRole) => {
        return { key: item.uId, value: item.displayName };
      });
      this.setState(
        {
          isLoading: false,
          availableCreditorsRoles,
        },
        () => this.setCreditorsListsValues(initialData.creditorsTable, assignedCreditorsUIDs)
      );
      this.filterCreditors();
    } catch (error: any) {
      const hasAccess = ErrorResponseHandler.hasAccess(error);
      this.setState({
        isLoading: false,
        hasAccess,
      });
    }
  };

  render() {
    if (!this.state.isLoading && !this.state.hasAccess) {
      return <NoPermissionsComponent />;
    }

    return (
      <div className="creditors-assignment">
        <div className="c-assignment">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="c-assignment__source">
                <AssignCreditors
                  ref={this.assignCreditors}
                  availableCreditorsRoles={this.state.availableCreditorsRoles}
                  creditorsTableColumns={this.state.creditorsTable.columns}
                  creditorsFilteredValues={this.state.creditorsTableDisplayedData}
                  updateCreditorsToAssignList={this.updateCreditorsToAssignList}
                  userUId={this.props.userUId}
                />
              </div>
            </div>

            <div className="col-12 col-md-2">
              <div className="c-assignment__actions">
                <Button
                  id="assignCreditorBtn"
                  className="mb-2"
                  variant={BUTTON_VARIANTS.ICON}
                  color={BUTTON_COLORS.PRIMARY}
                  size={BUTTON_SIZE.MD}
                  icon={{ className: 'fas fa-chevron-right', position: '' }}
                  onClick={this.handleAssignClick}
                />

                <Button
                  id="removeAssignCreditorBtn"
                  variant={BUTTON_VARIANTS.ICON}
                  size={BUTTON_SIZE.MD}
                  icon={{ className: 'fas fa-chevron-left', position: '' }}
                  onClick={this.handleRemoveAssignClick}
                />
              </div>
            </div>

            <div className="col-12 col-md-5">
              <div className="c-assignment__target">
                <ManageCreditors
                  ref={this.removeAssignment}
                  availableCreditorsRoles={this.state.availableCreditorsRoles}
                  creditorsTable={this.state.assignedCreditorsTable}
                  userUId={this.props.userUId}
                  removeCreditorAssignment={this.removeCreditorAssignment}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.isLoading && <Loader opacity={1} />}
      </div>
    );
  }

  handleAssignClick = () => {
    this.assignCreditors.current.assignCreditors();
  };

  handleRemoveAssignClick = () => {
    this.removeAssignment.current.showConfirmModal();
  };

  updateCreditorsToAssignList = async (assignedCreditorDomainIds: IBaseCreditorDetails[]) => {
    const assignedCreditorsUIDs = this.state.assignedCreditorsTable.values
      .map((item) => item.domainId)
      .concat(assignedCreditorDomainIds.map((item) => item.domainId));
    const allCreditors = {
      columns: this.state.creditorsTable.columns,
      values: this.state.creditorsTable.values.concat(this.state.assignedCreditorsTable.values),
    };
    this.setCreditorsListsValues(allCreditors, assignedCreditorsUIDs);
    this.filterCreditors();
    InfoMessageService.success(
      TranslationService.translateModule('CreditorAssignmentAddedSucessfully', ModuleNamesList.CreditorsAssignment)
    );
  };

  removeCreditorAssignment = async (creditorDomainIds: string[]) => {
    const result = await CreditorsAssignmentApiClient.removeCreditorAssignment(creditorDomainIds, this.props.userUId);
    if (result.status === Status.Success) {
      const assignedCreditorsUIDs = this.state.assignedCreditorsTable.values
        .map((item) => item.domainId)
        .filter((item) => !creditorDomainIds.includes(item));
      const allCreditors = {
        columns: this.state.creditorsTable.columns,
        values: this.state.creditorsTable.values.concat(this.state.assignedCreditorsTable.values),
      };
      this.setCreditorsListsValues(allCreditors, assignedCreditorsUIDs);
      this.filterCreditors();
      InfoMessageService.success(
        TranslationService.translateModule('CreditorAssignmentRemovedSucessfully', ModuleNamesList.CreditorsAssignment)
      );
    } else {
      InfoMessageService.error(
        TranslationService.translateModule('ErrorRemoveCreditorAssignment', ModuleNamesList.CreditorsAssignment)
      );
    }
  };

  filter = async (filterRequest: ICreditorsFilterRequest) => {
    this.setState(
      {
        filterRequest,
      },
      () => this.filterCreditors()
    );
  };

  setCreditorsListsValues = (creditorsTable: IComponentTable, assignedCreditorsUIDs: string[]) => {
    const assignedCreditors = creditorsTable.values
      .filter((item: IBaseCreditorDetails) => assignedCreditorsUIDs.includes(item.domainId))
      .sort(this.sortAsc);
    const availableCreditors = creditorsTable.values
      .filter((item: IBaseCreditorDetails) => !assignedCreditorsUIDs.includes(item.domainId))
      .sort(this.sortAsc);

    const columns = creditorsTable.columns.map((c) => ({
      ...c,
      searchable: true,
    }));

    this.setState({
      creditorsTable: {
        columns,
        values: availableCreditors,
      },
      assignedCreditorsTable: {
        columns,
        values: assignedCreditors,
      },
    });
  };

  filterCreditors = () => {
    let creditorsData = this.state.creditorsTable.values;
    if (this.state.filterRequest.filteredByValue && this.state.filterRequest.filteredByValue !== '') {
      creditorsData = creditorsData.filter(
        (item: IBaseCreditorDetails) =>
          item.domainId.includes(this.state.filterRequest.filteredByValue) ||
          item.organizationName.includes(this.state.filterRequest.filteredByValue)
      );
    }

    this.setState({
      creditorsTableDisplayedData: creditorsData,
    });
  };

  sortAsc = (a: IBaseCreditorDetails, b: IBaseCreditorDetails) => {
    if (a.organizationName < b.organizationName) {
      return -1;
    }
    if (a.organizationName > b.organizationName) {
      return 1;
    }
    return 0;
  };
}

export default CreditorsAssignment;
