import React, { useEffect, useState, useRef } from 'react';
import { Card } from '../../core/components/Card/Card';
import { CardContent } from '../../core/components/Card/components/CardContent';
import { connect } from 'react-redux';
import { EventsList } from '../../core/lists/EventsList';
import { ICashflowChartItem } from './types/ICashflowChartItem';
import { CashflowChartSetsType } from './types/CashflowChartSetsType';
import { ICashflowDataCollection } from './types/ICashflowDataCollection';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import { IModuleProps } from '../../core/types/IModuleProps';
import { ISelectedValue } from '../../core/components/Forms/types/ISelectedValue';
import { IStore } from '../../reducers/IStore';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import { SimpleSelect, SimpleSelectVarianstType } from '../../core/components/Forms/SimpleSelect';
import { CardHeader } from '../../core/components/Card/components/CardHeader';
import CashflowApiClient from './api/CashflowApiClient';
import Chart from 'chart.js';
import DateTimeParser from '../../core/helpers/DateTimeParser';
import Loader from '../../core/components/Loading/Loader';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import RestApiClient from '../../core/api/RestApiClient';
import TranslationService from '../../core/services/TranslationService';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';
import moment from 'moment';
import './Cashflow.scss';

type CashflowChartType = {
  sets: CashflowChartSetsType;
  options: {
    label: string;
    fill: string | boolean;
    borderWidth: number;
    borderColor: string;
    pointBackgroundColor: string;
    backgroundColor: string;
  };
};

type cashflowViewsType = {
  value: string;
  key: string;
};

type CashflowPropsType = IModuleProps &
  AbortRequestPropsType & {
    currency: string;
    locale: string;
    currentLanguageCode: string;
  };

const defaultCashflowView = 'daily';

const Cashflow = (props: CashflowPropsType) => {
  let moduleEvents: IModuleEventSubscription[];
  let cashflowEl = useRef<HTMLCanvasElement>(null);

  const CashflowApiClientObj = new CashflowApiClient(props.cancelTokenSource.token);
  const CASHFLOW_VIEWS_NAMES = [
    {
      value: TranslationService.translateModule('ViewModeDaily', ModuleNamesList.Cashflow),
      key: 'daily',
    },
    {
      value: TranslationService.translateModule('ViewModeMonthly', ModuleNamesList.Cashflow),
      key: 'monthly',
    },
    {
      value: TranslationService.translateModule('ViewModeYearly', ModuleNamesList.Cashflow),
      key: 'yearly',
    },
  ];
  const chartUnitTime = 'day';
  const chartColors = ['#003f5f', '#e4ecf2'];
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cashflowData, setCashflowData] = useState<CashflowChartType[]>([]);
  const [cashflowSelectedView, setCashflowSelectedView] = useState<string>(defaultCashflowView);
  const [cashflowChart, setCashflowChart] = useState<any>();
  const [cashflowViews] = useState<cashflowViewsType[]>(CASHFLOW_VIEWS_NAMES);

  const prepareDataForCashflow = (cashflowDatacollections: ICashflowDataCollection[]) => {
    return cashflowDatacollections.map((collection, index: number) => {
      const sets: CashflowChartSetsType = {
        daily: {
          chartDataLabels: [],
          chartDataDataset: [],
        },
        monthly: {
          chartDataLabels: [],
          chartDataDataset: [],
        },
        yearly: {
          chartDataLabels: [],
          chartDataDataset: [],
        },
      };

      Object.keys(collection.data).forEach((set) => {
        // eslint-disable-next-line
        collection.data[set].map((setProp: ICashflowChartItem) => {
          sets[set].chartDataLabels.push(moment(setProp.date).format('YYYY-MM-DD'));
          sets[set].chartDataDataset.push(Math.round(setProp.paid * 100) / 100);
        });
      });

      return {
        sets,
        options: {
          label: TranslationService.translateModule(collection.name, ModuleNamesList.Cashflow),
          fill: '-1',
          borderWidth: 1,
          borderColor: chartColors[index],
          pointBackgroundColor: chartColors[index],
          backgroundColor: chartColors[index],
        },
      };
    });
  };

  const buildCashflowChart = (cashflowData: CashflowChartType[]) => {
    const container = cashflowEl.current;

    if (!container) {
      return;
    }

    if (cashflowChart) {
      cashflowChart.destroy();
    }

    setCashflowSelectedView(defaultCashflowView);

    const chart = new Chart(container, {
      type: 'line',
      data: {
        labels: cashflowData[0].sets[defaultCashflowView].chartDataLabels,
        datasets: [
          {
            label: cashflowData[0].options.label,
            data: cashflowData[0].sets[defaultCashflowView].chartDataDataset,
            fill: 'origin',
            borderWidth: 1,
            borderColor: cashflowData[0].options.borderColor,
            pointBackgroundColor: cashflowData[0].options.pointBackgroundColor,
            backgroundColor: cashflowData[0].options.backgroundColor,
          },
          {
            label: cashflowData[1].options.label,
            data: cashflowData[1].sets[defaultCashflowView].chartDataDataset,
            fill: 'origin',
            borderWidth: 1,
            borderColor: cashflowData[1].options.borderColor,
            pointBackgroundColor: cashflowData[1].options.pointBackgroundColor,
            backgroundColor: cashflowData[1].options.backgroundColor,
          },
        ],
      },
      options: {
        legend: {
          position: 'bottom',
        },
        maintainAspectRatio: false,
        tooltips: {
          mode: 'nearest',
          intersect: false,
          bodyFontColor: '#929597',
          bodyFontFamily: "'PT Sans', sans-serif",
          bodyFontSize: 14,
          borderWidth: 1,
          borderColor: '#dddddd',
          backgroundColor: '#ffffff',
          titleFontColor: '#929597',
          callbacks: {
            title: (tooltipItem: any) => {
              const tooltip = tooltipItem[0];
              return `Date: ${DateTimeParser.toLocaleDateString(tooltip.label)}`;
            },
            label: (tooltipItem: any, data: any) => {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const translation = TranslationService.translateModule(label, ModuleNamesList.Cashflow);

              return `${translation}: ${tooltipItem.value} ${props.currency}`;
            },
          },
          titleFontSize: 12,
          xPadding: 10,
          yPadding: 10,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                padding: 1,
                maxRotation: 0,
              },
              type: 'time',
              time: {
                unit: chartUnitTime,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                maxTicksLimit: 6,
                callback: (value: number) =>
                  Math.floor(value / 1000) > 0
                    ? Math.floor(value / 1000000) > 0
                      ? (value / 1000000).toFixed(0) + 'M'
                      : (value / 1000).toFixed(0) + 'K'
                    : value,
              },
              scaleLabel: {
                display: true,
                labelString: props.currency,
              },
            },
          ],
        },
      },
    });

    setCashflowChart(chart);
  };

  const changeViewMode = (data: ISelectedValue) => {
    const unitMap = {
      daily: 'day',
      monthly: 'month',
      yearly: 'year',
    };

    setCashflowSelectedView(data.key);

    if (cashflowChart) {
      const datasets = cashflowChart.data.datasets.map((set: any, index: number) => {
        return {
          ...set,
          data: cashflowData[index].sets[data.key].chartDataDataset,
        };
      });

      cashflowChart.data.labels = cashflowData[0].sets[data.key].chartDataLabels;
      cashflowChart.data.datasets = datasets;
      cashflowChart.options = {
        ...cashflowChart.options,
        scales: {
          ...cashflowChart.options.scales,
          xAxes: [
            {
              type: 'time',
              time: {
                unit: unitMap[data.key],
              },
            },
          ],
        },
      };

      cashflowChart.update();
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const cashflowDataCollections = await CashflowApiClientObj.getCashflowData(props.module.id);
      const preparedData = prepareDataForCashflow(cashflowDataCollections);
      setCashflowData(preparedData);
    } catch (error: any) {
      if (RestApiClient.isCancelAxiosRequest(error)) {
        // to handle error
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line

    moduleEvents = [{ name: EventsList.CHANGED_CURRENT_CREDITOR, callback: fetchData }];

    ModuleEventSubscriber.registerEvents(moduleEvents);

    fetchData();

    return () => {
      ModuleEventSubscriber.unsubscribeEvents(moduleEvents);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cashflowData && cashflowData.length) {
      buildCashflowChart(cashflowData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashflowData]);

  return (
    <Card class="c-cashflow">
      <>
        <CardHeader>
          <>
            <div className="row align-items-center justify-content-between">
              <div className="col">
                <h1 className="c-heading">
                  {TranslationService.translateModule('CashflowHeader', ModuleNamesList.Cashflow)}
                </h1>
              </div>

              <div className="col col-auto align-self-end ">
                <SimpleSelect
                  id="selected-date"
                  name="cashflowView"
                  value={cashflowSelectedView}
                  variant={SimpleSelectVarianstType.Link}
                  options={cashflowViews}
                  onChangeHandle={changeViewMode}
                />
              </div>
            </div>
          </>
        </CardHeader>

        <CardContent>
          <>
            {isLoading && <Loader opacity={0.5} />}

            <div className="c-cashflow">
              <div className="c-cashflow__body">
                <div className="c-cashflow__chart">
                  <canvas ref={cashflowEl} id="myChart" height="250" />
                </div>
              </div>
            </div>
          </>
        </CardContent>
      </>
    </Card>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    locale: state.currentCultureCode,
    currency: state.currency,
    currentLanguageCode: state.currentCultureCode,
  };
};

export default connect(mapStateToProps)(withAbortRequest(Cashflow));
