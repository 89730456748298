import * as React from 'react';
import TranslationService from '../../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../../core/lists/ModuleNamesList';
import CurrencyParser from '../../../../core/helpers/CurrencyParser';
import { IStore } from '../../../../reducers/IStore';
import { connect } from 'react-redux';

interface IProps {
  totalInvoices: number;
  totalBalance: number;
  totalCapital: number;
  currency: string;
}

const Footer = (props: IProps) => (
  <div className="unit-invoices__footer">
    <div className="row">
      <div className="col-12 col-sm-6 col-lg-4 mb-2 mt-bg-0">
        <div className="summary-box green">
          <div className="icon">
            <i className="far fa-file-alt" />
          </div>
          <div className="box">
            <h3>{props.totalInvoices}</h3>
            <h4>{TranslationService.translateModule('FooterTotalItems', ModuleNamesList.UnitInvoices)}</h4>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-lg-4 mb-2 mt-bg-0">
        <div className="summary-box">
          <div className="icon">
            <i className="fas fa-coins" />
          </div>
          <div className="box">
            <div className="summary-amounts">
              <h3>{CurrencyParser.toLocaleString(props.totalBalance)}</h3>
              <h5>{props.currency}</h5>
            </div>
            <h4>{TranslationService.translateModule('FooterTotalBalance', ModuleNamesList.UnitInvoices)}</h4>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-lg-4 mb-2 mb-sm-0">
        <div className="summary-box">
          <div className="icon">
            <i className="fas fa-coins" />
          </div>
          <div className="box">
            <div className="summary-amounts">
              <h3>{CurrencyParser.toLocaleString(props.totalCapital)}</h3>
              <h5>{props.currency}</h5>
            </div>
            <h4>{TranslationService.translateModule('FooterTotalCapital', ModuleNamesList.UnitInvoices)}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state: IStore) => ({
  currency: state.currency,
});

export default connect(mapStateToProps)(Footer);
