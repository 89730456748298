import * as React from 'react';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { IServicesProps } from '../types/IServicesProps';

class Services extends React.Component<IServicesProps> {
  private validationSchema = yup.object().shape({});

  public render() {
    return (
      <div className="step-content">
        <Formik
          initialValues={{}}
          onSubmit={(values) => {
            // this.props.onCompanyDataFilled(values);
          }}
          validationSchema={this.validationSchema}
        >
          {({ values, errors, touched, handleChange }) => {
            return (
              <Form>
                <header className="step-header">
                  <h2 className="step-title">
                    {TranslationService.translateModule('FinancialHeader', ModuleNamesList.CreditorRegistration)}
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: TranslationService.translateModule(
                        'FinancialDescription',
                        ModuleNamesList.CreditorRegistration
                      ),
                    }}
                  />
                </header>
                <div className="small-form small-form-60">
                  {/* <FormGroupRadioButton 
                                        errors={errors} 
                                        fieldName="vatLiable" 
                                        handleChange={handleChange} 
                                        options={
                                            [
                                                { label:TranslationService.translate('Yes'), value: "true" },
                                                { label:TranslationService.translate('No'), value: "false" }
                                            ]
                                        }
                                        label={TranslationService.translateModule('FinancialVatLiable',ModuleNamesList.CreditorRegistration)} 
                                        touched={touched}
                                        labelClass="col-sm-8"
                                        inputContainerClass="col-sm-4"
                                        value={values.vatLiable}  /> */}
                </div>
                <hr />
                <div className="text-center clearfix mb-3 pb-3">
                  <button onClick={this.props.backToPrevStep} type="button" className="btn btn-back float-left">
                    <i className="fas fa-angle-double-left back-button-arrow" />
                    {' ' + TranslationService.translate('Back')}
                  </button>
                  <button type="submit" className="btn btn-next float-right">
                    {TranslationService.translate('Next') + ' '}
                    <i className="fas fa-angle-double-right next-button-arrow" />
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default Services;
