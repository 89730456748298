import * as React from 'react';
import { connect } from 'react-redux';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import { ISelectedValue } from '../../../core/components/Forms/types/ISelectedValue';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { ICreditor } from '../../../core/types/ICreditor';
import { IDictionary, INumberDictionary } from '../../../core/types/IDictionary';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';
import { IStore } from '../../../reducers/IStore';
import { CollectionNotice } from '../types/CollectionNotice';
import { IInitialSettings } from '../types/IInitialSettings';
import { IClaimInfo } from './IClaimInfo';
import RadioSelectBoxes from './RadioSelectBoxes';

interface IState {
  isLoading: boolean;
  isValid: boolean;
  creditors: IDictionary<string>;
}

interface IProps {
  creditors: ICreditor[];
  currentCreditors: string[];
  claimInfo: IClaimInfo;
  isVisited: boolean;
  moduleSettings: IInitialSettings;
  setClaimInfo(claimInfo: IClaimInfo): void;
  goToNextStep(): void;
}

class NewClaim extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
    isValid: true,
    creditors: {},
  };

  private collectionNotticeOptions: INumberDictionary<string> = {
    [CollectionNotice.YesWithReminderFee]: TranslationService.translate('Yes'),
    [CollectionNotice.No]: TranslationService.translateModule(
      'NewClaimCollectionNoticeNo',
      ModuleNamesList.CreateNewCase
    ),
  };

  componentDidMount() {
    this.availableCreditors();
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.claimInfo !== prevProps.claimInfo) {
      if (
        !this.props.isVisited &&
        this.state.isValid &&
        this.props.claimInfo.selectedCreditor &&
        !this.props.moduleSettings.showExtendedWorkflow
      ) {
        this.props.goToNextStep();
      }
    }
  }

  public render() {
    return (
      <>
        <div className="c-control row mb-3">
          <label className="col-12 col-form-label" htmlFor="available-creditors">
            {TranslationService.translateModule('SelectCreditor', ModuleNamesList.CreateNewCase)}
            <span className="c-control__required-sign" />
          </label>

          <div className="col-12 col-md-6">
            <SimpleSelect
              id="available-creditors"
              enableFilter={true}
              isNotStandard={true}
              emptyFirstRowText={TranslationService.translateModule(
                'NewClaimHeaderSelectCreditor',
                ModuleNamesList.CreateNewCase
              )}
              emptyFirstRowValue=""
              options={this.state.creditors}
              value={this.props.claimInfo.selectedCreditor}
              onChangeHandle={(selectedValue: ISelectedValue) => {
                this.setState({ isValid: !!selectedValue.key });
                this.props.setClaimInfo({
                  ...this.props.claimInfo,
                  selectedCreditor: selectedValue.key,
                });
              }}
            />

            {!this.state.isValid && (
              <span className="error error-message">
                {TranslationService.translateModule('SelectCreditorError', ModuleNamesList.CreateNewCase)}
              </span>
            )}
          </div>
        </div>

        {this.props.moduleSettings.showExtendedWorkflow === true && (
          <>
            <h6 className="create-case-header">
              {TranslationService.translateModule('NewClaimHeaderCollectionNotice', ModuleNamesList.CreateNewCase)}
            </h6>
            <div className="form-group row mb-1 mb-sm-3">
              <div className="col-12">
                <RadioSelectBoxes
                  elements={this.collectionNotticeOptions}
                  value={this.props.claimInfo.collectionNoticeType.toString()}
                  onChange={(collectionNoticeType: string) => {
                    this.props.setClaimInfo({
                      ...this.props.claimInfo,
                      collectionNoticeType: parseInt(collectionNoticeType, 0),
                    });
                  }}
                  name="collectionNoticeType"
                />
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: TranslationService.translateModule(
                  this.getDescriptionTranslationKey(),
                  ModuleNamesList.CreateNewCase
                ),
              }}
            />
          </>
        )}

        <hr className="mt-2 mb-3" />

        <StepListWizardButtons
          rightButtonClassName="c-btn--icon-right"
          rightButtonOnClick={this.validateAndGoToNextStep}
          rightButtonText={
            <>
              {TranslationService.translateModule('DebtorInfoNextButton', ModuleNamesList.CreateNewCase) + ' '}
              <i className="fas fa-chevron-right right" />
            </>
          }
        />
      </>
    );
  }

  private validateAndGoToNextStep = () => {
    if (this.props.claimInfo.selectedCreditor) {
      this.setState({
        isValid: true,
      });

      this.props.goToNextStep();
    } else {
      this.setState({
        isValid: false,
      });
    }
  };

  private getDescriptionTranslationKey = () => {
    if (this.props.claimInfo.collectionNoticeType === CollectionNotice.No) {
      return 'NewClaimNoDisputeNoCollectionNotice';
    } else {
      return 'NewClaimNoDisputeWithCollectionNotice';
    }
  };

  private availableCreditors = () => {
    const creditors = this.props.creditors.filter((item) => this.props.currentCreditors.includes(item.domainId));
    const mappedCreditors: IDictionary<string> = {};

    creditors.forEach((creditor: ICreditor) => {
      mappedCreditors[creditor.domainId] = `(${creditor.domainId}) ${creditor.organizationName}`;
    });

    const mappedCreditorsAsArray = Object.keys(mappedCreditors);

    if (mappedCreditorsAsArray.length === 1 && !this.props.moduleSettings.showExtendedWorkflow) {
      this.setState({ isValid: true });

      this.props.setClaimInfo({
        ...this.props.claimInfo,
        selectedCreditor: mappedCreditorsAsArray[0],
      });
    } else if (mappedCreditorsAsArray.length === 1) {
      this.props.setClaimInfo({
        ...this.props.claimInfo,
        selectedCreditor: mappedCreditorsAsArray[0],
      });
    }

    this.setState({
      creditors: mappedCreditors,
    });
  };
}

const mapStateToProps = (state: IStore) => ({
  creditors: state.creditors,
  currentCreditors: state.currentCreditors,
});

export default connect(mapStateToProps)(NewClaim);
