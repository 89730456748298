import RestApiClient from '../../../core/api/RestApiClient';
import { IModule } from '../../../core/api/types/IModule';

class ModalApi {
  public async getModalContent(moduleInstanceId: number): Promise<any> {
    return await RestApiClient.get<IModule>('modules/ModalModule', { moduleInstanceId });
  }
}

export default new ModalApi();
