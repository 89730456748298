import RestApiClient from '../../core/api/RestApiClient';
import { ICSInvoicesResponse } from './types/ICSInvoicesResponse';
import { CancelToken } from 'axios';

class CaseStatisticsCasesOverdueApiClient {
  public async GetData(cancelToken: CancelToken): Promise<ICSInvoicesResponse> {
    return await RestApiClient.get<ICSInvoicesResponse>(`modules/CaseStatisticsCasesOverdue/getData`, {}, cancelToken);
  }
}

export default new CaseStatisticsCasesOverdueApiClient();
