import React from 'react';
import CurrencyParser from './../../../../core/helpers/CurrencyParser';
import TableRow from './TableRow';

export default (props: { label: string; values: number[]; uniqueKey: string; boldColumns?: number[] }) => (
  <TableRow
    uniqueKey={props.uniqueKey}
    label={props.label}
    boldColumns={props.boldColumns}
    values={props.values.map((v) => CurrencyParser.toLocaleString(v))}
  />
);
