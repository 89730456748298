import React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';
import { ISummaryProps } from '../types/ISummaryProps';
// @ts-ignore
import { createBrowserHistory } from 'history';
import RouteParamsService from '../../../core/services/RouteParamsService';

const history = createBrowserHistory();

const Summary = (props: ISummaryProps) => {
  const handleHomepageRedirect = () => {
    const queryString = RouteParamsService.getQueryParameters();
    let redirectUrl = '/';
    if (queryString['token']) {
      redirectUrl += '?token=' + queryString['token'];
    }

    history.push(redirectUrl);
    history.go(0);
  };

  return (
    <>
      <hr />
      <header className="step-header">
        <h2 className="step-title">
          {TranslationService.translateModule('SummaryHeader', ModuleNamesList.CreditorRegistration)}
        </h2>
        {props.isDisabledCreateNewCreditor ? (
          <div
            dangerouslySetInnerHTML={{
              __html: TranslationService.translateModule(
                'SummaryDescriptionRegisterNewCompanyButtonDisabled',
                ModuleNamesList.CreditorRegistration
              ),
            }}
          />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: TranslationService.translateModule(
                'SummaryDescriptionRegisterNewCompanyButtonEnabled',
                ModuleNamesList.CreditorRegistration
              ),
            }}
          />
        )}
      </header>
      <p className="note text-center mb-0">
        {TranslationService.translateModule('SummaryBottomNote', ModuleNamesList.CreditorRegistration)}
      </p>
      <hr />

      {props.isDisabledCreateNewCreditor ? (
        <StepListWizardButtons
          rightButtonOnClick={handleHomepageRedirect}
          rightButtonText={
            <>
              <i className="fas fa-home left" />
              {' ' + TranslationService.translate('Homepage')}
            </>
          }
        />
      ) : (
        <StepListWizardButtons
          leftButtonOnClick={props.resetWizard}
          leftButtonText={
            <>
              <i className="far fa-building left" />
              {' ' +
                TranslationService.translateModule(
                  'SummaryRegisterNewCompanyButton',
                  ModuleNamesList.CreditorRegistration
                )}
            </>
          }
          rightButtonOnClick={handleHomepageRedirect}
          rightButtonText={
            <>
              <i className="fas fa-home left" />
              {' ' + TranslationService.translate('Homepage')}
            </>
          }
        />
      )}
    </>
  );
};

export default Summary;
