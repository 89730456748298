import React from 'react';
import styles from '../Accordion.module.scss';
// @ts-ignore
import classNames from 'classnames';
import { useAccordionContext } from '../context/AccordionContext';

type AccordionBodyPropsType = {
  index: number;
  children: React.ReactNode;
  className?: string;
  activeClass?: string;
};

const AccordionBody = (props: AccordionBodyPropsType) => {
  const { isOpen, currentOpen } = useAccordionContext();

  return (
    <div
      className={classNames(
        styles.body,
        props.className,
        props.index === currentOpen && isOpen && `${styles.bodyIsOpen} ${props.activeClass}`
      )}
    >
      {props.children}
    </div>
  );
};

export default AccordionBody;
