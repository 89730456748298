import RestApiClient from '../../../core/api/RestApiClient';
import { IActionStatus } from '../../../core/api/types/IActionStatus';
import { CaseNoteType } from '../types/CaseNoteType';
import { UserCaseNoteRequestType } from '../types/UserCaseNoteRequest';

const UserCaseNotesApi = {
  getUserCaseNotes: async (caseId: string): Promise<IActionStatus<CaseNoteType>> => {
    return await RestApiClient.get<IActionStatus<CaseNoteType>>(
      `modules/UserCaseNotesModule/getUserCaseNotes/${caseId}`
    );
  },
  addOrUpdateUserNote: async (data: UserCaseNoteRequestType): Promise<IActionStatus> => {
    return await RestApiClient.post<IActionStatus>('modules/UserCaseNotesModule/AddOrUpdateUserNote', data);
  },
};

export default UserCaseNotesApi;
