import NotesListApi from '../api/NotesListApi';
import { INotesListRequest } from '../types/INotesListRequest';

class NotesListService {
  public async getNotes(request: INotesListRequest) {
    return await NotesListApi.getNotes(request);
  }
}

export default new NotesListService();
