export enum StepIconsCss {
  Agreement = 'security-and-terms',
  Case = 'case',
  Company = 'company',
  Complete = 'complete',
  Confirmation = 'confirmation',
  Contacts = 'contacts',
  Financial = 'financial',
  Message = 'envelope',
  NewFile = 'new-file',
  Profile = 'profile',
  Services = 'services',
  UserPlus = 'new-registration',
  Welcome = 'welcome',
  Format = 'format',
  Notifications = 'notifications',
  Summary = 'summary',
  Refresh = 'refresh',
  BankIdVerification = 'bankidverification',
  BisnodeVerification = 'bisnodeverification',
}
