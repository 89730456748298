export enum AutoOnboardingStatus {
  Unknown = 0,
  Created = 1,
  PendingUserRegistration = 2,
  PassedBankIdVerification = 3,
  FailedBankIdVerification = 4,
  PassedBisnodeVerification = 5,
  FailedBisnodeVerification = 6,
  PendingCreditorRegistration = 7,
  Done = 8,
}
