import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../../core/components/Forms/FormGroup';
import { Formik, Form, ErrorMessage } from 'formik';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { FormValues } from '../types/FormValues';
import React from 'react';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import TranslationService from '../../../core/services/TranslationService';
import * as yup from 'yup';

type FilterProps = {
  onFilter: (formData: FormValues) => void;
  isDataLoading: boolean;
  formValues: FormValues;
};

export const Filter = (props: FilterProps) => {
  const formValidationSchema = yup.object().shape({
    // unitId: yup.string().required(TranslationService.translate('ValidationMessageRequiredField')),
  });

  const handleFilter = (value: FormValues) => {
    props.onFilter(value);
  };

  const handleFormikSubmit = (value: FormValues) => {
    handleFilter(value);
  };

  return (
    <Formik
      initialValues={{ ...props.formValues }}
      enableReinitialize={true}
      onSubmit={handleFormikSubmit}
      validationSchema={formValidationSchema}
    >
      {({ values, handleChange }) => {
        return (
          <Form className="c-form">
            <div className="row">
              <div className="col-4">
                <FormGroup
                  name="unitId"
                  label={TranslationService.translateModule('UnitNo', ModuleNamesList.UnitIdSearch)}
                  variant={FORM_GROUP_VARIANTS.BLOCK}
                  render={(name) => (
                    <>
                      <SimpleInput
                        id="unitId"
                        name={name}
                        value={values[name]}
                        onChange={(value: string) => {
                          const handleChangeEvent = {
                            target: {
                              id: name,
                              name: name,
                              value: value,
                            },
                          } as React.ChangeEvent<any>;

                          handleChange(handleChangeEvent);
                        }}
                      />

                      <ErrorMessage name={name} component="div" className="error error-message" />
                    </>
                  )}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Button
                  type="submit"
                  id="unitIdFilterBtn"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTON_SIZE.SM}
                  label={TranslationService.translate('Filter')}
                  disabled={props.isDataLoading}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
