import { ISearchConversationsRequest } from '../types/ISearchConversationsRequest';
import { IConversationsTableResult } from '../types/IConversationsTableResult';
import RestApiClient from '../../../core/api/RestApiClient';
import { ConversationDetailsType } from '../types/ConversationType';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

const getConversationsList = async (request: ISearchConversationsRequest): Promise<IConversationsTableResult> => {
  return await RestApiClient.post<IConversationsTableResult>(`modules/MessagesHomeModule/getConversations`, request);
};

const getConversationDetails = async (conversationId: number, creditorNumber: string) => {
  return await RestApiClient.get<ConversationDetailsType>(
    `modules/MessagesHomeModule/getConversation/${conversationId}/${creditorNumber}`
  );
};

const sendNewMessageApi = async (formData: FormData): Promise<IActionStatus> => {
  const headers = {
    'Content-Type': 'multipart/form-data;',
  };
  return await RestApiClient.post<IActionStatus>(`modules/MessagesHomeModule/sendNewMessage`, formData, headers, false);
};

export { getConversationDetails, getConversationsList, sendNewMessageApi };
