import RestApiClient from '../../../core/api/RestApiClient';
import { TopbarSettings } from '../types/TopbarSettings';

const TopbarApi = {
  getModuleSettings: async () => {
    return await RestApiClient.get<TopbarSettings>(`modules/Topbar/getConfiguration`);
  },
};

export default TopbarApi;
