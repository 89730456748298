import React, { useState } from 'react';
import './styles.scss';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { IDictionary } from '../../core/types/IDictionary';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IModuleWithSettings } from './types/IModuleWithSettings';
import { TimelineDataType, timelineDataInit } from './types/TimelineDataType';
import { TimelineOptionsType, timelineOptionsTypeInit } from './types/TimelineOptionsType';
import CaseTimelineService from './services/CaseTimelineService';
import DateTimeParser from '../../core/helpers/DateTimeParser';
import InfoMessageService from '../../core/services/InfoMessageService';
import Loader from '../../core/components/Loading/Loader';
import Timeline from './components/Timeline';
import TimelineActions from './components/TimelineActions';
import TimelineNav from './components/TimelineNav';
import TimelineVerticalScroll from './components/TimelineVerticalScroll';
import TranslationService from '../../core/services/TranslationService';
import useEffectAsync from '../../core/hooks/useEffectAsync';
import { EventsList } from '../../core/lists/EventsList';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import moment from 'moment';

const vScrollDataInit = {
  top: 0,
  scrollHeight: 0,
};

type VScrollDataType = typeof vScrollDataInit;

const CaseTimeline = (props: IModuleProps) => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [timelineOptions, setTimelineOptions] = useState<TimelineOptionsType>(timelineOptionsTypeInit);
  const [timelineAction, setTimelineAction] = useState<IDictionary<number> | null>(null);
  const [timelineData, setTimelineData] = useState<TimelineDataType>(timelineDataInit);
  const [vscrollData, setVscrollData] = useState<VScrollDataType>();

  const module = flatModuleOptions<IModuleWithSettings>(props.module as any);

  let moduleEvents: IModuleEventSubscription[] = [];

  const fetchData = () => {
    setIsDataLoading(true);

    const { id } = props.routeParameters;
    const caseId = id as string;

    CaseTimelineService.getTimeline(caseId, module)
      .then((response) => {
        const timelineData = response.map((r, index) => {
          const date = DateTimeParser.toLocaleDateString(r.date);

          return {
            id: index,
            content: `<img src="${r.icon}" alt="" style="width:24px;height:24px;" />`,
            start: r.date,
            end: null,
            className: r.label,
            style: `${r.color.length ? `background-color: ${r.color}; border-color: ${r.color}` : ''}`,
            title: `<h1>${r.label}</h1><p>${r.title}</p><p>${date}</p>`,
          };
        });

        const dates = timelineData.map((d) => moment(d.start));
        const minDate = moment.min(dates);
        const maxDate = moment.max(dates);
        const diffDate = maxDate.diff(minDate, 'days');
        const days = (diffDate / 7).toFixed(0);

        setTimelineOptions({
          minDate: minDate.subtract(days, 'day').format('YYYY-MM-DD HH:mm:ss'),
          maxDate: maxDate.add(days, 'day').format('YYYY-MM-DD HH:mm:ss'),
          data: timelineData,
        });

        setIsDataLoading(false);
      })
      .catch((_) => {
        InfoMessageService.error(
          TranslationService.translateModule('ErrorLoadingActivities', props.module.name),
          TranslationService.translate('Error')
        );

        setIsDataLoading(false);
      });
  };

  const registerModuleEvents = () => {
    moduleEvents = [...moduleEvents, { name: EventsList.CASE_DATA_UPDATED, callback: fetchData }];
    ModuleEventSubscriber.registerEvents(moduleEvents);
  };

  const handleMoveLeft = () => {
    setTimelineAction({
      timelineMove: 0.2,
    });
  };

  const handleMoveRight = () => {
    setTimelineAction({
      timelineMove: -0.2,
    });
  };

  const handleZoomIn = () => {
    setTimelineAction({
      zoomIn: 0.2,
    });
  };

  const handleZoomOut = () => {
    setTimelineAction({
      zoomOut: 0.2,
    });
  };

  const handleOnUpdateTimeline = (data: { className: string; end: string; start: string }) => {
    let className: string;
    const start = DateTimeParser.toDateFormat(data.start, 'L HH:mm:ss');
    const end = DateTimeParser.toDateFormat(data.end, 'L HH:mm:ss');

    if (moment(timelineOptions.minDate).isBefore(start) || moment(end).isBefore(timelineOptions.maxDate)) {
      className = 'range-timeline';
    } else {
      className = 'range-timeline range-timeline--hide-vertical-scroll';
    }

    setTimelineData({
      ...timelineData,
      className,
      start: data.start,
      end: data.end,
    });
  };

  const handleOnChangeVScroll = (data: any) => {
    let top = 0;
    let scrollHeight = 0;
    let diffScrollTop = data.scrollTop;
    const height = data.bottom.height + data.background.height;

    if (diffScrollTop < 0) {
      diffScrollTop = diffScrollTop * -1;
    }

    if (data.scrollTopMin !== 0) {
      top = (1 / height) * 100 * diffScrollTop;
      scrollHeight = data.centerContainer.height / height;
    }

    setVscrollData({
      top,
      scrollHeight,
    });
  };

  useEffectAsync(async () => {
    fetchData();
    registerModuleEvents();

    return () => {
      ModuleEventSubscriber.unsubscribeEvents(moduleEvents);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isDataLoading ? (
        <Loader />
      ) : (
        <div className="c-timeline">
          <h6 className="c-timeline__title">
            {TranslationService.translateModule('TimelineTitle', props.module.name)}
          </h6>

          <TimelineActions timelineZoomIn={handleZoomIn} timelineZoomOut={handleZoomOut} />

          <div className="case-timeline__main">
            <div className="case-timeline__timeline">
              <Timeline
                data={timelineOptions}
                actions={timelineAction}
                onUpdate={handleOnUpdateTimeline}
                onChangeVScroll={handleOnChangeVScroll}
              />
            </div>

            <div className="case-timeline__v-scroll">
              <TimelineVerticalScroll data={vscrollData} />
            </div>
          </div>

          <TimelineNav
            timelineData={{
              start: timelineData.start,
              end: timelineData.end,
              className: timelineData.className,
            }}
            timelineOptions={{
              minDate: timelineOptions.minDate,
              maxDate: timelineOptions.maxDate,
            }}
            timelineMoveLeft={handleMoveLeft}
            timelineMoveRight={handleMoveRight}
          />
        </div>
      )}
    </>
  );
};

export default CaseTimeline;
