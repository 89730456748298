import * as React from 'react';
import Loader from '../../core/components/Loading/Loader';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import { IComponentTable } from '../../core/types/IComponentTable';
import { IModuleProps } from '../../core/types/IModuleProps';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import ActivitiesListTableConfigurator from './Helpers/ActivitiesListTableConfigurator';
import ActivitiesListService from './services/ActivitiesListService';
import { IActivitiesListRequest } from './types/IActivitiesListRequest';
import { IActivityListElement } from './types/IActivityListElement';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import { EventsList } from '../../core/lists/EventsList';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';

interface IState {
  isDataLoading: boolean;
  activitiesTable: IComponentTable<IActivityListElement>;
}

export default class ActivitiesList extends React.Component<IModuleProps, IState> {
  moduleEvents: IModuleEventSubscription[] | undefined;
  state: IState = {
    isDataLoading: false,
    activitiesTable: {
      columns: [],
      values: [],
    },
  };

  componentDidMount = async () => {
    this.registerModuleEvents();
    await this.fetchActivities();
  };

  componentWillUnmount() {
    if (this.moduleEvents) {
      ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
    }
  }

  registerModuleEvents() {
    this.moduleEvents = [{ name: EventsList.CASE_DATA_UPDATED, callback: this.fetchActivities }];

    ModuleEventSubscriber.registerEvents(this.moduleEvents);
  }

  render() {
    return (
      <div className="activities-list position-relative mt-4">
        <section className={`l-module__section ${this.state.isDataLoading ? 'l-module__section--loading' : ''}`}>
          {this.state.isDataLoading ? <Loader opacity={0.5} /> : null}
          <BootstrapTableContainer
            remote={false}
            wrapperClasses="bt"
            paginationProps={{}}
            classes="bt__table bt-table"
            keyField="id"
            data={this.state.activitiesTable.values}
            columns={
              this.state.activitiesTable.columns.length === 0
                ? []
                : ActivitiesListTableConfigurator.getActivitiesListTableColumns(this.state.activitiesTable)
            }
          />
        </section>
      </div>
    );
  }

  fetchActivities = async () => {
    this.setState({
      isDataLoading: true,
    });

    try {
      const { id } = this.props.routeParameters;
      const request = {
        caseId: id,
        moduleInstanceId: this.props.module.id,
      } as IActivitiesListRequest;

      const result = await ActivitiesListService.getActivities(request);
      this.setState({
        isDataLoading: false,
        activitiesTable: result,
      });
    } catch {
      this.setState(
        {
          isDataLoading: false,
        },
        () => {
          InfoMessageService.error(
            TranslationService.translateModule('ErrorLoadingActivities', this.props.module.name),
            TranslationService.translate('Error')
          );
        }
      );
    }
  };
}
