import * as React from 'react';
import { IModuleProps } from '../../core/types/IModuleProps';
import TranslationService from '../../core/services/TranslationService';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import DatabaseForm from './components/DatabaseForm';
import { IDBConnectionDetails, emptyConnectionDetails } from './types/IDBConnectionDetails';
import DnnMigrationApi from './api/DnnMigrationApi';
import Loader from '../../core/components/Loading/Loader';
import { emptyComponentTable, IComponentTable } from 'core/types/IComponentTable';
import { IUserDetails } from './types/IUserDetails';
import MigrateUsersList from './components/MigrateUsersList';
import InfoMessageService from '../../core/services/InfoMessageService';
import { useState } from 'react';
import { IDnnMigration } from './types/IDnnMigration';
import RoleGroup from './components/RoleGroup';
import RestApiClient from '../../core/api/RestApiClient';
import { IActionStatus } from '../../core/api/types/IActionStatus';
import { useMutation } from 'react-query';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from 'core/components/Button/Button';

const DnnMigration = (props: IModuleProps) => {
  const [dnnModel, setDnnModel] = useState<IDnnMigration>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [isDBConnected, setIsDBConnected] = useState<boolean>(false);
  const [connectionDetails, setConnectionDetails] = useState<IDBConnectionDetails>(emptyConnectionDetails);
  const [usersTable, setUsersTable] = useState<IComponentTable<IUserDetails>>(emptyComponentTable);
  const [rolesSelected, setRolesSelected] = useState<any>();
  const [migrationResult, setMigrationResult] = useState<any>();

  const migrateRole = useMutation(
    (selectedUsers) => {
      return RestApiClient.post<IActionStatus>(`modules/DnnMigration/migrateUsers`, {
        moduleInstanceId: props.module.id,
        connectionDetails,
        userUIds: selectedUsers,
        rolesMapping: rolesSelected,
      });
    },
    {
      onSuccess: (data: any) => {
        setMigrationResult(data);
      },
      onError: () => {
        InfoMessageService.error(TranslationService.translate('ErrorSthWentWrong'));
      },
    }
  );

  const onCloseConnection = () => {
    setConnectionDetails({ ...emptyConnectionDetails });
    setIsDBConnected(false);
    setUsersTable({ ...emptyComponentTable });
    setIsDataLoading(false);
  };

  const gatherUsers = async (connectionDetails: IDBConnectionDetails) => {
    setIsDataLoading(true);

    try {
      const result = await DnnMigrationApi.getDnnUsers(props.module.id, connectionDetails);

      if (!result.isSuccess) {
        InfoMessageService.error(
          TranslationService.translateModule('ErrorDatabaseConnectionNotConnected', ModuleNamesList.DnnMigration)
        );
      } else {
        setDnnModel(result);
      }

      setConnectionDetails(connectionDetails);
      setUsersTable(result.isSuccess ? result.usersTable : usersTable);
      setIsDBConnected(result.isSuccess);
      setIsDataLoading(false);
    } catch {
      setConnectionDetails(connectionDetails);
      setIsDataLoading(false);
    }
  };

  const handleOnRoleSelect = (values: any) => {
    setRolesSelected(values);
  };

  const handleOnMigrate = (selectedUsers: any) => {
    migrateRole.mutate(selectedUsers);
  };

  return (
    <article className="l-module">
      {migrateRole.isLoading && <Loader opacity={0.4} />}

      <section className="l-module__section l-module__section--head">
        <h1>
          <i className="fas fa-file-import mr-2" />
          {TranslationService.translateModule('MigrationHeader', ModuleNamesList.DnnMigration)}
        </h1>
      </section>

      <section className="l-module__section l-module__section--filter mb-3">
        <h6 className="font-weight-bold mb-3">
          {TranslationService.translateModule('DatabaseHeader', ModuleNamesList.DnnMigration)}
        </h6>

        <DatabaseForm
          onSubmit={gatherUsers}
          onCloseConnection={onCloseConnection}
          isDataLoading={isDataLoading}
          isDBConnected={isDBConnected}
        />
      </section>

      <section
        className={`l-module__section l-module__item--animated ${isDBConnected ? 'l-module__item--fadein' : ''} mb-3`}
      >
        {migrationResult ? (
          <>
            {migrationResult.endWithError > 0 ? (
              <div className="alert alert-danger" role="alert">
                {TranslationService.translateModule('MigrationEndsWithError', ModuleNamesList.DnnMigration)}

                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  className="mr-3 float-right"
                  size={BUTTON_SIZE.MD}
                  label={`${TranslationService.translateModule('BackToUsersList', ModuleNamesList.DnnMigration)}`}
                  onClick={() => setMigrationResult(null)}
                />
              </div>
            ) : (
              migrationResult.endWithSuccess > 0 && (
                <div className="alert alert-success" role="alert">
                  {TranslationService.translateModule('MigrationEndsWithSuccess', ModuleNamesList.DnnMigration)}

                  <Button
                    variant={BUTTON_VARIANTS.PRIMARY}
                    className="ml-3"
                    size={BUTTON_SIZE.MD}
                    label={`${TranslationService.translateModule('BackToUsersList', ModuleNamesList.DnnMigration)}`}
                    onClick={() => setMigrationResult(null)}
                  />
                </div>
              )
            )}
          </>
        ) : (
          <>
            {isDataLoading && <Loader />}

            {dnnModel && (
              <>
                <h6 className="font-weight-bold mb-3">
                  {TranslationService.translateModule('UsersListHeader', ModuleNamesList.DnnMigration)}
                </h6>

                <RoleGroup
                  dnnRoleGroup={dnnModel.dnnRoles}
                  ooRoleGroup={dnnModel.oorOles}
                  migrationResult={migrationResult}
                  onChange={handleOnRoleSelect}
                />

                {usersTable.values.length > 0 && (
                  <MigrateUsersList
                    connectionDetails={connectionDetails}
                    moduleInstanceId={props.module.id}
                    usersTable={usersTable}
                    migrationResult={migrationResult}
                    onMigrate={handleOnMigrate}
                  />
                )}
              </>
            )}
          </>
        )}
      </section>
    </article>
  );
};

export default DnnMigration;
