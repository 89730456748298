import { IModule } from '../types/IModule';

export function flatModuleOptions<T extends IModule>(module: T): T {
  let result = module;

  if (!result.options) {
    return result;
  }

  // @ts-ignore
  module.options.forEach((option) => {
    // @ts-ignore
    module[option.key] = option.value;
  });

  return result;
}
