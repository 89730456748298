import React from 'react';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import Loader from '../../core/components/Loading/Loader';
import { getModalOpenEvent } from '../../core/helpers/Modal/ModalEvents';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import { emptyComponentTable, IComponentTable } from '../../core/types/IComponentTable';
import { IModuleProps } from '../../core/types/IModuleProps';
import PortalUserListFilter from './components/PortalUserListFilter';
import UsersListTableConfigurator from './Helpers/UsersListTableConfigurator';
import PortalUsersListApiClient from './PortalUsersListApiClient';
import { emptyPortalUsersFilterRequest, IPortalUsersFilterRequest } from './types/IPortalUsersFilterRequest';
import { IUserListElement } from './types/IUserListElement';
import ConfirmationModal from '../../core/components/ConfirmationModal/ConfirmationModal';
import { Status } from '../../core/api/Enums/Status';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import ErrorResponseHandler from '../../core/services/ErrorResponseHandler';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';

interface IState {
  filterRequest: IPortalUsersFilterRequest;
  isDataLoading: boolean;
  isLockUnlockModalVisible: boolean;
  isModalContentLoading?: boolean;
  isResetPasswordConfirmModalVisible: boolean;
  isDeleteUserConfirmModalVisible: boolean;
  selectedUser?: IUserListElement;
  usersTable: IComponentTable<IUserListElement>;
  hasAccess: boolean;
}

class PortalUsersList extends React.Component<IModuleProps> {
  public module: any = flatModuleOptions<any>(this.props.module as any);
  public state: IState = {
    isDataLoading: true,
    usersTable: { ...emptyComponentTable },
    filterRequest: { ...emptyPortalUsersFilterRequest },
    isLockUnlockModalVisible: false,
    isResetPasswordConfirmModalVisible: false,
    isDeleteUserConfirmModalVisible: false,
    hasAccess: true,
  };

  public componentDidMount = async () => {
    await this.fetchData();
  };

  public render() {
    if (!this.state.isDataLoading && !this.state.hasAccess) {
      return <NoPermissionsComponent />;
    }

    return (
      <>
        <article className="l-module">
          <div className="l-module__item">
            <section className="l-module__section l-module__section--head">
              <h1>
                <i className="fas fa-users mr-2" />
                {TranslationService.translateModule('PortalUsersListHeader', ModuleNamesList.PortalUsersList)}
              </h1>
            </section>

            <section className="l-module__section l-module__section--filter mb-3">
              <PortalUserListFilter filter={this.filter} />
            </section>

            <section className={`l-module__section ${this.state.isDataLoading ? 'l-module__section--loading' : ''}`}>
              {this.state.isDataLoading && <Loader opacity={0.5} />}
              <div>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTON_SIZE.MD}
                  icon={{ className: 'fas fa-plus', position: 'left' }}
                  label={TranslationService.translateModule('AddNewUser', this.module.name)}
                  onClick={this.openRegisterUserModal}
                />
              </div>

              <BootstrapTableContainer
                remote={false}
                wrapperClasses="bt mt-1"
                classes="bt__table bt-table"
                keyField="uId"
                paginationProps={{
                  sizePerPage: 10,
                }}
                data={this.state.usersTable.values}
                columns={UsersListTableConfigurator.getTableColumns(
                  this.state.usersTable,
                  this.openCreditorsAssignmentModal,
                  this.openConfirmChangeUserLockStatus,
                  this.openGroupUsersAssignmentModal,
                  this.openResetPasswordModal,
                  this.openDeleteUserModal,
                  this.openRolesAssignmentModal
                )}
              />
            </section>
          </div>
        </article>

        {this.state.selectedUser && (
          <ConfirmationModal
            isOpen={this.state.isLockUnlockModalVisible}
            isModalContentLoading={this.state.isModalContentLoading}
            message={TranslationService.translateModule(
              this.state.selectedUser.isLocked ? 'ModalContentUnlockUser' : 'ModalContentLockUser',
              ModuleNamesList.PortalUsersList
            ).replace('{0}', this.state.selectedUser.userName)}
            onCancel={() => this.setState({ isLockUnlockModalVisible: false })}
            onOk={() => this.changeUserLockStatus()}
          />
        )}

        {this.state.selectedUser && (
          <ConfirmationModal
            isOpen={this.state.isResetPasswordConfirmModalVisible}
            isModalContentLoading={this.state.isModalContentLoading}
            message={TranslationService.translateModule(
              'ModalContentResetUserPassword',
              ModuleNamesList.PortalUsersList
            ).replace('{0}', this.state.selectedUser.userName)}
            onCancel={() =>
              this.setState({
                isResetPasswordConfirmModalVisible: false,
                isModalContentLoading: false,
              })
            }
            onOk={() => this.resetUserPassword()}
          />
        )}

        {this.state.selectedUser && (
          <ConfirmationModal
            isImportant={true}
            isOpen={this.state.isDeleteUserConfirmModalVisible}
            isModalContentLoading={this.state.isModalContentLoading}
            message={TranslationService.translateModule(
              'ModalContentDeleteUser',
              ModuleNamesList.PortalUsersList
            ).replace('{0}', this.state.selectedUser.userName)}
            onCancel={() =>
              this.setState({
                isDeleteUserConfirmModalVisible: false,
                isModalContentLoading: false,
              })
            }
            onOk={() => this.deleteUser()}
          />
        )}
      </>
    );
  }

  private openRegisterUserModal = () => {
    ModuleEventSubscriber.emitEvent({
      name: getModalOpenEvent(this.module.RegisterUserModalModuleInstanceId),
      data: {
        modalData: {
          MODAL_HEADER_TEXT: TranslationService.translateModule('RegisterUser', this.module.name),
          MODAL_HEADER_ICON: 'fas fa-user',
          onClose: (actionSuccess?: boolean) => {
            if (actionSuccess) {
              this.fetchData();

              InfoMessageService.success(TranslationService.translateModule('RegisterUserSuccess', this.module.name));
            }
          },
        },
      },
    });
  };

  private openConfirmChangeUserLockStatus = (selectedUser: IUserListElement) => {
    this.setState({
      selectedUser,
      isLockUnlockModalVisible: true,
    });
  };

  private openResetPasswordModal = (selectedUser: IUserListElement) => {
    this.setState({
      selectedUser,
      isResetPasswordConfirmModalVisible: true,
    });
  };

  private openDeleteUserModal = (selectedUser: IUserListElement) => {
    this.setState({
      selectedUser,
      isDeleteUserConfirmModalVisible: true,
    });
  };

  private changeUserLockStatus = async () => {
    const user = this.state.selectedUser;
    if (user) {
      this.setState({
        isModalContentLoading: true,
      });
      try {
        const status = await PortalUsersListApiClient.changeUserLockStatus(user.uId, !user.isLocked);
        if (status.status === Status.Success) {
          InfoMessageService.success(
            TranslationService.translateModule('ChangeUserLockStatusSucceded', ModuleNamesList.PortalUsersList)
          );
          const table = { ...this.state.usersTable } as IComponentTable<IUserListElement>;
          const userRow = table.values.find((item) => item.uId === user.uId);
          const index = table.values.indexOf(userRow!);
          table.values[index] = { ...userRow!, isLocked: !user.isLocked };

          this.setState({
            isLockUnlockModalVisible: false,
            usersTable: table,
          });
        }
      } finally {
        this.setState({
          isModalContentLoading: false,
          isLockUnlockModalVisible: false,
        });
      }
    }
  };

  private filter = async (filterRequest: IPortalUsersFilterRequest) => {
    this.setState(
      {
        filterRequest,
      },
      async () => await this.fetchData()
    );
  };

  private fetchData = async () => {
    this.setState({
      isDataLoading: true,
    });
    try {
      const usersTable = await PortalUsersListApiClient.getUsers(this.props.module.id, this.state.filterRequest);
      this.setState({
        usersTable,
        isDataLoading: false,
      });
    } catch (error: any) {
      const hasAccess = ErrorResponseHandler.hasAccess(error);
      this.setState({
        isDataLoading: false,
        hasAccess,
      });
    }
  };

  private resetUserPassword = async () => {
    if (this.state.selectedUser) {
      this.setState({ isModalContentLoading: true });
      try {
        const result = await PortalUsersListApiClient.resetPassword(this.state.selectedUser.uId);
        if (result.status === Status.Success) {
          InfoMessageService.success(
            TranslationService.translateModule('ResetLinkMessageWasSend', ModuleNamesList.ResetPassword)
          );
        } else {
          InfoMessageService.displayActionStatus(result);
        }
      } finally {
        this.setState({
          isModalContentLoading: false,
          isResetPasswordConfirmModalVisible: false,
        });
      }
    }
  };

  private deleteUser = async () => {
    if (this.state.selectedUser) {
      this.setState({ isModalContentLoading: true });
      try {
        const result = await PortalUsersListApiClient.deleteUser(this.state.selectedUser.uId);
        if (result.status === Status.Success) {
          InfoMessageService.success(
            TranslationService.translateModule('UserDeleted', ModuleNamesList.PortalUsersList).replace(
              '{0}',
              this.state.selectedUser.userName
            )
          );
        } else {
          InfoMessageService.displayActionStatus(result);
        }
      } finally {
        this.setState({
          isModalContentLoading: false,
          isDeleteUserConfirmModalVisible: false,
        });
        this.fetchData();
      }
    }
  };

  private openGroupUsersAssignmentModal = (userUId: string, username: string) => {
    ModuleEventSubscriber.emitEvent({
      name: getModalOpenEvent(this.module.GroupUsersAssignmentModalModuleInstanceId),
      data: {
        modalData: {
          MODAL_HEADER_TEXT:
            TranslationService.translateModule('ActionAssignGroupUsers', ModuleNamesList.PortalUsersList) +
            ': ' +
            username,
          MODAL_HEADER_ICON: 'fas fa-user-friends',
          className: 'c-modal--lg',
        },
        otherData: {
          userUId,
        },
      },
    });
  };

  private openCreditorsAssignmentModal = (userUId: string, fullName: string) => {
    ModuleEventSubscriber.emitEvent({
      name: getModalOpenEvent(this.module.CreditorsAssignmentModalModuleInstanceId),
      data: {
        modalData: {
          MODAL_HEADER_TEXT:
            TranslationService.translateModule('ActionCreditorsAssignment', ModuleNamesList.PortalUsersList) +
            ': ' +
            fullName,
          MODAL_HEADER_ICON: 'fas fa-user-tie',
          className: 'c-modal--lg',
        },
        otherData: {
          userUId,
        },
      },
    });
  };

  private openRolesAssignmentModal = (userUId: string, fullName: string) => {
    ModuleEventSubscriber.emitEvent({
      name: getModalOpenEvent(this.module.RolesAssignmentModalModuleInstanceId),
      data: {
        modalData: {
          MODAL_HEADER_TEXT:
            TranslationService.translateModule('ActionRolesAssignment', ModuleNamesList.PortalUsersList) +
            ': ' +
            fullName,
          MODAL_HEADER_ICON: 'fas fa-address-card',
        },
        otherData: {
          userUId,
        },
      },
    });
  };
}

export default PortalUsersList;
