import CaseTabsApi from '../api/InvoiceDetailsTabsApi';
import { InvoiceDetailsTabsRequest } from '../types/InvoiceDetailsTabsRequest';
import { InvoiceDetailsTabsSettings } from '../types/InvoiceDetailsTabsSettings';

class InvoiceDetailsTabsService {
  public async getModuleSettings(request: InvoiceDetailsTabsRequest): Promise<InvoiceDetailsTabsSettings> {
    return await CaseTabsApi.getModuleSettings(request);
  }
}

export default new InvoiceDetailsTabsService();
