import RestApiClient from '../../../core/api/RestApiClient';
import { IAmountsStructure } from '../types/IAmountsStructure';

class CasePaymentsApi {
  public async getCasePayments(caseId: string) {
    return await RestApiClient.get<{ casePayments: IAmountsStructure }>(`modules/CasePaymentsModule/${caseId}`);
  }
}

export default new CasePaymentsApi();
