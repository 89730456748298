export interface INewMessage {
  attachedFiles: File[];
  content: string;
  sendCopyToEmail: boolean;
}

export const emptyMessage: INewMessage = {
  content: '',
  sendCopyToEmail: false,
  attachedFiles: [],
};
