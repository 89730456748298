import React from 'react';

interface ISettingInputComponent {
  name: string;
  id?: string;
  value: string;
  handleChange: (value: string) => void;
}

class StringComponentInput extends React.Component<ISettingInputComponent, {}> {
  render() {
    return (
      <div className="c-control">
        <input
          type="text"
          id={this.props.id || this.props.name}
          className="form-control"
          defaultValue={this.props.value}
          name={this.props.name}
          onChange={(event) => this.props.handleChange(event.target.value)}
        />
      </div>
    );
  }
}

export default StringComponentInput;
