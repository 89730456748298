import RestApiClient from '../../../../core/api/RestApiClient';
import { IUnitOverview } from '../types/IUnitOverview';

class UnitOverviewApiClient {
  public async getCaseUnitOverview(caseNumber: string): Promise<IUnitOverview> {
    return await RestApiClient.get<IUnitOverview>(`modules/UnitOverviewModule/getCaseUnitOverview/${caseNumber}`);
  }
}

export default new UnitOverviewApiClient();
