import * as React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { IConversationDetails } from '../types/IConversationDetails';
import DateTimeParser from '../../../core/helpers/DateTimeParser';

interface IProps {
  conversations: IConversationDetails[];
}

class MessagesDetails extends React.Component<IProps> {
  public render() {
    return (
      <>
        <h6>{TranslationService.translateModule('Messages', ModuleNamesList.PrintCase)}</h6>
        <table className="table table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th>{TranslationService.translateModule('MessagesCategory', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('MessagesComment', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('MessagesSendDate', ModuleNamesList.PrintCase)}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.conversations.map((item) => {
              const dateDue = DateTimeParser.toLocaleDateString(item.registerDate);
              return (
                <React.Fragment key={item.id}>
                  <tr>
                    <td>{item.category}</td>
                    <td>{item.conversationComment}</td>
                    <td>{dateDue}</td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      {item.messages.map((message, index) => {
                        const date = DateTimeParser.toLocaleDateString(message.createDate);
                        return (
                          <p className="mb-1" key={item.id + '__' + index}>
                            {date} {message.content}
                          </p>
                        );
                      })}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default MessagesDetails;
