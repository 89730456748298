import React, { useState } from 'react';
import styles from './Input.module.scss';
import classNames from 'classnames';

type CheckboxInputPropsType = {
  id: string;
  name: string;
  onChange: () => void;
};

const RadioInput = (props: CheckboxInputPropsType) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked && e.target.name === props.name);

    props.onChange();
  };

  return (
    <div className={classNames(styles.inputRadio, isChecked && styles.checkboxInputChecked)}>
      {/* <div className={styles.checkboxInput}> */}
      <label htmlFor={props.id}>
        <i className="fas fa-check" />
        Standard repporter
      </label>
      <input type="radio" id={props.id} name={props.name} onChange={handleCheckboxChange} />
      {/* </div> */}
    </div>
  );
};

export default RadioInput;
