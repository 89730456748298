import * as React from 'react';

const SimpleStep = (props: any) => (
  <div className="step">
    <div className="step-content">
      <h2 className="step-title">{props.title}</h2>
      <div className="step-body" dangerouslySetInnerHTML={{ __html: props.body }} />
    </div>
  </div>
);

export default SimpleStep;
