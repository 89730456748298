import * as React from 'react';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IBaseCreditorDetails } from '../types/IBaseCreditorDetails';

class AssignCreditorsTableConfigurator {
  public getTableColumns = (baseColumns: IComponentTableColumn[]) => {
    const columns = [...baseColumns];
    this.configureSquareCheckbox(columns);
    return columns;
  };

  private configureSquareCheckbox = (columns: IComponentTableColumn[]) => {
    columns.push({
      dataField: 'selected',
      formatter: (value: any, item: IBaseCreditorDetails) => this.actionsRowData(),
      sort: false,
      searchable: false,
      text: '',
    } as IComponentTableColumn);
  };

  private actionsRowData = () => {
    return <i className="far fa-square" />;
  };
}

export default new AssignCreditorsTableConfigurator();
