export enum CaseProductType {
  Order,
  Invoice,
  Reminder,
  Prelegal,
  Legal,
  Surveillance,
  Active,
  Closed,
}
