import RegisterUserApiClient from './RegisterUserApiClient';
import { IActionStatus } from '../../core/api/types/IActionStatus';
import { IUser } from './types/IUser';

export class RegisterUserService {
  public async CreateUser(user: IUser): Promise<IActionStatus> {
    return await RegisterUserApiClient.CreateUser(user);
  }
}

export default new RegisterUserService();
