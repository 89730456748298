import CentralContainerForLoggedUser from '../layouts/CentralContainerForLoggedUser';
import CentralContainerForNotLoggedUser from '../layouts/CentralContainerForNotLoggedUser';
import FullWidthLayoutWithDynamicHeight from '../layouts/FullWidthLayoutWithDynamicHeight';
import FullWidthLayoutWithStaticHeight from '../layouts/FullWidthLayoutWithStaticHeight';
import DCAFullWidthLayoutWithDynamicHeight from '../layouts/DCAFullWidthLayoutWithDynamicHeight';

export default {
  CentralContainerForLoggedUser,
  CentralContainerForNotLoggedUser,
  FullWidthLayoutWithDynamicHeight,
  FullWidthLayoutWithStaticHeight,
  DCAFullWidthLayoutWithDynamicHeight,
};
