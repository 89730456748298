import RestApiClient from '../../core/api/RestApiClient';
import { IStylingInitData } from './types/IStylingInitData';
import { IActionStatus } from '../../core/api/types/IActionStatus';
import { IPropertySettings } from './types/ISettingsStyling';

class StylingSettingsApiClient {
  public async getSettings(): Promise<IStylingInitData> {
    return await RestApiClient.get<IStylingInitData>(`modules/StylingSettings/getSettings`);
  }

  public async updateSettings(settings: IPropertySettings[]): Promise<IActionStatus> {
    return await RestApiClient.post<IActionStatus>(`modules/StylingSettings/updateSettings`, { styles: settings });
  }
}

export default new StylingSettingsApiClient();
