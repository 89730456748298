import { INotesListRequest } from '../types/INotesListRequest';
import RestApiClient from '../../../core/api/RestApiClient';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { INotesListElement } from '../types/INotesListElement';

class NotesListApi {
  public async getNotes(request: INotesListRequest) {
    return await RestApiClient.post<IComponentTable<INotesListElement>>('modules/NotesListModule/getNotes', request);
  }
}

export default new NotesListApi();
