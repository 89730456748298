import * as React from 'react';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { ICaseNewMessage } from '../types/ICaseNewMessage';

interface IProps {
  message: ICaseNewMessage;
  backToPrevStep: () => void;
  goToNextStep: () => void;
  setMessage: (message: ICaseNewMessage) => void;
}

class Message extends React.Component<IProps> {
  public render() {
    return (
      <>
        <h6 className="create-case-header">
          {TranslationService.translateModule('MessagesHeaderMessageToHeader', ModuleNamesList.CreateNewCase)}
        </h6>

        <textarea
          value={this.props.message.message}
          id="message-content"
          className="form-control"
          rows={5}
          onChange={(event) => this.props.setMessage({ ...this.props.message, message: event.target.value })}
        />

        <footer className="mt-2">
          <div className="d-flex">
            <div className="d-inline-block form-check">
              <input
                type="checkbox"
                id="sendEmailCopy"
                checked={this.props.message.sendEmailCopy}
                onChange={() =>
                  this.props.setMessage({ ...this.props.message, sendEmailCopy: !this.props.message.sendEmailCopy })
                }
                className="form-check-input"
              />
              <label className="form-check-label" htmlFor="sendEmailCopy">
                {TranslationService.translateModule('SendCopyToEmail', ModuleNamesList.Messages)}
              </label>
            </div>
          </div>
        </footer>

        <p
          className="note mt-2"
          dangerouslySetInnerHTML={{
            __html: TranslationService.translateModule('MessagesTextAreaNote', ModuleNamesList.CreateNewCase),
          }}
        />

        <hr className="mt-2 mb-3" />

        <StepListWizardButtons
          leftButtonOnClick={this.props.backToPrevStep}
          leftButtonClassName="float-left small text-transform-none"
          leftButtonText={
            <>
              <i className="fas fa-chevron-left left" />{' '}
              {TranslationService.translateModule('MessagesBackButton', ModuleNamesList.CreateNewCase)}
            </>
          }
          rightButtonOnClick={this.props.goToNextStep}
          rightButtonClassName="c-btn--icon-right"
          rightButtonText={
            <>
              {TranslationService.translateModule('MessagesNextButton', ModuleNamesList.CreateNewCase)}{' '}
              <i className="fas fa-chevron-right right" />
            </>
          }
        />
      </>
    );
  }
}

export default Message;
