export interface IInvoiceDetails {
  amount: string;
  attachedFiles: File[];
  currency: string;
  dateAdd: Date | undefined;
  dateCollectionNotice: Date | undefined;
  dateDue: Date | undefined;
  dateDueNotice: Date | undefined;
  interest: string;
  interestTypeId: number;
  number: string;
  text: string;
  reminderFee: string;
}

export const emptyInvoice: IInvoiceDetails = {
  amount: '',
  attachedFiles: [],
  currency: '',
  dateAdd: undefined,
  dateCollectionNotice: undefined,
  dateDue: undefined,
  dateDueNotice: undefined,
  interest: '',
  interestTypeId: -1,
  number: '',
  text: '',
  reminderFee: '',
};
