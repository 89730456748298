import RestApiClient from '../../core/api/RestApiClient';
import { IDictionary } from './../../core/types/IDictionary';

class StyleSettingsService {
  public async getStyleSettings(): Promise<IDictionary<IDictionary<string>>[]> {
    return await RestApiClient.get<IDictionary<IDictionary<string>>[]>(`modules/StylingSettings/getStyleSettings`);
  }
}

export default new StyleSettingsService();
