import React from 'react';
import CheckboxInput from './CheckboxInput';
import styles from './Checkbox.module.scss';
import classNames from 'classnames';

type CheckboxPropsType = {};

class Checkbox extends React.PureComponent<CheckboxPropsType> {
  static Input = CheckboxInput;

  render() {
    return (
      <>
        <div className={classNames(styles.checkbox)}>{this.props.children}</div>
      </>
    );
  }
}

export default Checkbox;
