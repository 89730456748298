import * as React from 'react';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';

export const CreditNoteMaxInfo = (props: { amount: number; currency: string }) => {
  return (
    <span>
      {TranslationService.translateModule('CreditNoteMax', ModuleNamesList.DirectPayment)}{' '}
      {CurrencyParser.toLocaleString(props.amount)} {props.currency}
    </span>
  );
};
