import { Form, Formik } from 'formik';
import React from 'react';
import Loader from '../../../core/components/Loading/Loader';
import { IScheduleData } from '../../Reports/types/IScheduleData';
import { IScheduleParametersVM } from '../types/IScheduleParametersVM';
import { ISubscriptionDataVM } from '../types/ISubscriptionDataVM';
import Daily from '../views/Daily';
import Monthly from '../views/Monthly';
import Once from '../views/Once';
import Weekly from '../views/Weekly';
import TranslationService from './../../../core/services/TranslationService';
import { IScheduleMode } from './../types/IScheduleMode';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';

interface IProps {
  initData: any;
  data: IScheduleData;
  setScheduleOutputdata: (data: IScheduleData) => void;
  goToNextStep?: () => void;
  goToPreviousStep?: () => void;
}

interface IScheduleModes {
  group: string;
  value: string;
  key: IScheduleMode;
}

interface IState {
  form: any;
  params: any;
  formData: any;
  frequencyMode: number;
  isLoaded: boolean;
}

type ScheduleDataForm = {
  calendarDayNumbers: number[];
  monthNumbers: number[];
  weekDayNumbers: number[];
  weekNumbers: number[];
  startTime?: Date;
  stopTime?: Date;
  startTimeHours: number;
  startTimeMinutes: number;
};

type FrequencyData = {
  calendarDayNumbers: number[];
  monthNumbers: number[];
  weekDayNumbers: number[];
  weekNumbers: number[];
};

class ScheduleParams extends React.PureComponent<IProps, IState> {
  public state: IState = {
    form: {},
    params: {},
    formData: {},
    frequencyMode: 0,
    isLoaded: false,
  };

  private scheduleModes: IScheduleModes[] = [
    {
      group: 'scheduleMode',
      value: TranslationService.translateModule('ScheduleFrequencyOnce', 'ScheduleReport'),
      key: IScheduleMode.Once,
    },
    {
      group: 'scheduleMode',
      value: TranslationService.translateModule('ScheduleFrequencyDaily', 'ScheduleReport'),
      key: IScheduleMode.Daily,
    },
    {
      group: 'scheduleMode',
      value: TranslationService.translateModule('ScheduleFrequencyWeekly', 'ScheduleReport'),
      key: IScheduleMode.Weekly,
    },
    {
      group: 'scheduleMode',
      value: TranslationService.translateModule('ScheduleFrequencyMonthly', 'ScheduleReport'),
      key: IScheduleMode.Monthly,
    },
  ];

  componentDidMount() {
    const data = this.prepareFormData(this.props.initData, this.props.data);

    this.setState({
      form: data.form,
      params: data.params,
      isLoaded: true,
    });
  }

  prepareModeFormData = (initData: ISubscriptionDataVM, parameters: IScheduleParametersVM): ScheduleDataForm => {
    const initWeekDays = initData.weeklyTab.weekDays
      .filter((day) => {
        return day.selected;
      })
      .map((day) => day.id);

    const result: ScheduleDataForm = {
      startTime: parameters ? parameters.startTime : undefined,
      stopTime: parameters ? parameters.stopTime : undefined,
      startTimeHours: parameters ? parameters.startTimeHours : 0,
      startTimeMinutes: parameters ? parameters.startTimeMinutes : 0,
      monthNumbers: parameters ? parameters.monthNumbers : [],
      weekNumbers: parameters ? parameters.weekNumbers : [],
      weekDayNumbers: parameters ? parameters.weekDayNumbers : initWeekDays,
      calendarDayNumbers: parameters ? parameters.calendarDayNumbers : [],
    };

    return result;
  };

  prepareFormData = (initData: any, data: IScheduleData) => {
    const parameters = data.parameters;
    const mode = parameters ? parameters.mode : initData.mode;
    let result: any = {
      params: {},
      form: {},
    };

    result.form = this.prepareModeFormData(initData, parameters);
    result.params.mode = mode;
    result.params.onceTab = initData.onceTab;
    result.params.dailyTab = initData.dailyTab;
    result.params.weeklyTab = initData.weeklyTab;
    result.params.monthlyTab = initData.monthlyTab;

    return result;
  };

  render() {
    const colWidth = `col-${parseInt((12 / this.scheduleModes.length) as any)}`;

    return (
      <>
        <div className="c-creator__body">
          {!this.state.isLoaded ? (
            <Loader opacity={1} />
          ) : (
            <Formik
              initialValues={this.state.form}
              onSubmit={(value) => {
                this.handleFormSubmit(value);
              }}
            >
              {({ values, handleChange }) => {
                return (
                  <Form>
                    <div className="c-creator__body">
                      <div className="row">
                        <div className="col-8">
                          <div className="c-creator__box">
                            <p>{TranslationService.translateModule('LabelDefineFrequency', 'OnlineReports')}</p>

                            <div className="row">
                              {this.scheduleModes.map((mode: IScheduleModes, index: number) => (
                                <div
                                  key={index}
                                  className={`c-control c-control--radio ${this.state.params.mode === mode.key ? 'c-control--selected' : ''} ${colWidth}`}
                                >
                                  <input
                                    type="radio"
                                    name={mode.group}
                                    id={`mode-${mode.key}`}
                                    value={mode.key}
                                    checked={this.state.params.mode === mode.key}
                                    onChange={this.onChangeFrequency}
                                  />

                                  <label
                                    className="c-control__label c-control__label--radio"
                                    htmlFor={`mode-${mode.key}`}
                                  >
                                    {mode.value}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>

                          {
                            {
                              [IScheduleMode.Once]: (
                                <Once
                                  data={this.state.params.onceTab}
                                  values={values}
                                  parameters={this.props.data.parameters}
                                  handleChange={(data: any) => this.handleChange(data, handleChange)}
                                />
                              ),
                              [IScheduleMode.Daily]: (
                                <Daily
                                  params={this.state.params.dailyTab}
                                  values={values}
                                  handleChange={(data: any) => this.handleChange(data, handleChange)}
                                />
                              ),
                              [IScheduleMode.Weekly]: (
                                <Weekly
                                  data={this.state.params.weeklyTab}
                                  values={values}
                                  handleChange={(data: any) => this.handleChange(data, handleChange)}
                                />
                              ),
                              [IScheduleMode.Monthly]: (
                                <Monthly
                                  inputData={this.state.params.monthlyTab}
                                  values={values}
                                  handleChange={(data: any) => this.handleChange(data, handleChange)}
                                />
                              ),
                            }[this.state.params.mode]
                          }
                        </div>
                      </div>
                    </div>

                    <div className="c-creator__footer c-creator__footer--btn-action">
                      <Button
                        type="button"
                        label={TranslationService.translate('Back')}
                        variant={BUTTON_VARIANTS.SECONDARY}
                        size={BUTTON_SIZE.MD}
                        onClick={this.props.goToPreviousStep}
                      />

                      <Button
                        label={TranslationService.translate('Next')}
                        variant={BUTTON_VARIANTS.PRIMARY}
                        size={BUTTON_SIZE.MD}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </>
    );
  }

  handleChange = (data: { name: string; value: any }, handleChange: any) => {
    const handleChangeEvent = {
      target: {
        id: data.name,
        name: data.name,
        value: data.value,
      },
    } as React.ChangeEvent<any>;

    handleChange(handleChangeEvent);
  };

  handleFormSubmit = (values: ScheduleDataForm) => {
    if (this.props.goToNextStep) {
      const scheduleFrequencyData = this.prepareFrequencyData(values);
      this.props.setScheduleOutputdata({
        ...this.props.data,
        parameters: {
          ...values,
          ...scheduleFrequencyData,
          mode: this.state.params.mode,
        },
      });
      this.props.goToNextStep();
    }
  };

  onChangeFrequency = ({ target: { value } }: any) => {
    this.setState({
      params: {
        ...this.state.params,
        mode: parseInt(value),
      },
    });
  };

  prepareFrequencyData = (values: ScheduleDataForm) => {
    const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const WEEK_DAYS = [1, 2, 3, 4, 5, 6, 7];

    let result: FrequencyData = {
      calendarDayNumbers: [],
      monthNumbers: MONTHS,
      weekDayNumbers: WEEK_DAYS,
      weekNumbers: [],
    };

    switch (Number(this.state.params.mode)) {
      case IScheduleMode.Once:
      case IScheduleMode.Daily:
        break;
      case IScheduleMode.Weekly:
        result.weekDayNumbers = values.weekDayNumbers;
        break;
      case IScheduleMode.Monthly:
        result.calendarDayNumbers = values.calendarDayNumbers;
        break;
    }

    return result;
  };
}

export default ScheduleParams;
