import RestApiClient from '../../core/api/RestApiClient';
import { IRoleDetails } from './types/IRoleDetails';
import { IManageRoleResponse } from './types/IManageRoleResponse';
import { IComponentTable } from '../../core/types/IComponentTable';
import { IActionStatus } from 'core/api/types/IActionStatus';

class RolesManagementApiClient {
  public async getRolesTable(moduleId: number): Promise<IComponentTable<IRoleDetails>> {
    return await RestApiClient.get<IComponentTable<IRoleDetails>>(
      `modules/RolesManagement/getRoles/${moduleId}`,
      null,
      null,
      true
    );
  }
  public async getManageRoleRequest(roleUId: string): Promise<IManageRoleResponse> {
    return await RestApiClient.get<IManageRoleResponse>(`modules/RolesManagement/getManageRoleRequest/${roleUId}`);
  }
  public async addOrUpdateRole(role: IRoleDetails, permissionsIds: string[]): Promise<IActionStatus> {
    const request = {
      role,
      permissionsIds,
    };
    return await RestApiClient.post<IActionStatus>(`modules/RolesManagement/updateRolePermissions`, request);
  }
  public async removeRole(roleUId: string): Promise<IActionStatus> {
    return await RestApiClient.get<IActionStatus>(`modules/RolesManagement/removeRole/${roleUId}`);
  }
}

export default new RolesManagementApiClient();
