import RestApiClient from '../../core/api/RestApiClient';
import { IAppSetting } from './types/IAppSetting';
import { IActionStatus } from '../../core/api/types/IActionStatus';

class ApplicationSettingsApiClient {
  public async getGeneralSettings(): Promise<IAppSetting[]> {
    return await RestApiClient.get<IAppSetting[]>(`modules/ApplicationSettings/getGeneralSettings`, null, null, true);
  }

  public async getDefaultLanugage(): Promise<string> {
    return await RestApiClient.get<string>(`modules/ApplicationSettings/getDefaultLanguage`, null, null, true);
  }

  public async updateDefaultLanugage(langCode: string): Promise<IActionStatus> {
    return await RestApiClient.post<IActionStatus>(`modules/ApplicationSettings/updateDefaultLanguage`, langCode);
  }

  public async updateGeneralSettings(settings: IAppSetting[]): Promise<IActionStatus> {
    const request = {
      settings: settings,
    };
    return await RestApiClient.post<IActionStatus>(`modules/ApplicationSettings/updateGeneralSettings`, request);
  }

  public async clearCache(): Promise<IActionStatus> {
    return await RestApiClient.get<IActionStatus>(`modules/ApplicationSettings/clearCache`);
  }
}

export default new ApplicationSettingsApiClient();
