import DebtorDetailsApiClient from '../api/DebtorDetailsApiClient';
import { IInvoiceDetailsDebtorRequest } from '../types/IInvoiceDetailsDebtorRequest';

class DebtorDetailsService {
  public async getDebtorDetails(caseId: string) {
    return await DebtorDetailsApiClient.getDebtorDetails(caseId);
  }

  public async getArmDebtorDetails(request: IInvoiceDetailsDebtorRequest) {
    return await DebtorDetailsApiClient.getArmDebtorDetails(request);
  }

  public async getAgeAnalysisDebtorDetails(debtorNo: string, creditorNo: string) {
    return await DebtorDetailsApiClient.getAgeAnalysisDebtorDetails(debtorNo, creditorNo);
  }
}

export default new DebtorDetailsService();
