import * as React from 'react';
import { ContainerFactory } from '../factories/ContainerFactory';
import { ISkinProps } from '../../core/types/ISkinProps';

export function DefaultSkin(props: ISkinProps) {
  const containers = props.skin.containers;
  return (
    <div className="container">
      <ContainerFactory containers={containers} moduleIndex={0} routeParameters={props.routeParameters} />
    </div>
  );
}
