import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Status } from '../../core/api/Enums/Status';
import DatePicker from '../../core/components/Forms/DatePicker';
import Loader from '../../core/components/Loading/Loader';
import CurrencyParser from '../../core/helpers/CurrencyParser';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import { emptyComponentTable, IComponentTable } from '../../core/types/IComponentTable';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { IStore } from '../../reducers/IStore';
import { ActionButtons } from './components/ActionButtons';
import { DirectPaymentAmount } from './components/DirectPaymentAmount';
import { DirectPaymentLargerAmountNotice } from './components/DirectPaymentLargerAmountNotice';
import InvoicesTableConfigurator from './helpers/InvoicesTableConfigurator';
import { isPaymentDateValid, validatePayment } from './helpers/paymentValidator';
import DirectPaymentService from './services/InvoiceDetailsDirectPaymentService';
import { IEditableInvoiceListElement, IInvoiceListElement } from './types/InvoiceElement';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';

const InvoiceDetailsDirectPayment = (props: { currency: string } & IExtendedModuleProps) => {
  const [amount, setAmount] = useState(0);
  const [automaticDistribution, setAutomaticDistribution] = useState(true);
  const [errors, setErrors] = useState([]);
  const [table, setTable] = useState<IComponentTable<IInvoiceListElement>>(emptyComponentTable);
  const [originalInvoices, setOriginalInvoices] = useState<IEditableInvoiceListElement[]>([]);
  const [invoices, setInvoices] = useState<IEditableInvoiceListElement[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [maximumAmount, setMaximumAmount] = useState(0);
  const [paymentDate, setPaymentDate] = useState(new Date());

  useEffect(() => {
    const load = async () => {
      await loadInvoices();
    };
    load();
    // eslint-disable-next-line
  }, [props]);

  const onAutomaticDistributionChange = (value: boolean) => {
    setAutomaticDistribution(value);
    if (value) {
      resotreOriginalOrdering();
    }
  };

  const resotreOriginalOrdering = () => {
    const originalOrder = originalInvoices.map((i) => ({ invoiceNo: i.invoiceNo }));
    setInvoices([
      ...invoices.map((i) => {
        const originalOrderItem = originalOrder.filter((ooi) => ooi.invoiceNo === i.invoiceNo);
        const originalIndex = originalOrder.indexOf(originalOrderItem[0]);
        return {
          ...i,
          order: originalIndex + 1,
        };
      }),
    ]);
  };

  const onSave = async () => {
    const errors = validatePayment(invoices, maximumAmount, amount, paymentDate);

    if (!errors.length) {
      try {
        setIsLoading(true);

        const invoiceOrdering = invoices
          .map((i) => ({ [i.invoiceNo]: i.order }))
          .reduce((prev, next) => ({ ...prev, ...next }));
        const result = await DirectPaymentService.savePayment({
          accountNo: props.accountNo,
          creditorNo: props.creditorNo,
          invoiceNo: props.invoiceNo,
          amount,
          moduleInstanceId: props.module.id,
          paidDate: paymentDate,
          debtorNo: invoices[0].debtorNo,
          automaticDistribution,
          invoiceOrdering,
        });

        InfoMessageService.displayActionStatus(result, true);

        setIsLoading(true);

        callExitHandler(result.status === Status.Success);
      } catch (err: any) {
        let errorCodes = [];
        if (err.response && err.response.data) {
          errorCodes = Object.keys(err.response.data)
            .map((k) => err.response.data[k])
            .reduce((arr: any[], next: any[]) => [...arr, ...next]);
        }

        setIsLoading(false);
        setErrors(errorCodes);
      }
    } else {
      setErrors([]);
    }
  };

  const onCancel = () => {
    callExitHandler(false);
  };

  const callExitHandler = (actionStatus?: boolean) => {
    if (props.exit) {
      props.exit(actionStatus);
    }
  };

  const loadInvoices = async () => {
    setIsLoading(true);

    try {
      const invoicesTable = await DirectPaymentService.getInvoices({
        accountNo: props.accountNo,
        creditorNo: props.creditorNo,
        invoiceNo: props.invoiceNo,
        moduleInstanceId: props.module.id,
      });

      const invoices = invoicesTable.values.map((i: IEditableInvoiceListElement, index: number) => ({
        ...i,
        order: index + 1,
      }));
      const amount = invoices.map((i) => i.remainingAmount).reduce((sum, next) => sum + next);

      setIsLoading(false);
      setTable(invoicesTable);
      setInvoices(invoices);
      setOriginalInvoices(invoices);
      setMaximumAmount(amount);
      setAmount(amount);
    } catch {
      InfoMessageService.error(TranslationService.translateModule('ErrorLoadingInvoices', props.module.name));
      setIsLoading(false);
    }
  };

  return (
    <article className="l-module iddp" id="iddp">
      {isLoading && <Loader opacity={1} />}
      <section className="l-module__section">
        <div className="row">
          <div className="col-12 col-sm-8 col-md-6">
            <div className="c-control">
              <div className="row row--aligned">
                <label className="c-control__label col-6" htmlFor="directPaymentDate">
                  {TranslationService.translateModule('PaymentReceivedOn', ModuleNamesList.DirectPayment)}
                </label>
                <div className="col-6">
                  <DatePicker
                    inputId="directPaymentDate"
                    inputName="paymentDate"
                    value={paymentDate}
                    onChange={(date: Date) => {
                      setPaymentDate(date);
                    }}
                  />
                </div>
                <div className={`c-input-error ${isPaymentDateValid(invoices, paymentDate) ? 'd-none' : null}`}>
                  {TranslationService.translateModule(
                    'PaymentDateBetweenLowestInvoiceDateAndTodayError',
                    ModuleNamesList.DirectPayment
                  )}
                </div>
              </div>
            </div>
            <div className="c-control">
              <div className="row row--aligned">
                <label className="c-control__label col-6" htmlFor="time">
                  {TranslationService.translateModule('MaximumAmountOfDirectPayment', ModuleNamesList.DirectPayment)}
                </label>

                <div className="col-6">{CurrencyParser.toLocaleString(maximumAmount)}</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6">
            <div className="c-control">
              <div className="row row--aligned">
                <label className="c-control__label col-6" htmlFor="directPaymentAmount">
                  {TranslationService.translateModule('Amount', ModuleNamesList.DirectPayment)}
                </label>
                <div className="col-6">
                  <DirectPaymentAmount maximumAmount={maximumAmount} onChange={setAmount} />
                </div>
              </div>
            </div>
            <div className="c-control">
              <div className={`row row--aligned ${invoices.length === 1 ? 'd-none' : ''}`}>
                <label className="c-control__label col-6" htmlFor="directPaymentAutomaticDistribution">
                  {TranslationService.translateModule('DistributeAutomatically', ModuleNamesList.DirectPayment)}
                </label>

                <div className="col-6">
                  <input
                    id="directPaymentAutomaticDistribution"
                    type="checkbox"
                    disabled={invoices.length === 1}
                    checked={automaticDistribution}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onAutomaticDistributionChange(event.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="c-control">
              <DirectPaymentLargerAmountNotice />
            </div>
          </div>
        </div>
      </section>

      <section className="l-module__section">
        <BootstrapTableContainer
          remote={false}
          data={invoices}
          keyField="invoiceNo"
          wrapperClasses="bt bt--modal-margins"
          paginationProps={{
            alwaysShowAllBtns: invoices.length > 1,
            hideSizePerPage: invoices.length <= 1,
            hidePageListOnlyOnePage: invoices.length <= 1,
          }}
          classes="bt__table bt-table"
          cellEdit={{
            mode: 'click',
            blurToSave: true,
            autoSelectText: true,
            afterSaveCell: (oldValue: any, newValue: any, row: any) => {
              const item = invoices.filter((i) => i.invoiceNo === row.invoiceNo);
              const itemIdx = invoices.indexOf(item[0]);
              const editedItem = { ...item[0] };
              editedItem.order = newValue;

              setInvoices([...invoices.slice(0, itemIdx), editedItem, ...invoices.slice(itemIdx + 1)]);
            },
          }}
          columns={InvoicesTableConfigurator.getInvoicesListTableColumns([...table.columns], automaticDistribution)}
        />

        {errors.length > 0 && (
          <div className="row d-flex validation-errors-summary">
            <div className="card card-body alert-danger">
              <ul>
                {errors.map((err, index: number) => (
                  <li key={index}>{TranslationService.translateModule(err, props.module.name)}</li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className="row">
          <ActionButtons onSave={onSave} onCancel={onCancel} />
        </div>
      </section>
    </article>
  );
};

const mapStateToProps = (state: IStore) => ({
  currency: state.currency,
});

export default connect(mapStateToProps)(InvoiceDetailsDirectPayment);
