import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../reducers/IStore';
import { ILanguage } from '../../core/types/ILanguage';
import { ISettingInputComponent } from './types/ISettingInputComponent';
import ApplicationSettingsApiClient from './ApplicationSettingsApiClient';
import ComponentInputTypeMapper from './helpers/ComponentInputTypeMapper';
import { IAppSetting } from './types/IAppSetting';
import TranslationService from '../../core/services/TranslationService';
import { Status } from '../../core/api/Enums/Status';
import InfoMessageService from '../../core/services/InfoMessageService';
import { ModuleNamesList } from 'core/lists/ModuleNamesList';
import Loader from '../../core/components/Loading/Loader';
import { Button, BUTTON_VARIANTS } from '../../core/components/Button/Button';
import ErrorResponseHandler from '../../core/services/ErrorResponseHandler';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../core/components/Forms/FormGroup';
import { SimpleSelect } from '../../core/components/Forms/SimpleSelect';
import { ErrorMessage } from 'formik';

const ApplicationSettings = (props: { languages: ILanguage[]; currentCultureCode: string }) => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [hasAccess, setHasAccess] = useState<boolean>(true);
  const [settings, setSettings] = useState<IAppSetting[]>([]);
  const [languages, setLanguages] = useState<any[]>([]);
  const [cultureCode, setCultureCode] = useState<string>('');

  const updateSettingValue = (settingName: string, settingValue: string) => {
    const settingsCopy = [...settings];
    const setting = [...settingsCopy].find((item) => item.name === settingName);
    if (setting) {
      setting.value = settingValue;
      setSettings(settingsCopy);
    }
  };

  const updateGeneralSettings = async () => {
    setIsDataLoading(true);

    try {
      const result1 = await ApplicationSettingsApiClient.updateGeneralSettings(settings);
      const result2 = await ApplicationSettingsApiClient.updateDefaultLanugage(cultureCode);

      if (result1.status === Status.Success && result2.status === Status.Success) {
        InfoMessageService.success(
          TranslationService.translateModule('ActionMessageUpdatedSuccessfully', ModuleNamesList.ApplicationSettings)
        );
      } else {
        InfoMessageService.error(
          TranslationService.translateModule('ActionMessageUpdateError', ModuleNamesList.ApplicationSettings)
        );
      }
    } finally {
      setIsDataLoading(false);
    }
  };

  const fetchSettings = async () => {
    setIsDataLoading(true);

    try {
      setLanguages([]);

      const defaultLang = await ApplicationSettingsApiClient.getDefaultLanugage();
      setCultureCode(defaultLang);

      const settings = await ApplicationSettingsApiClient.getGeneralSettings();
      setSettings(settings);
    } catch (error: any) {
      const hasAccess = ErrorResponseHandler.hasAccess(error);
      setHasAccess(hasAccess);
    } finally {
      setIsDataLoading(false);
    }
  };

  const clearCache = async () => {
    setIsDataLoading(true);
    const result = await ApplicationSettingsApiClient.clearCache();
    InfoMessageService.displayActionStatus(result);
    setIsDataLoading(false);
  };

  const getLanguageOptions = () => {
    const languages = props.languages.map((language) => {
      return {
        key: language.code,
        value: TranslationService.translate(`${language.name}`),
      };
    });

    setLanguages(languages);
  };

  useEffect(() => {
    fetchSettings();
    getLanguageOptions();
  }, []);

  return !isDataLoading && !hasAccess ? (
    <NoPermissionsComponent />
  ) : (
    <article className="l-module">
      <section className="l-module__section l-module__section--head">
        <h1 className="l-module__title">
          <i className="fas fa-cogs" />
          {TranslationService.translateModule('LeftMenuItemApplicationSettings', ModuleNamesList.ApplicationSettings)}:
          <strong className="l-module__title-highlighted">
            {TranslationService.translateModule('LeftMenuItemGeneralSettings', ModuleNamesList.ApplicationSettings)}
          </strong>
        </h1>
      </section>
      <section className="l-module__section l-module__section--filter mb-3">
        <Button
          label={TranslationService.translateModule('ButtonClearCache', ModuleNamesList.ApplicationSettings)}
          variant={BUTTON_VARIANTS.PRIMARY}
          onClick={clearCache}
        />
      </section>
      <section className={`l-module__section ${isDataLoading ? 'l-module__section--loading' : ''}`}>
        {isDataLoading ? (
          <Loader opacity={0.5} />
        ) : (
          <div className="card mb-4">
            <h6 className="card-header">
              <div>
                {TranslationService.translateModule('LeftMenuItemGeneralSettings', ModuleNamesList.ApplicationSettings)}
              </div>
            </h6>

            <div className="card-body">
              {settings.map((item) => {
                const props: ISettingInputComponent = {
                  value: item.value,
                  name: item.name,
                  onChange: updateSettingValue,
                };
                const Component = ComponentInputTypeMapper.getSettingInput(item.settingType) as any;

                return (
                  <div className={`form-group row`} key={item.id}>
                    <label className={'col-sm-3 col-form-label'} htmlFor={item.name}>
                      {item.description}
                    </label>
                    <div className="col-sm-9">
                      <Component {...props} />
                    </div>
                  </div>
                );
              })}

              <FormGroup
                variant={FORM_GROUP_VARIANTS.BLOCK}
                name="language"
                label={TranslationService.translateModule(
                  'DefaultLanguage',
                  ModuleNamesList.MessageTemplatesManagement
                )}
                bolded={true}
                render={(name) => (
                  <>
                    <SimpleSelect
                      id={name}
                      options={languages}
                      value={cultureCode}
                      onChangeHandle={(language) => setCultureCode(language.key)}
                    />
                    <ErrorMessage name={name} component="div" className="error error-message" />
                  </>
                )}
              />
              <button className="btn btn--normal btn-color-2 btn-sm mt-1 float-right" onClick={updateGeneralSettings}>
                {TranslationService.translate('Save')}
              </button>
            </div>
          </div>
        )}
      </section>
    </article>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    languages: state.languages,
  };
};

export default connect(mapStateToProps)(ApplicationSettings);
