import React from 'react';

type RadioLabelPropsType = {
  children: React.ReactChild;
};

const RadioLabel = (props: RadioLabelPropsType) => {
  return <>{props.children}</>;
};

export default RadioLabel;
