import { TransferInvoicesSelectFilter } from './TransferInvoicesSelectFilter';

export interface ITransferInvoiceFilterRequest {
  filteredBy?: TransferInvoicesSelectFilter;
  filterByValue?: string;
  balanceFrom?: string;
  balanceTo?: string;
  invoiceNoFrom?: string;
  invoiceNoTo?: string;
}

export const emptyTransferInvoiceFilterRequest: ITransferInvoiceFilterRequest = {};
