export type SearchRequest = {
  unitId: string;
  currentPage: number;
  sizePerPage: number;
  sortBy: string;
  sortDirection: string;
};

export const emptySearchRequest: SearchRequest = {
  unitId: '',
  currentPage: 1,
  sizePerPage: 10,
  sortBy: 'unitNo',
  sortDirection: 'asc',
};
