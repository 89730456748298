import RestApiClient from '../../../core/api/RestApiClient';
import { IDebtorDetails } from '../types/IDebtorDetails';
import { IInvoiceDetailsDebtorRequest } from '../types/IInvoiceDetailsDebtorRequest';

class DebtorDetailsApiClient {
  public async getDebtorDetails(caseId: string) {
    return await RestApiClient.get<IDebtorDetails>(`modules/DebtorDetails/forCase/${caseId}`);
  }

  public async getAgeAnalysisDebtorDetails(debtorNo: string, creditorNo: string) {
    return await RestApiClient.get<IDebtorDetails>(`modules/DebtorDetails/byDebtorNo/${debtorNo},${creditorNo}`);
  }

  public async getArmDebtorDetails(request: IInvoiceDetailsDebtorRequest) {
    return await RestApiClient.post<IDebtorDetails>(`modules/DebtorDetails/forInvoice`, request);
  }
}

export default new DebtorDetailsApiClient();
