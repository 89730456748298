export const CHANGE_CURRENT_CREDITOR = 'CHANGE_CURRENT_CREDITOR';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const FETCH_APPLICATION_SETTINGS_FAILED = 'FETCH_APPLICATION_SETTINGS_FAILED';
export const FETCH_APPLICATION_SETTINGS_IN_PROGRESS = 'FETCH_APPLICATION_SETTINGS_IN_PROGRESS';
export const FETCH_APPLICATION_SETTINGS_SUCCEEDED = 'FETCH_APPLICATION_SETTINGS_SUCCEEDED';
export const FETCH_CREDITORS_FAILED = 'FETCH_CREDITORS_FAILED';
export const FETCH_CREDITORS_IN_PROGRESS = 'FETCH_CREDITORS_IN_PROGRESS';
export const FETCH_CREDITORS_SUCCEEDED = 'FETCH_CREDITORS_SUCCEEDED';
export const FETCH_LANGUAGES_FAILED = 'FETCH_LANGUAGES_FAILED';
export const FETCH_LANGUAGES_IN_PROGRESS = 'FETCH_LANGUAGES_IN_PROGRESS';
export const FETCH_LANGUAGES_SUCCEEDED = 'FETCH_LANGUAGES_SUCCEEDED';
export const FETCH_PAGES_FAILED = 'FETCH_PAGES_FAILED';
export const FETCH_PAGES_IN_PROGRESS = 'FETCH_PAGES_IN_PROGRESS';
export const FETCH_PAGES_SUCCEEDED = 'FETCH_PAGES_SUCCEEDED';
export const FETCH_STYLING_SETTINGS_FAILED = 'FETCH_STYLING_SETTINGS_FAILED';
export const FETCH_STYLING_SETTINGS_IN_PROGRESS = 'FETCH_STYLING_SETTINGS_IN_PROGRESS';
export const FETCH_STYLING_SETTINGS_SUCCEEDED = 'FETCH_STYLING_SETTINGS_SUCCEEDED';
export const FETCH_TRANSLATIONS_FAILED = 'FETCH_TRANSLATIONS_FAILED';
export const FETCH_TRANSLATIONS_IN_PROGRESS = 'FETCH_TRANSLATIONS_IN_PROGRESS';
export const FETCH_TRANSLATIONS_SUCCEEDED = 'FETCH_TRANSLATIONS_SUCCEEDED';
export const FETCH_USER_CREDITOR_ROLES_FAILED = 'FETCH_USER_CREDITOR_ROLES_FAILED';
export const FETCH_USER_CREDITOR_ROLES_IN_PROGRESS = 'FETCH_USER_CREDITOR_ROLES_IN_PROGRESS';
export const FETCH_USER_CREDITOR_ROLES_SUCCEEDED = 'FETCH_USER_CREDITOR_ROLES_SUCCEEDED';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION';
export const FETCH_LEFTMENU_FAILED = 'FETCH_LEFTMENU_FAILED';
export const FETCH_LEFTMENU_IN_PROGRESS = 'FETCH_LEFTMENU_IN_PROGRESS';
export const FETCH_LEFTMENU_SUCCEEDED = 'FETCH_LEFTMENU_SUCCEEDED';
export const FETCH_PERMISSIONS_FAILED = 'FETCH_PERMISSIONS_FAILED';
export const FETCH_PERMISSIONS_IN_PROGRESS = 'FETCH_PERMISSIONS_IN_PROGRESS';
export const FETCH_PERMISSIONS_SUCCEEDED = 'FETCH_PERMISSIONS_SUCCEEDED';
export const FETCH_TILES_COUNTERS_FAILED = 'FETCH_TILES_COUNTERS_FAILED';
export const FETCH_TILES_COUNTERS_IN_PROGRESS = 'FETCH_TILES_COUNTERS_IN_PROGRESS';
export const FETCH_TILES_COUNTERS_SUCCEEDED = 'FETCH_TILES_COUNTERS_SUCCEEDED';
