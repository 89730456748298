export enum CustomerTransactionsListTableColumns {
  DocNo,
  AccountNo,
  Product,
  Description,
  RegDate,
  DueDate,
  Status,
  CaseNo,
  DebtorName,
  Capital,
  OverdueDays,
  Balance,
  DebtorNo,
  CreditorNo,
}
