import CreditorsApiClient from '../api/CreditorsApiClient';

class CreditorsService {
  public async getCreditorsForLoggedUser() {
    return await CreditorsApiClient.getCreditorsForLoggedUser();
  }
  public async getActiveCreditorsForLoggedUser() {
    return await CreditorsApiClient.getActiveCreditorsForLoggedUser();
  }
}

export default new CreditorsService();
