import React from 'react';
import classNames from 'classnames';
import styles from '../Card.module.scss';

enum CardAsideVariants {
  Secondary = 'secondary',
}

type CardAsidePropsType = {
  variant?: CardAsideVariants;
  children: React.ReactChild;
};

const CardAside = (props: CardAsidePropsType) => {
  return (
    <div className={classNames(styles['card-aside'], props.variant && styles[`card-aside--${props.variant}`])}>
      {props.children}
    </div>
  );
};

export { CardAside, CardAsideVariants };
