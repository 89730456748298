export interface IDebtorDetails {
  address: string;
  address2: string;
  city: string;
  postalCode: string;
  debtorRef: string;
  domainId: string;
  name: string;
  phone: string;
  scoringRate: string;
  totalDebt?: number;
  activeCases?: number;
  identNo: string;
}

export const emptyDebtorDetails: IDebtorDetails = {
  address: '',
  address2: '',
  city: '',
  postalCode: '',
  debtorRef: '',
  domainId: '',
  name: '',
  phone: '',
  scoringRate: '',
  totalDebt: 0,
  identNo: '',
};
