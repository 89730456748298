import UserIdentityService from './UserIdentityService';

class CreditorAccessValidator {
  private userIdentityService: UserIdentityService;

  constructor() {
    this.userIdentityService = new UserIdentityService();
  }

  public hasPermissionTo(creditorNumber: string, permissionName: string): boolean {
    const creditorPermissions = this.userIdentityService.GetUserPermissions();
    if (creditorPermissions[creditorNumber] === undefined) {
      return false;
    }
    const permissions = creditorPermissions[creditorNumber];

    if (permissions.indexOf(permissionName) > -1) {
      return true;
    }
    return false;
  }
}

export default new CreditorAccessValidator();
