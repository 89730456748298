import React from 'react';
import { IStore } from '../../reducers/IStore';
import { connect } from 'react-redux';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';
import { Card } from '../../core/components/Card/Card';
import { CardContent } from '../../core/components/Card/components/CardContent';
import { Form, Formik } from 'formik';
import { IModuleProps } from '../../core/types/IModuleProps';
import * as yup from 'yup';
import { CardHeader } from '../../core/components/Card/components/CardHeader';
import ChangePasswordApiClient from './ChangePasswordApiClient';
import FormGroupField from '../../core/components/Forms/FormGroupField';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import './ChangePassword.scss';

interface IProps extends IModuleProps {
  userPasswordMinLength: string;
}

class ChangePassword extends React.Component<IProps> {
  private codesToMessagesMapping = {
    PasswordMismatch: TranslationService.translateModule('OldPasswordInvalid', this.props.module.name),
  };

  private validationMessages = {
    atLeastOneLowercase: TranslationService.translate('ValidationMessagePasswordAtLeastOneLowercaseLetter'),
    atLeastOneNumber: TranslationService.translate('ValidationMessagePasswordAtLeastOneNumber'),
    atLeastOneSpecial: TranslationService.translate('ValidationMessagePasswordAtLeastOneSpecial'),
    atLeastOneUppercase: TranslationService.translate('ValidationMessagePasswordAtLeastOneUpercaseLetter'),
    oldPasswordInvalid: TranslationService.translateModule('OldPasswordInvalid', this.props.module.name),
    passwordMinimumLength: TranslationService.translateFormat(
      'ValidationMessagePasswordMinimumLength',
      this.props.userPasswordMinLength
    ),
    repeat: TranslationService.translate('ValidationMessageRepeatedIncorrectly'),
    required: TranslationService.translate('ValidationMessageRequiredField'),
  };

  private validationSchema = yup.object().shape({
    oldPassword: yup.string().required(this.validationMessages.required),
    newPassword: yup
      .string()
      .required(this.validationMessages.required)
      .min(+this.props.userPasswordMinLength, this.validationMessages.passwordMinimumLength)
      .matches(/[a-z]+/, this.validationMessages.atLeastOneLowercase)
      .matches(/[A-Z]+/, this.validationMessages.atLeastOneUppercase)
      .matches(/[0-9]+/, this.validationMessages.atLeastOneNumber)
      .matches(/[!@#$%&]+/, this.validationMessages.atLeastOneSpecial),
    repeatedPassword: yup
      .string()
      .required(this.validationMessages.required)
      .test('is-password-repeated', this.validationMessages.repeat, function (value) {
        const { newPassword } = this.parent;
        return value === newPassword;
      }),
  });

  public render() {
    return (
      <Card>
        <>
          <CardHeader>
            <h1 className="c-heading">
              {TranslationService.translateModule('ChangePasswordTitle', this.props.module.name)}
            </h1>
          </CardHeader>

          <CardContent>
            <Formik
              onSubmit={this.changePassword}
              validationSchema={this.validationSchema}
              initialValues={{
                oldPassword: '',
                newPassword: '',
                repeatedPassword: '',
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form className="c-form">
                  <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                      <FormGroupField
                        fieldName="oldPassword"
                        type="password"
                        label={TranslationService.translateModule('OldPassword', this.props.module.name)}
                        labelClass="col-4 c-control__label"
                        inputContainerClass="col-8 c-control__input"
                        value={values.oldPassword}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />

                      <FormGroupField
                        fieldName="newPassword"
                        type="password"
                        label={TranslationService.translateModule('NewPassword', this.props.module.name)}
                        labelClass="col-4 c-control__label"
                        inputContainerClass="col-8 c-control__input "
                        value={values.newPassword}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />

                      <FormGroupField
                        fieldName="repeatedPassword"
                        type="password"
                        label={TranslationService.translateModule('RepeatPassword', this.props.module.name)}
                        labelClass="col-4 c-control__label"
                        inputContainerClass="col-8 c-control__input "
                        value={values.repeatedPassword}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />

                      <div className="row justify-content-end mb-4">
                        <div className="col-auto">
                          <Button
                            variant={BUTTON_VARIANTS.PRIMARY}
                            size={BUTTON_SIZE.MD}
                            type="submit"
                            label={TranslationService.translateModule('Save', this.props.module.name)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </CardContent>
        </>
      </Card>
    );
  }

  private changePassword = (values: any, actions: any) => {
    ChangePasswordApiClient.changePassword(values.oldPassword, values.newPassword)
      .then((_) => {
        actions.resetForm();
        InfoMessageService.success(TranslationService.translateModule('SuccessMessage', this.props.module.name));
      })
      .catch((err) => {
        if (err!.response!.data!.messages!) {
          for (const error of err.response.data.messages) {
            const code = this.codesToMessagesMapping[error] || 'ServerErrorMessage';
            InfoMessageService.error(TranslationService.translateModule(code, this.props.module.name));
          }
        } else {
          InfoMessageService.error(TranslationService.translateModule('ServerErrorMessage', this.props.module.name));
        }
      });
  };
}

const mapStateToProps = (state: IStore) => ({
  userPasswordMinLength: state.applicationSettings.UserPasswordMinLength,
});

export default connect(mapStateToProps)(ChangePassword);
