import * as React from 'react';
import { Field, ErrorMessage } from 'formik';
import { IFormGroupRadioButtonProps } from '../../types/IFormGroupRadioButtonProps';

const FormGroupRadioButton = (props: IFormGroupRadioButtonProps) => (
  <div className="form-group row">
    <label className={(props.labelClass ? props.labelClass : 'col-sm-3') + ' col-form-label'} htmlFor={props.fieldName}>
      {props.label}
    </label>

    <div className={(props.inputContainerClass ? props.inputContainerClass : 'col-sm-9') + ' col-form-field'}>
      {props.options.map((option, index) => {
        return (
          <label className="radio-inline" key={index}>
            <Field
              type="radio"
              name={props.fieldName}
              value={option.value}
              checked={option.value.toString() === props.value.toString()}
              disabled={props.disabled}
              className={
                'form-check-input' + (props.errors[props.fieldName] && props.touched[props.fieldName] ? ' error' : '')
              }
              onChange={props.handleChange}
            />

            {option.label}
          </label>
        );
      })}
      <ErrorMessage name={props.fieldName} component="div" className="error error-message" />
    </div>
  </div>
);

export default FormGroupRadioButton;
