import * as React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import { IPaymentDetails } from '../types/IPaymentDetails';

interface IProps {
  payments: IPaymentDetails;
}

class PaymentDetails extends React.Component<IProps> {
  public render() {
    return (
      <>
        <h6>{TranslationService.translateModule('PaymentDetails', ModuleNamesList.PrintCase)}</h6>
        <table className="table table-bordered table-sm mb-0">
          <tbody>
            <tr>
              <td>{TranslationService.translateModule('PaymentDetailsCapital', ModuleNamesList.PrintCase)}</td>
              <td>{CurrencyParser.toLocaleString(-1 * this.props.payments.paidCapital)}</td>
            </tr>
            <tr>
              <td>{TranslationService.translateModule('PaymentDetailsLegalFee', ModuleNamesList.PrintCase)}</td>
              <td>{CurrencyParser.toLocaleString(this.props.payments.paidLegalFees)}</td>
            </tr>
            <tr>
              <td>{TranslationService.translateModule('PaymentDetailsFee1', ModuleNamesList.PrintCase)}</td>
              <td>{CurrencyParser.toLocaleString(this.props.payments.paidFees1)}</td>
            </tr>
            <tr>
              <td>{TranslationService.translateModule('PaymentDetailsFee2', ModuleNamesList.PrintCase)}</td>
              <td>{CurrencyParser.toLocaleString(this.props.payments.paidFees2)}</td>
            </tr>
            <tr>
              <td>{TranslationService.translateModule('PaymentDetailsInterest', ModuleNamesList.PrintCase)}</td>
              <td>{CurrencyParser.toLocaleString(this.props.payments.paidInterest)}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default PaymentDetails;
