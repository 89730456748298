import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../Button/Button';
import SessionStorageService from '../../services/SessionStorageService';
import TranslationService from '../../services/TranslationService';

const IEWarning = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isIEDetected, setIsIeDetected] = useState<boolean>(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);

    SessionStorageService.set('ie-warning', '1');
  };

  const detectIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    let isIE = false;

    if (msie > 0) {
      isIE = true;
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      isIE = true;
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      isIE = true;
    }

    // other browser
    return isIE;
  };

  useEffect(() => {
    if (isIEDetected) {
      const isModalOpen = SessionStorageService.get('ie-warning') === '1' ? false : true;
      setIsModalOpen(isModalOpen);
    }
  }, [isIEDetected]);

  useEffect(() => {
    const isIEBrowser = detectIE();

    setIsIeDetected(isIEBrowser);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        modalData={{
          className: 'c-modal--float',
          content: (
            <div className="ie-warning">
              <i className="fas fa-info-circle" />

              <p>{TranslationService.translate('IEWarningInfoFirstText')}</p>

              <p>{TranslationService.translate('IEWarningInfoSecondText')}</p>

              <p>{TranslationService.translate('IEWarningInfoThirdText')}</p>

              <Button
                id="closeModalIEBtn"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTON_SIZE.SM}
                label={TranslationService.translate('IEWarningInfoBtnText')}
                onClick={handleCloseModal}
              />
            </div>
          ),
        }}
      />

      {isIEDetected && (
        <div className="ie-warning--static">
          <i className="fas fa-info-circle" />

          <div>
            <p>{TranslationService.translate('IEWarningInfoFirstText')}</p>

            <p>{TranslationService.translate('IEWarningInfoSecondText')}</p>

            <p>{TranslationService.translate('IEWarningInfoThirdText')}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default IEWarning;
