import * as React from 'react';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import Loader from '../../core/components/Loading/Loader';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import { emptyComponentTable, IComponentTable } from '../../core/types/IComponentTable';
import { IModuleProps } from '../../core/types/IModuleProps';
import DocumentsListTableConfigurator from './Helpers/DocumentsListTableConfigurator';
import DocumentsListService from './services/DocumentsListService';
import { IDocumentListElement } from './types/IDocumentsListElement';
import { IDocumentsListRequest } from './types/IDocumentsListRequest';
import { EventsList } from '../../core/lists/EventsList';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import './DocumentsList.scss';

interface IState {
  isDataLoading: boolean;
  documentsTable: IComponentTable<IDocumentListElement>;
}

export default class DocumentsList extends React.Component<IModuleProps, IState> {
  public state: IState = {
    isDataLoading: false,
    documentsTable: { ...emptyComponentTable },
  };

  private moduleEvents: IModuleEventSubscription[] | undefined;

  public componentDidMount = async () => {
    this.registerModuleEvents();
    await this.fetchDocuments();
  };

  public downloadDocument = async (documentId: number, caseNumber: string) => {
    let documentsList = [...this.state.documentsTable.values];
    const documentIndex = documentsList.findIndex((elem: IDocumentListElement) => elem.id === documentId);
    let document = { ...documentsList[documentIndex] };
    document.isDownloading = true;
    documentsList[documentIndex] = document;
    this.setState({
      documentsTable: {
        ...this.state.documentsTable,
        values: documentsList,
      },
    });
    try {
      await DocumentsListService.getDocument(documentId, caseNumber);
    } finally {
      documentsList = [...this.state.documentsTable.values];
      document = { ...documentsList[documentIndex] };
      document.isDownloading = false;
      documentsList[documentIndex] = document;
      this.setState({
        documentsTable: {
          ...this.state.documentsTable,
          values: documentsList,
        },
      });
    }
  };

  public render() {
    return (
      <div className="documents-list position-relative mt-4">
        <section className={`l-module__section ${this.state.isDataLoading ? 'l-module__section--loading' : ''}`}>
          {this.state.isDataLoading ? <Loader opacity={0.5} /> : null}
          <BootstrapTableContainer
            remote={false}
            wrapperClasses="bt"
            paginationProps={{}}
            classes="bt__table bt-table"
            keyField="id"
            data={this.state.documentsTable.values}
            columns={
              this.state.documentsTable.columns.length === 0
                ? []
                : DocumentsListTableConfigurator.getDocumentsListTableColumns(
                    this.state.documentsTable,
                    this.downloadDocument
                  )
            }
          />
        </section>
      </div>
    );
  }

  private registerModuleEvents() {
    this.moduleEvents = [{ name: EventsList.CASE_DATA_UPDATED, callback: this.fetchDocuments }];

    ModuleEventSubscriber.registerEvents(this.moduleEvents);
  }

  private fetchDocuments = async () => {
    this.setState({
      isDataLoading: true,
    });

    try {
      const { id } = this.props.routeParameters;
      const request = {
        caseId: id,
        moduleInstanceId: this.props.module.id,
      } as IDocumentsListRequest;

      const result = await DocumentsListService.getDocuments(request);

      this.setState({
        isDataLoading: false,
        documentsTable: result,
      });
    } catch {
      this.setState(
        {
          isDataLoading: false,
        },
        () => {
          InfoMessageService.error(
            TranslationService.translateModule('ErrorLoadingDocuments', this.props.module.name),
            TranslationService.translate('Error')
          );
        }
      );
    }
  };
}
