enum UserAuthorizationType {
  Creditor = 0,
  Dca = 1,
}

export interface IUser {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  emailRepeat: string;
  password: string;
  passwordRepeat: string;
  confirmationCodePhone: string;
  confirmationCodeEmail: string;
  userAuthorizationType: UserAuthorizationType;
}

export const emptyUser: IUser = {
  confirmationCodeEmail: '',
  confirmationCodePhone: '',
  email: '',
  emailRepeat: '',
  firstName: '',
  lastName: '',
  password: '',
  passwordRepeat: '',
  phone: '',
  userAuthorizationType: UserAuthorizationType.Creditor,
};
