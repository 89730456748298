import React from 'react';
import { IContainerProps } from '../types/IContainerProps';
import { IModule } from '../api/types/IModule';
import { ModuleFactory } from '../factories/ModuleFactory';

const AllModules = (props: IContainerProps) => {
  const {
    container: { modules },
  } = props;

  return modules ? (
    <>
      {modules.map((_: IModule, index: number) => (
        <ModuleFactory
          key={index}
          modules={modules}
          moduleIndex={index}
          staticHeight={props.staticHeight}
          routeParameters={props.routeParameters}
        />
      ))}
    </>
  ) : (
    <>[no modules]</>
  );
};

export const ModuleContainer = (props: IContainerProps) => {
  return props.container ? (
    <AllModules container={props.container} staticHeight={props.staticHeight} routeParameters={props.routeParameters} />
  ) : (
    <div>[missing container]</div>
  );
};
