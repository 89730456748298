import React from 'react';
import styles from '../Accordion.module.scss';
import { useAccordionContext } from '../context/AccordionContext';
// @ts-ignore
import classNames from 'classnames';

type AccordionHeaderPropsType = {
  index: number;
  children: React.ReactNode;
  className?: string;
  activeClass?: string;
};

const AccordionHeader = (props: AccordionHeaderPropsType) => {
  const { toggle, currentOpen, isOpen } = useAccordionContext();

  const handleClick = () => {
    if (toggle) {
      toggle(props.index);
    }
  };

  return (
    <div
      className={classNames(
        styles.header,
        props.className,
        props.index === currentOpen && isOpen && `${styles.headerIsOpen} ${props.activeClass}`
      )}
      onClick={handleClick}
    >
      {props.children}
    </div>
  );
};

export default AccordionHeader;
