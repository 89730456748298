import * as React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import { IActivityDetails } from '../types/IActivityDetails';
import DateTimeParser from '../../../core/helpers/DateTimeParser';

interface IProps {
  activities: IActivityDetails[];
}

class ActivitiesDetails extends React.Component<IProps> {
  public render() {
    return (
      <>
        <h6>{TranslationService.translateModule('Activities', ModuleNamesList.PrintCase)}</h6>
        <table className="table table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th>{TranslationService.translateModule('ActivitiesDate', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('ActivitiesDescription', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('ActivitiesPaid', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('ActivitiesLegalFee', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('ActivitiesFee1', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('ActivitiesFee2', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('ActivitiesCapital', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('ActivitiesInterest', ModuleNamesList.PrintCase)}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.activities.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{DateTimeParser.toLocaleDateString(item.date)}</td>
                  <td>{item.activityDescription}</td>
                  <td>{CurrencyParser.toLocaleString(item.paid)}</td>
                  <td>{CurrencyParser.toLocaleString(item.legalFee)}</td>
                  <td>{CurrencyParser.toLocaleString(item.fee1)}</td>
                  <td>{CurrencyParser.toLocaleString(item.fee2)}</td>
                  <td>{CurrencyParser.toLocaleString(item.capital)}</td>
                  <td>{CurrencyParser.toLocaleString(item.interest)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default ActivitiesDetails;
