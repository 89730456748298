import React, { useState } from 'react';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import SettingsTypeCellEditor from '../helpers/SettingsTypeCellEditor';
import InfoMessageService from 'core/services/InfoMessageService';
import TranslationsManagementService from '../services/TranslationsManagementService';
import { useMutation } from 'react-query';

type PropsType = {
  moduleInstanceId: number;
  custom: boolean;
  value: string;
  row: any;
};

type SaveTranslationMutateType = {
  rowId: string;
  newValue: string;
  moduleInstanceId: number;
};

const EditCell = (props: PropsType) => {
  const [value, setValue] = useState<string>(props.value || '');
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const saveTranslation = useMutation(
    (values: SaveTranslationMutateType) => {
      return TranslationsManagementService.saveTranslations({
        moduleInstanceId: values.moduleInstanceId,
        translations: {
          [values.rowId]: values.newValue,
        },
      });
    },
    {
      onSuccess: (data, variables) => {
        setValue(variables.newValue);
        setIsEdit(false);
        InfoMessageService.displayActionStatus(data);
      },
      onError: () => {
        InfoMessageService.error(TranslationService.translate('ErrorSthWentWrong'));
      },
    }
  );

  const handleOnEditClick = () => {
    setIsEdit(true);
  };

  const handleOnUpdate = async (rowId: string, newValue: string) => {
    saveTranslation.mutate({
      rowId,
      newValue,
      moduleInstanceId: props.moduleInstanceId,
    });
  };

  const handleOnClose = () => {
    setIsEdit(false);
  };

  return (
    <>
      {isEdit ? (
        <SettingsTypeCellEditor
          value={value}
          type={props.row.type}
          row={props.row}
          isLoading={saveTranslation.isLoading}
          onUpdate={handleOnUpdate}
          onClose={handleOnClose}
        />
      ) : props.custom || value.length ? (
        <>
          <p dangerouslySetInnerHTML={{ __html: value }} />

          <Button
            type="button"
            label={TranslationService.translateModule('Edit', ModuleNamesList.TranslationsManagement)}
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTON_SIZE.MD}
            icon={{ className: 'fas fa-edit', position: 'left' }}
            onClick={handleOnEditClick}
          />
        </>
      ) : (
        <Button
          type="button"
          label={TranslationService.translateModule('AddNewCustom', ModuleNamesList.TranslationsManagement)}
          variant={BUTTON_VARIANTS.PRIMARY}
          size={BUTTON_SIZE.MD}
          icon={{ className: 'fas fa-plus', position: 'left' }}
          onClick={handleOnEditClick}
        />
      )}
    </>
  );
};

export default EditCell;
