import { PortalUsersFilterOption } from './PortalUsersFilterOption';

export interface IPortalUsersFilterRequest {
  filterByOption?: PortalUsersFilterOption;
  filteredByValue: string;
}

export const emptyPortalUsersFilterRequest: IPortalUsersFilterRequest = {
  filteredByValue: '',
};
