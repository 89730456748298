import * as React from 'react';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import Dropdown from 'react-bootstrap/Dropdown';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { IGroupUser } from '../types/IGroupUser';

class GroupListTableConfigurator {
  public getTableColumns = (
    items: IComponentTable<IGroupUser>,
    openCreditorsAssignmentModal: (userUId: string, fullName: string) => void,
    openManageUserModal: (userUId: string) => void,
    openRemoveGroupUserModal: (item: IGroupUser) => void,
    openRolesAssignmentModal: (userUId: string, fullName: string) => void,
    toggleDropdown: () => void
  ) => {
    let columns: IComponentTableColumn[] = [];

    if (items != null) {
      columns = items.columns;
      this.configureDateColumns(columns);
      this.configureActions(
        columns,
        openCreditorsAssignmentModal,
        openManageUserModal,
        openRemoveGroupUserModal,
        openRolesAssignmentModal,
        toggleDropdown
      );

      return columns;
    }

    return columns;
  };

  private configureDateColumns = (columns: IComponentTableColumn[]) => {
    const dateColumns = ['createDate'];
    const cols = columns.filter((col) => dateColumns.indexOf(col.dataField) >= 0);
    const formatterFunc = (value: any, row: any) => {
      if (value !== null && value !== '') {
        return DateTimeParser.toLocaleDateTimeString(value);
      }
      return '';
    };

    for (const col of cols) {
      col.formatter = formatterFunc;
    }
  };

  private configureActions = (
    columns: IComponentTableColumn[],
    openCreditorsAssignmentModal: (userUId: string, fullName: string) => void,
    openManageUserModal: (userUId: string) => void,
    openRemoveGroupUserModal: (item: IGroupUser) => void,
    openRolesAssignmentModal: (userUId: string, fullName: string) => void,
    toggleDropdown: () => void
  ) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'actions');
    if (column) {
      column.headerStyle = () => {
        return { width: '90px' };
      };
      column.formatter = (value: any, item: IGroupUser) =>
        this.actionsRowData(
          value,
          item,
          openCreditorsAssignmentModal,
          openManageUserModal,
          openRemoveGroupUserModal,
          openRolesAssignmentModal
        );
    }
  };

  private actionsRowData = (
    value: any,
    item: IGroupUser,
    openCreditorsAssignmentModal: (userUId: string, fullName: string) => void,
    openManageUserModal: (userUId: string) => void,
    openRemoveGroupUserModal: (item: IGroupUser) => void,
    openRolesAssignmentModal: (userUId: string, fullName: string) => void
  ) => {
    return (
      <Dropdown alignRight className="button-table">
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {TranslationService.translateModule('Actions', ModuleNamesList.GroupUsersList)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              openManageUserModal(item.uId);
            }}
          >
            <i className="fas fa-edit" />{' '}
            {TranslationService.translateModule('ActionManageGroup', ModuleNamesList.GroupUsersList)}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              openCreditorsAssignmentModal(item.uId, item.userName);
            }}
          >
            <i className="fas fa-user-tie" />{' '}
            {TranslationService.translateModule('ActionCreditorsAssignment', ModuleNamesList.GroupUsersList)}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              openRolesAssignmentModal(item.uId, item.userName);
            }}
          >
            <i className="fas fa-address-card" />{' '}
            {TranslationService.translateModule('ActionRolesAssignment', ModuleNamesList.GroupUsersList)}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              openRemoveGroupUserModal(item);
            }}
          >
            <i className="fas fa-trash" />{' '}
            {TranslationService.translateModule('ActionRemoveGroup', ModuleNamesList.GroupUsersList)}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
}

export default new GroupListTableConfigurator();
