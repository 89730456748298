import React, { useState, useEffect } from 'react';
import InfoMessageService from '../../services/InfoMessageService';
import TranslationService from '../../services/TranslationService';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../Button/Button';
import { ModuleNamesList } from '../../lists/ModuleNamesList';

type FileStringInputPropsType = {
  id: string;
  name: string;
  value: string;
  onChange: (value: string, e: any) => void;
};

const FileStringInput = (props: FileStringInputPropsType) => {
  const [value, setValue] = useState<string>('');

  let fileInput: any = null;

  const renderIcon = () => {
    const buff = new Buffer(value);
    const base64data = buff.toString('base64');

    return <img src={`data:image/svg+xml;base64,${base64data}`} alt="" />;
  };

  useEffect(() => {
    if (!props.value) {
      return;
    }
    const encoded = props.value.substring(26);
    const decoded = atob(encoded);
    const dom = new DOMParser();
    const svg: any = dom.parseFromString(decoded, 'image/svg+xml');

    svg.rootElement.style.fill = '#fff';

    if (svg.rootElement.getElementsByTagName('path').length) {
      const collections = svg.rootElement.getElementsByTagName('path');

      for (let i = 0; i < collections.length; i++) {
        collections[i].setAttribute('fill', '#414042');
      }
    }

    if (svg.rootElement.getElementsByTagName('defs').length) {
      svg.rootElement.getElementsByTagName('defs')[0].remove();
    }

    setValue(svg.rootElement.outerHTML);
    // eslint-disable-next-line
  }, [props.value]);

  return (
    <>
      <label className="c-file-input">
        <input
          ref={(input) => {
            fileInput = input;
          }}
          id={props.id}
          type="file"
          name={props.name || ''}
          accept=".svg"
          onChange={({ target: { value, files } }) => {
            if (files && files.length) {
              const file = files[0];
              const reader = new FileReader();

              if (file.type === 'image/svg+xml') {
                reader.readAsText(file);
                reader.onload = function (evt: any) {
                  const buff = new Buffer(evt.target.result);
                  const base64data = buff.toString('base64');

                  setValue(evt.target.result);

                  props.onChange(`data:image/svg+xml;base64,${base64data}`, evt);
                };
              } else {
                value = '';

                InfoMessageService.warning(TranslationService.translate('ErrorIncorrectSVGFormat'));
              }
            }
          }}
        />

        {renderIcon()}

        <Button
          variant={BUTTON_VARIANTS.SECONDARY}
          size={BUTTON_SIZE.SM}
          type="button"
          label={TranslationService.translateModule('Select icon', ModuleNamesList.PagesList)}
          onClick={() => {
            fileInput.click();
          }}
        />
      </label>
    </>
  );
};

export default FileStringInput;
