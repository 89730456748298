import React from 'react';
import UserPlus from './components/UserPlus';
import Contacts from './components/Contacts';
import { SvgIconsNames } from './enums/SvgIconsNames';
import { SvgIconProps } from './types/SvgIconProps';

const icons = {
  UserPlus,
  Contacts,
};

const SvgIcon = (props: { icon: SvgIconsNames } & SvgIconProps) => {
  const IconComponent = icons[props.icon];
  return (
    <div className={`svg-icon-wrapper ${props.isActive ? 'active' : ''} ${props.visited ? 'visited' : ''}`}>
      <IconComponent />
    </div>
  );
};

export default SvgIcon;
