import RestApiClient from '../../../core/api/RestApiClient';
import { IWithdrawCaseRequest } from '../types/IWithdrawCaseRequest';

class WithdrawCaseApi {
  public async withdrawCase(request: IWithdrawCaseRequest) {
    return await RestApiClient.post<void>(`modules/WithdrawCaseModule/withdraw`, request);
  }
}

export default new WithdrawCaseApi();
