import RestApiClient from '../../../core/api/RestApiClient';
import { IGetGroupUsersResponse } from '../types/IGetGroupUsersResponse';
import { IGroupUser } from '../types/IGroupUser';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

class GroupUsersAssignmentApiClient {
  public async assignGroupUsers(userUId: string, selectedUsers: IGroupUser[]): Promise<IActionStatus> {
    const request = { userUId, selectedUsers };
    return await RestApiClient.post<IActionStatus>(`modules/GroupUsersAssignment/assignGroupUsers`, request);
  }

  public async getGroupUsers(userUId: string, moduleInstanceId: number): Promise<IGetGroupUsersResponse> {
    return await RestApiClient.get<IGetGroupUsersResponse>(
      `modules/GroupUsersAssignment/getGroupUsers/${moduleInstanceId}/${userUId}`
    );
  }
}

export default new GroupUsersAssignmentApiClient();
