import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { IAgreementProps } from '../types/IAgreementProps';
import TranslationService from '../../../core/services/TranslationService';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { Formik, Form } from 'formik';
import FormGroupCheckbox from '../../../core/components/Forms/FormGroupCheckbox';
import CreditorRegistrationApiClient from '../CreditorRegistrationApiClient';
import Loader from '../../../core/components/Loading/Loader';
import useEffectAsync from '../../../core/hooks/useEffectAsync';
import useLocalStorageState from '../../../core/hooks/useLocalStorageState';

const Agreement = (props: IAgreementProps) => {
  const [isPendingRequest, setIsPendingRequest] = useState<boolean>(true);
  const [agreementSrc, setAgreementSrc] = useState<string>('');

  const [initialValues, handleUpdateForm] = useLocalStorageState({
    key: 'AgreementForm',
    value: props.companyForm,
  });

  const validationSchema = yup.object().shape({
    isAgreementConfirmed: yup
      .boolean()
      .oneOf([true], TranslationService.translate('ValidationMessageCheckboxRequired')),
  });

  useEffectAsync(async () => {
    setIsPendingRequest(true);

    const agreementSrc = await CreditorRegistrationApiClient.GenerateAgreement(props.companyForm);
    if (agreementSrc === null || agreementSrc === undefined) {
    }

    setIsPendingRequest(false);
    setAgreementSrc(agreementSrc);
  }, []);

  const handleOnValidateForm = (values: any) => {
    handleUpdateForm(values);
  };

  useEffect(() => {
    if (sessionStorage.getItem('AgreementFormFilled') === null) {
      handleUpdateForm(props.companyForm, true);
    }
  }, [props.companyForm]);

  return (
    <div className="step-content">
      {isPendingRequest ? <Loader opacity={1} /> : ''}
      <header className="step-header">
        <h2 className="step-title">
          {TranslationService.translateModule('AgreementHeader', ModuleNamesList.CreditorRegistration)}
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: TranslationService.translateModule('AgreementDescription', ModuleNamesList.CreditorRegistration),
          }}
        />
      </header>
      <div className="row mb-3">
        <div className="col-12 col-sm-6">
          <dl className="row">
            <dt className="col-6">
              {TranslationService.translateModule('AgreementOrganizationNumber', ModuleNamesList.CreditorRegistration)}
            </dt>
            <dd className="col-6">{props.companyForm.organizationNumber}</dd>
            <dt className="col-6">
              {TranslationService.translateModule('AgreementOrganizationName', ModuleNamesList.CreditorRegistration)}
            </dt>
            <dd className="col-6">{props.companyForm.organizationName}</dd>
            <dt className="col-6">
              {TranslationService.translateModule('AgreementOrganizationAddress', ModuleNamesList.CreditorRegistration)}
            </dt>
            <dd className="col-6">
              {props.companyForm.address.street} {props.companyForm.address.address2}
              <br />
              {props.companyForm.address.postalCode} {props.companyForm.address.city}
            </dd>
          </dl>
        </div>
        <div className="col-12 col-sm-6">
          <dl className="row">
            <dt className="col-6">
              {TranslationService.translateModule('AgreementCPFullName', ModuleNamesList.CreditorRegistration)}
            </dt>
            <dd className="col-6">
              {props.companyForm.contactDetails.firstName + ' ' + props.companyForm.contactDetails.lastName}
            </dd>
            <dt className="col-6">
              {TranslationService.translateModule('AgreementCPPhoneNo', ModuleNamesList.CreditorRegistration)}
            </dt>
            <dd className="col-6">{props.companyForm.contactDetails.phoneNumber}</dd>
            <dt className="col-6">
              {TranslationService.translateModule('AgreementCPPEmail', ModuleNamesList.CreditorRegistration)}
            </dt>
            <dd className="col-6">{props.companyForm.contactDetails.email}</dd>
            <dt className="col-6">
              {TranslationService.translateModule('AgreementAccountNo', ModuleNamesList.CreditorRegistration)}
            </dt>
            <dd className="col-6">{props.companyForm.accountancyInformation.accountNumber}</dd>
          </dl>
        </div>
      </div>
      <header className="step-header">
        <h2 className="step-title">
          {TranslationService.translateModule('AgreementSecondHeader', ModuleNamesList.CreditorRegistration)}
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: TranslationService.translateModule(
              'AgreementSecondDescription',
              ModuleNamesList.CreditorRegistration
            ),
          }}
        />
      </header>
      {agreementSrc !== '' ? (
        <object className="pdf-container concent-scrollbar" type="application/pdf" data={agreementSrc}>
          <p>
            {TranslationService.translateModule(
              'NotDisplayAgreementErrorMessage',
              ModuleNamesList.CreditorRegistration
            )}
          </p>
        </object>
      ) : (
        ''
      )}
      <div className="form-check--wrapper">
        <Formik
          initialValues={{
            ...initialValues,
            isAgreementConfirmed: false,
          }}
          onSubmit={props.goToNextStep}
          validationSchema={validationSchema}
          validate={handleOnValidateForm}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => {
            return (
              <Form className="confirmation-form">
                <div className="form-check">
                  <FormGroupCheckbox
                    label={TranslationService.translateModule(
                      'AgreementConfirmationCheckBoxLabel',
                      ModuleNamesList.CreditorRegistration
                    )}
                    type="checkbox"
                    fieldName="isAgreementConfirmed"
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    required={true}
                    value={values.isAgreementConfirmed}
                  />
                </div>
                <hr />
                <StepListWizardButtons
                  leftButtonOnClick={props.backToPrevStep}
                  leftButtonClassName="float-left"
                  leftButtonText={
                    <>
                      <i className="fas fa-angle-double-left back-button-arrow left" />{' '}
                      {TranslationService.translate('Back')}
                    </>
                  }
                  rightButtonClassName="float-right"
                  rightButtonText={
                    <>
                      {TranslationService.translate('Next')}{' '}
                      <i className="fas fa-angle-double-right next-button-arrow right" />
                    </>
                  }
                  rightButtonOnClick={() => {
                    sessionStorage.setItem('AgreementFormFilled', 'true');
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Agreement;
