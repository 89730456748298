import { INumberDictionary } from '../../../core/types/IDictionary';

export interface ICustomerTransactionsInitialSettings {
  filterByOptions: INumberDictionary<string>;
  enableFiltersSection: boolean;
  exportEnabled: boolean;
  enableSummarySection: boolean;
}

export const emptyCustomersInitialSettings: ICustomerTransactionsInitialSettings = {
  filterByOptions: {},
  enableFiltersSection: true,
  exportEnabled: true,
  enableSummarySection: false,
};
