import { IInvoicesAgeAnalysisListItem } from './IInvoicesAgeAnalysisListItem';
import { IComponentTable, emptyComponentTable } from '../../../core/types/IComponentTable';

export interface IInvoicesAgeAnalysisSummary {
  data: IComponentTable<IInvoicesAgeAnalysisListItem>;
  total: number;
  totalBalance: number;
  totalNinetyPlus: number;
  totalSixtyTo90: number;
  totalThirtyTo60: number;
  totalZeroTo30: number;
}

export const emptyInvoicesAgeAnalysisSummary: IInvoicesAgeAnalysisSummary = {
  data: { ...emptyComponentTable },
  total: 0,
  totalBalance: 0,
  totalNinetyPlus: 0,
  totalSixtyTo90: 0,
  totalThirtyTo60: 0,
  totalZeroTo30: 0,
};
