import RestApiClient from '../../../core/api/RestApiClient';
import { IComponentTable } from 'core/types/IComponentTable';
import { IGuarantor } from '../types/IGuarantor';

class GuarantorsApiClient {
  public async getGuarantorsTable(moduleInstanceId: number, caseNo: string): Promise<IComponentTable<IGuarantor>> {
    return await RestApiClient.get<IComponentTable<IGuarantor>>(
      `modules/Guarantors/getGuarantorsTable/${moduleInstanceId}/${caseNo}`
    );
  }
}

export default new GuarantorsApiClient();
