import RestApiClient from '../../../core/api/RestApiClient';
import { IInvoiceDetails } from '../types/IInvoiceDetails';
import { IInvoiceDetailsRequest } from '../types/IInvoiceDetailsRequest';

class InvoiceDetailsApi {
  public async getInvoiceDetails(request: IInvoiceDetailsRequest) {
    return await RestApiClient.post<IInvoiceDetails>('modules/InvoiceDetailsModule', request);
  }
}

export default new InvoiceDetailsApi();
