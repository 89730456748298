import { IModule } from '../../../core/api/types/IModule';
import ModalApi from '../api/ModalApi';

class ModalService {
  public async getModalContent(moduleInstanceId: number): Promise<IModule> {
    return await ModalApi.getModalContent(moduleInstanceId);
  }
}

export default new ModalService();
