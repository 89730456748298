import React, { useState, useEffect } from 'react';
import SelectOption from './SelectOption';
import styles from './Select.module.scss';
import classNames from 'classnames';

type OptionsType = {
  name: string;
  value: string;
};

type SelectOptionsPropsType = {
  children?: React.ReactChild;
  name: string;
  id: string;
  disabled?: boolean;
  options: OptionsType[] | any;
  onChange?: (option: any) => void;
};

const SelectOptions = (props: SelectOptionsPropsType) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleSelectClick = () => {
    if (!props.disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (option: any) => {
    if (props.onChange) {
      setIsOpen(!isOpen);
      setSelectedValue(option.name);

      props.onChange(option);
    }
  };

  useEffect(() => {
    if (props.options.length) {
      setSelectedValue(props.options[0].name);
    }
  }, [props.options]);

  return (
    <>
      <select name={props.name} id={props.id}>
        {props.options.map((option: any) => {
          return <SelectOption key={option.id} value={option.id} name={option.name} />;
        })}
      </select>

      <p
        className={classNames(
          styles.selectedValue,
          props.disabled && styles.selectedValueDisabled,
          isOpen && styles.selectedValueIsOpen
        )}
        onClick={handleSelectClick}
      >
        {selectedValue}
      </p>

      <ul className={classNames(styles.selectList, isOpen && styles.selectListIsOpen)}>
        {props.options.map((option: any) => {
          return (
            <li
              key={option.id}
              className={classNames(
                styles.selectListItem,
                option.name === selectedValue && styles.selectListItemSelected
              )}
              onClick={() => handleOptionClick(option)}
            >
              {option.name}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default SelectOptions;
