import React, { useEffect, useState } from 'react';
// @ts-ignore
import classNames from 'classnames';
import styles from './InputField.module.scss';

type PropsType = {
  id?: string;
  name: string;
  className?: string;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
};

const InputField = (props: PropsType) => {
  const fieldId = props.name.toLowerCase().replace(/\s/g, '');

  const [fieldProps, setFieldProps] = useState<PropsType>({
    name: props.name,
    id: props.id || fieldId,
    className: classNames(styles.wrapper, props.className),
    disabled: props.disabled || false,
    onChange: props.onChange,
    onBlur: props.onBlur,
  });

  useEffect(() => {
    if (props.value) {
      setFieldProps((prev) => ({
        ...prev,
        value: props.value,
      }));
    } else if (props.defaultValue) {
      setFieldProps((prev) => ({
        ...prev,
        defaultValue: props.defaultValue,
      }));
    }
    // eslint-disable-next-line
  }, []);

  return <input type="text" {...fieldProps} />;
};

export default React.memo(InputField);
