import React, { useEffect, useState } from 'react';
import { IDictionary } from '../../../core/types/IDictionary';
import { TimelineOptionsType } from '../types/TimelineOptionsType';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { DataSet, Timeline as TL } from 'vis-timeline/standalone';
import { connect } from 'react-redux';
import { IStore } from '../../../reducers/IStore';
import moment from 'moment';

type TimelineType = {
  locale: string;
  data: TimelineOptionsType;
  actions: IDictionary<number> | null;
  onUpdate: (data: any) => void;
  onChangeVScroll: (data: any) => void;
};

const Timeline = (props: TimelineType) => {
  const [timeline, setTimeline] = useState<any>();

  const prepareTimeline = (data: TimelineOptionsType) => {
    const container = document.getElementById('timeline');
    const options = {
      locales: {
        nb: {
          current: 'nåværende',
          time: 'tid',
        },
        en: {
          current: 'current',
          time: 'time',
        },
      },
      locale: props.locale.split('-')[0],
      min: moment(data.minDate).toISOString(true),
      max: moment(data.maxDate).toISOString(true),
      start: moment(data.minDate).toISOString(true),
      margin: {
        item: 20,
      },
      height: 240,
      zoomMin: 1000 * 60 * 60 * 24 * 7,
    };

    const items = new DataSet(data.data);

    if (container) {
      const timeline: any = new TL(container, items, options);

      timeline.on('rangechange', (data: any) => {
        rangeChangeTimeline(data);
      });

      timeline.on('changed', () => {
        props.onChangeVScroll(timeline.props);
      });

      setTimeline(timeline);
    }
  };

  const rangeChangeTimeline = (data: any) => {
    const start = DateTimeParser.toDateFormat(data.start, 'L HH:mm:ss');
    const end = DateTimeParser.toDateFormat(data.end, 'L HH:mm:ss');
    const dataRangeSlider: any = {};

    if (moment(data.minDate).isBefore(start) || moment(end).isBefore(data.maxDate)) {
      dataRangeSlider.className = 'range-timeline';
    } else {
      dataRangeSlider.className = 'range-timeline range-timeline--hide-vertical-scroll';
    }

    dataRangeSlider.start = start;
    dataRangeSlider.end = end;

    props.onUpdate(dataRangeSlider);
  };

  const timelineMove = (percentage: number) => {
    const range = timeline.getWindow();
    const interval = range.end - range.start;

    timeline.setWindow({
      start: range.start.valueOf() - interval * percentage,
      end: range.end.valueOf() - interval * percentage,
    });
  };

  const makeAction = (actions: any) => {
    if (actions.zoomOut) {
      timeline.zoomOut(0.2);
    } else if (actions.zoomIn) {
      timeline.zoomIn(0.2);
    } else if (actions.timelineMove) {
      timelineMove(actions.timelineMove);
    }
  };

  useEffect(() => {
    prepareTimeline(props.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.actions) {
      return;
    }

    makeAction(props.actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.actions]);

  return <div id="timeline" />;
};

const mapStateToProps = (state: IStore) => {
  const splittedLocale = state.currentCultureCode.split('-');
  return {
    locale: `${splittedLocale[0]}-${splittedLocale[1].toUpperCase()}`,
  };
};

export default connect(mapStateToProps)(Timeline);
