import * as React from 'react';
import { IAlertMessagesProps } from './types/IAlertMessagesProps';
import TranslationService from '../../../core/services/TranslationService';

class AlertMessages extends React.Component<IAlertMessagesProps> {
  getDate = (date: Date) => {
    return new Intl.DateTimeFormat('sv-SE', {
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
  };

  getStyle = () => {
    return { textAlign: 'right' };
  };

  public render() {
    return (
      <>
        {this.props.messages.map((message: string, index: number) => {
          return (
            <div className={'alert ' + this.props.alertClass} key={index}>
              {this.props.translate ? (
                <>
                  {this.props.moduleName
                    ? TranslationService.translateModule(message, this.props.moduleName)
                    : TranslationService.translate(message)}
                </>
              ) : (
                message
              )}
            </div>
          );
        })}
        {this.props.messages.length !== 0 && <div style={{ textAlign: 'end' }}>{`[${this.getDate(new Date())}]`}</div>}
      </>
    );
  }
}

export default AlertMessages;
