import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import RestApiClient from '../../../core/api/RestApiClient';
import { BootstrapTableContainer } from '../../../core/components/BootstrapTable/BootstrapTableContainer';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import Loader from '../../../core/components/Loading/Loader';
import NoPermissionsComponent from '../../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import RouteParamsService from '../../../core/services/RouteParamsService';
import TranslationService from '../../../core/services/TranslationService';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IExtendedModuleProps } from '../../../core/types/IExtendedModuleProps';
import Footer from './components/Footer';
import TableConfigurator from './helpers/TableConfigurator';
import { GetUnitCasesResponse } from './types/GetUnitCasesResponse';
import { UnitCase } from './types/UnitCase';
import ErrorResponseHandler from '../../../core/services/ErrorResponseHandler';

const getUnitIdCasesUrl = 'modules/UnitCasesModule/{moduleInstanceId}/{creditorNo}/{accountNo}/{unitId}';

const UnitCases = (props: IExtendedModuleProps & RouteComponentProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);
  const [table, setTable] = useState<IComponentTable<UnitCase>>({
    columns: [],
    values: [],
  });

  const [summaryCounters, setSummaryCounters] = useState({
    totalCases: 0,
    totalBalance: 0,
    paidBalance: 0,
    remainBalance: 0,
  });

  const loadData = async () => {
    const queryParams = RouteParamsService.getQueryParameters();
    const properUrl = getUnitIdCasesUrl
      .replace('{moduleInstanceId}', props.module.id.toString())
      .replace('{creditorNo}', queryParams.creditorNo as string)
      .replace('{accountNo}', queryParams.accountNo as string)
      .replace('{unitId}', (queryParams.unitId !== undefined ? queryParams.unitId : '') as string);

    try {
      const data = await RestApiClient.get<GetUnitCasesResponse>(properUrl);

      setTable(data.table);

      setSummaryCounters({
        paidBalance: data.paidBalance,
        remainBalance: data.remainBalance,
        totalBalance: data.totalBalance,
        totalCases: data.totalCases,
      });
    } catch (error: any) {
      const hasAccess = ErrorResponseHandler.hasAccess(error);
      setHasAccess(hasAccess);
    }

    setIsLoading(false);
  };

  // eslint-disable-next-line
  useEffect(() => {
    loadData();
  }, [props]);

  return !hasAccess ? (
    <NoPermissionsComponent />
  ) : (
    <article className="l-module">
      <Loader isLoaded={!isLoading} opacity={0.5} />
      <section className="l-module__section l-module__section--head">
        <h1 className="l-module__title">
          <i className="fas fa-file-invoice" />
          {TranslationService.translateModule('UnitIdDetails', ModuleNamesList.UnitCases)}:
          <strong className="l-module__title-highlighted">
            {TranslationService.translateModule('Cases', ModuleNamesList.UnitCases)}
          </strong>
        </h1>
      </section>

      <section className="l-module__section mt-3">
        <div className="unit-invoices__action-btns">
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTON_SIZE.MD}
            icon={{
              position: 'left',
              className: 'fas fa-arrow-left',
            }}
            label={TranslationService.translate('Back')}
            onClick={() => {
              props.history.goBack();
            }}
          />
        </div>
        <BootstrapTableContainer
          remote={false}
          data={table.values}
          classes="bt__table bt-table"
          keyField="caseNo"
          wrapperClasses="bt unit-cases-table"
          columns={TableConfigurator.getTableColumns(table)}
        />
      </section>
      <section className="l-module__section l-module__section--p-0 mt-3">
        <Footer
          totalBalance={summaryCounters.totalBalance}
          totalCases={summaryCounters.totalCases}
          paidBalance={summaryCounters.paidBalance}
          remainBalance={summaryCounters.remainBalance}
        />
      </section>
    </article>
  );
};

export default withRouter(UnitCases);
