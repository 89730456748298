import { Dispatch } from 'redux';
import * as ACTIONS from '../actions';
import UserCreditorRolesService from '../core/services/UserCreditorRolesService';
const fetchUserCreditorRolesInProgress = () => ({
  type: ACTIONS.FETCH_USER_CREDITOR_ROLES_IN_PROGRESS,
});

const fetchUserCreditorRolesSucceeded = (data: any) => ({
  type: ACTIONS.FETCH_USER_CREDITOR_ROLES_SUCCEEDED,
  userCreditorRoles: data,
});

const fetchUserCreditorRolesFailed = () => ({
  type: ACTIONS.FETCH_USER_CREDITOR_ROLES_FAILED,
});

export default () => (dispatch: Dispatch<any>) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(fetchUserCreditorRolesInProgress());
    return UserCreditorRolesService.getUserCreditorRoles()
      .then((data) => {
        dispatch(fetchUserCreditorRolesSucceeded(data));
        resolve();
      })
      .catch((_) => {
        dispatch(fetchUserCreditorRolesFailed());
        reject();
      });
  });
};
