import React from 'react';

type SwitchInputPropsType = {
  name: string;
  id?: string;
  value: string;
  handleChange: (value: string) => void;
};

const SwitchInput = (props: SwitchInputPropsType) => {
  return (
    <>
      <label className="c-switch-input">
        <input
          type="checkbox"
          id={props.id}
          name={props.name}
          defaultChecked={props.value.toString() === 'true' ? true : false}
          onChange={(e) => {
            props.handleChange(e.target.checked.toString());
          }}
        />
        <div className="c-switch-input__switch"></div>
      </label>
    </>
  );
};

export default SwitchInput;
