import * as React from 'react';
import { ISettingInputComponent } from '../types/ISettingInputComponent';

class StringComponentInput extends React.Component<ISettingInputComponent> {
  public render() {
    return (
      <input
        type="text"
        className="form-control"
        value={this.props.value}
        name={this.props.name}
        onChange={(event) => this.props.onChange(this.props.name, event.target.value)}
      />
    );
  }
}

export default StringComponentInput;
