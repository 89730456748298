import React from 'react';
import styles from '../SelectField.module.scss';
// @ts-ignore
import classNames from 'classnames';
import { useSelectFieldContext } from '../context/SelectFieldContext';

type PropsType = {
  children: React.ReactNode;
  className?: string;
};

const SelectFieldSelected = (props: PropsType) => {
  const { onToggle } = useSelectFieldContext();

  const handleOnToggle = (e: any) => {
    if (onToggle) {
      onToggle(e);
    }
  };

  return (
    <span className={classNames(styles.selected, props.className)} onClick={(e) => handleOnToggle(e)}>
      {props.children}
    </span>
  );
};

export default SelectFieldSelected;
