export interface IDBConnectionDetails {
  hostName: string;
  databaseName: string;
  username: string;
  password: string;
}

export const emptyConnectionDetails: IDBConnectionDetails = {
  hostName: '',
  databaseName: '',
  username: '',
  password: '',
};
