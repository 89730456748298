export type ExternalLoginProviderType = {
  authority: string;
  canLoginGroup: string;
  clientId: string;
  clientSecret: string;
  disabled: boolean;
  showOnLoginPage: boolean;
  domain: string;
  loginProviderTypeId?: number;
  loginProvider: string;
  loginProviderType: string;
  providerDisplayName: string;
  tenantId: string;
  authHeaderPassword: string | null;
  usePkce: boolean;
};

export const emptyExternalLoginProviderData: ExternalLoginProviderType = {
  authority: '',
  canLoginGroup: '',
  clientId: '',
  clientSecret: '',
  disabled: false,
  showOnLoginPage: false,
  domain: '',
  loginProvider: '',
  loginProviderType: '',
  providerDisplayName: '',
  tenantId: '',
  authHeaderPassword: null,
  usePkce: false,
};
