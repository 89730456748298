import React from 'react';

type SelectOptionPropsType = {
  name: string;
  value: string;
};

const SelectOption = (props: SelectOptionPropsType) => {
  return <option value={props.value}>{props.name}</option>;
};

export default SelectOption;
