import * as ACTIONS from '../../actions';
import { store } from '../../index';
import { IDictionary } from '../types/IDictionary';
import { IStore } from '../../reducers/IStore';
import RestApiClient from '../api/RestApiClient';

const IdentityServerUrl = 'IdentityServerUrl';
const WebsiteUrl = 'WebsiteUrl';

class ConfigurationService {
  public async getIdentityServerUrl(): Promise<string> {
    const config = await this.getConfiguration();
    if (config[IdentityServerUrl]) {
      return config[IdentityServerUrl];
    }
    return '';
  }

  public async getWebsiteUrl(): Promise<string> {
    const config = await this.getConfiguration();
    if (config[WebsiteUrl]) {
      return config[WebsiteUrl];
    }
    return '';
  }

  public async getConfiguration(): Promise<IDictionary<string>> {
    if (store !== undefined) {
      const currentState: IStore = store.getState();
      if (!Object.keys(currentState.configurations).length) {
        const configuration = await RestApiClient.get<IDictionary<string>>('Configuration');
        store.dispatch({ type: ACTIONS.UPDATE_CONFIGURATION, configurations: configuration });
        return configuration;
      }
      return currentState.configurations;
    }
    return {};
  }
}

export default ConfigurationService;
