import RestApiClient from '../../../core/api/RestApiClient';
import { IActionStatus } from '../../../core/api/types/IActionStatus';
import { IUser } from '../../RegisterUser/types/IUser';

export default {
  validateIsEmailExists: async (email: string): Promise<boolean> => {
    return await RestApiClient.get<boolean>(`modules/DcaRegisterUserModule/validateIsEmailExists/${email}`);
  },
  createUser: async (user: IUser, moduleInstanceId: number): Promise<IActionStatus> => {
    var request = { ...user, moduleInstanceId };
    return await RestApiClient.post<IActionStatus>('modules/DcaRegisterUserModule/createUser', request);
  },
};
