import * as React from 'react';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IBaseCreditorDetails } from '../types/IBaseCreditorDetails';

class ExistingCreditorsTableConfigurator {
  public getTableColumns = (items: IComponentTable<IBaseCreditorDetails>) => {
    let columns: IComponentTableColumn[] = [];
    if (items != null) {
      columns = [...items.columns];
      this.configureSquareCheckbox(columns);

      return columns;
    }

    return columns;
  };

  private configureSquareCheckbox = (columns: IComponentTableColumn[]) => {
    columns.push({
      dataField: 'selected',
      formatter: (value: any, item: IBaseCreditorDetails) => this.actionsRowData(),
      sort: false,
      text: '',
    } as IComponentTableColumn);
  };

  private actionsRowData = () => {
    return <i className="far fa-square" />;
  };
}

export default new ExistingCreditorsTableConfigurator();
