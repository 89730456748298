import { EventsList } from '../../core/lists/EventsList';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { getModalOpenEvent } from '../../core/helpers/Modal/ModalEvents';
import { IBaseCreditorDetails } from '../CreditorsAssignment/types/IBaseCreditorDetails';
import { ICreditorRolesDto } from './types/ICreditorRolesDto';
import { IDictionary } from '../../core/types/IDictionary';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IUserModuleVm } from './types/IUsersModuleVm';
import { IUsersModuleCreditorVm } from './types/IUsersModuleCreditorVm';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import * as React from 'react';
import InfoMessageService from '../../core/services/InfoMessageService';
import InviteUser from './components/InviteUser';
import Loader from '../../core/components/Loading/Loader';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
// import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import TranslationService from '../../core/services/TranslationService';
import UsersApiClient from './api/UsersApiClient';
import UsersTable from './components/UserTable';

export interface IState {
  creditor?: IUsersModuleCreditorVm;
  creditors?: IUsersModuleCreditorVm[];
  isDataLoading: boolean;
  roles: IDictionary<ICreditorRolesDto[]>;
}

class Users extends React.Component<IModuleProps, IState> {
  module = flatModuleOptions<any>(this.props.module);

  state = {
    creditors: [],
    expandInvitation: false,
    isDataLoading: true,
    roles: {},
  };

  private moduleEvents: IModuleEventSubscription[];

  public componentDidMount() {
    this.registerModuleEvents();
    this.fetchData();
  }

  public componentWillUnmount() {
    ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
  }

  public render() {
    const moduleId = this.props.module.id;
    const { isDataLoading, creditors } = this.state;

    return (
      <article className="l-module">
        <header className="l-module__header">
          <h5 className="l-module__title">
            <i className="fas fa-cogs mr-2" />
            {TranslationService.translateModule('LeftMenuLocalAdmin', ModuleNamesList.Users)}:
            <strong className="l-module__title-highlighted">
              {TranslationService.translateModule('UsersHeader', ModuleNamesList.Users)}
            </strong>
          </h5>
        </header>
        <section className="l-module__section l-module__section--filter">
          {isDataLoading && <Loader opacity={0.5} />}
          <InviteUser creditors={this.state.creditors} moduleId={moduleId} onSubmit={this.fetchData} />
        </section>
        <section className="l-module__section mt-3">
          <UsersTable creditors={creditors} moduleId={moduleId} onOpenModal={this.openModal} refresh={this.fetchData} />
        </section>
      </article>
    );
  }

  openModal = (userUId: string, creditors: IBaseCreditorDetails[]) => {
    ModuleEventSubscriber.emitEvent({
      name: getModalOpenEvent(this.module.CreditorsAssignmentModalModuleInstanceId),
      data: {
        modalData: {
          MODAL_HEADER_TEXT: TranslationService.translateModule('CreditorsAssignment', this.props.module.name),
          MODAL_HEADER_ICON: 'fas fa-user',
          onClose: (actionSuccess?: boolean) => {
            if (actionSuccess) {
              this.fetchData();
            }
          },
        },
        otherData: {
          userUId,
          creditors,
        },
      },
    });
  };

  private fetchData = () => {
    this.setState({
      isDataLoading: true,
    });

    UsersApiClient.getUsersModuleVm(this.props.module.id).then(this.dataFetched).catch(this.fetchFailed);
  };

  private dataFetched = (vm: IUserModuleVm) => {
    this.setState({
      creditors: vm.creditors ? vm.creditors.filter((c) => c.roles && c.roles.length > 0) : [],
      isDataLoading: false,
    });
  };

  private fetchFailed = () => {
    InfoMessageService.error(TranslationService.translate('ErrorLoadingData'));
    this.setState({
      isDataLoading: false,
      creditors: [],
    });
  };

  private registerModuleEvents() {
    this.moduleEvents = [{ name: EventsList.CHANGED_CURRENT_CREDITOR, callback: this.fetchData }];
    ModuleEventSubscriber.registerEvents(this.moduleEvents);
  }
}

export default Users;
