import RestApiClient from '../../../core/api/RestApiClient';
import { CaseSummary } from '../types/CaseSummary';

class CaseSummaryApi {
  public async getCaseSummary(caseId: string): Promise<any> {
    return await RestApiClient.get<CaseSummary>(`modules/CaseSummary/${caseId}`);
  }
  public async uploadCaseFile(caseNumber: string, moduleInstanceId: string, file: any): Promise<any> {
    const headers = {
      'Content-Type': 'multipart/form-data;',
    };
    const form = new FormData();
    form.append('file', file);
    form.append('moduleInstanceId', moduleInstanceId);

    return await RestApiClient.post(`modules/CaseSummary/${caseNumber}/upload`, form, headers, false);
  }
}

export default new CaseSummaryApi();
