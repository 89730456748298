export type CreditorOnboardingAgreement = {
  id: number;
  agreementCode: string;
  baseCreditorIdentity: string;
  agreementActivityCode: string;
};

export const emptyCreditorOnboardingAgreement: CreditorOnboardingAgreement = {
  id: 0,
  agreementCode: '',
  baseCreditorIdentity: '',
  agreementActivityCode: '',
};
