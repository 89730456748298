import * as React from 'react';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import Loader from '../../core/components/Loading/Loader';
import { getModalOpenEvent } from '../../core/helpers/Modal/ModalEvents';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import { emptyComponentTable, IComponentTable } from '../../core/types/IComponentTable';
import { IModuleProps } from '../../core/types/IModuleProps';
import GroupUsersListApiClient from './api/GroupUsersListApiClient';
import GroupListTableConfigurator from './helpers/GroupListTableConfigurator';
import { IGroupUser } from './types/IGroupUser';
import { IModule } from '../../core/api/types/IModule';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { Button, BUTTON_VARIANTS } from '../../core/components/Button/Button';
import Modal from '../../core/components/Modal/Modal';
import ManageGroupUser from './components/ManageGroupUser';
import ConfirmationModal from '../../core/components/ConfirmationModal/ConfirmationModal';
import ComponentTableHelper from 'core/helpers/ComponentTableHelper';
import { Status } from '../../core/api/Enums/Status';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import { PermissionNames } from '../../core/helpers/PermissionNames';
import UserIdentityService from '../../core/services/UserIdentityService';

interface IState {
  isDataLoading: boolean;
  isOpenDropdown: boolean;
  isManageModalOpened: boolean;
  usersTable: IComponentTable<IGroupUser>;
  selectedUserUId?: string;
  isRemoveUserModalVisible: boolean;
  isModalContentLoading: boolean;
  selectedUser?: IGroupUser;
}

interface IModuleSettings extends IModule {
  CreditorsAssignmentModalModuleInstanceId: number;
}

export default class GroupUsersList extends React.Component<IModuleProps, IState> {
  private userIdentityService: UserIdentityService = new UserIdentityService();
  public module: any = flatModuleOptions<IModuleSettings>(this.props.module as any);
  public state: IState = {
    isDataLoading: false,
    isOpenDropdown: false,
    isModalContentLoading: false,
    isManageModalOpened: false,
    usersTable: { ...emptyComponentTable },
    isRemoveUserModalVisible: false,
  };

  public componentDidMount = async () => {
    if (this.userIdentityService.hasPermission(PermissionNames.CanViewGroupUsers)) {
      await this.fetchGroupUsers();
    }

    const tableEl = document.getElementsByClassName('table')[0];

    tableEl.addEventListener('show.bs.dropdown', () => {});
  };

  public render() {
    if (!this.userIdentityService.hasPermission(PermissionNames.CanViewGroupUsers)) {
      return <NoPermissionsComponent />;
    }

    return (
      <article className="l-module l-module--case-list">
        <section className="l-module__section l-module__section--head">
          <h1 className="l-module__title">
            <i className="fas fa-users mr-2" />
            {TranslationService.translateModule('LeftMenuItemRootUsers', ModuleNamesList.PortalUsersList)}:
            <strong className="l-module__title-highlighted">
              {TranslationService.translateModule('LeftMenuItemGroupUsersList', ModuleNamesList.GroupUsersList)}
            </strong>
          </h1>
        </section>
        <section className="l-module__section l-module__section--filter">
          <Button
            label={TranslationService.translateModule('ButtonCreateUser', ModuleNamesList.GroupUsersList)}
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={() => this.openManageUserModal()}
          />
        </section>
        <section className={`l-module__section mt-3 ${this.state.isDataLoading ? 'l-module__section--loading' : ''}`}>
          {this.state.isDataLoading ? (
            <Loader opacity={0.5} />
          ) : (
            <BootstrapTableContainer
              remote={false}
              paginationProps={{}}
              wrapperClasses="bt"
              classes="bt__table bt-table"
              keyField="uId"
              data={this.state.usersTable.values}
              columns={GroupListTableConfigurator.getTableColumns(
                this.state.usersTable,
                this.openCreditorsAssignmentModal,
                this.openManageUserModal,
                this.openRemoveGroupUserModal,
                this.openRolesAssignmentModal,
                this.toggleDropdown
              )}
            />
          )}
        </section>
        <Modal
          isOpen={this.state.isManageModalOpened}
          onClose={() => this.setState({ isManageModalOpened: false })}
          modalData={{
            headerText: TranslationService.translateModule(
              'ManageGroupUserModalHeader',
              ModuleNamesList.GroupUsersList
            ),
            className: 'c-modal--lg',
            content: (
              <ManageGroupUser
                userUId={this.state.selectedUserUId}
                reloadList={this.fetchGroupUsers}
                hideModal={() => this.setState({ isManageModalOpened: false })}
              />
            ),
          }}
        />

        {this.state.selectedUser && (
          <ConfirmationModal
            isOpen={this.state.isRemoveUserModalVisible}
            isModalContentLoading={this.state.isModalContentLoading}
            message={TranslationService.translateModule('', ModuleNamesList.PortalUsersList).replace(
              '{0}',
              this.state.selectedUser.userName
            )}
            onCancel={() => this.setState({ isRemoveUserModalVisible: false })}
            onOk={() => this.removeGroupUser()}
          />
        )}
      </article>
    );
  }

  toggleDropdown = () => {
    this.setState({
      isOpenDropdown: !this.state.isOpenDropdown,
    });
  };

  private fetchGroupUsers = async () => {
    this.setState({
      isDataLoading: true,
    });

    try {
      const usersTable = await GroupUsersListApiClient.getGroupUsersTable(this.props.module.id);
      this.setState({
        isDataLoading: false,
        usersTable,
      });
    } catch {
      this.setState(
        {
          isDataLoading: false,
        },
        () => {
          InfoMessageService.error(TranslationService.translate('ErrorLoadingData'));
        }
      );
    }
  };

  private openManageUserModal = (userUId?: string) => {
    this.setState({ selectedUserUId: userUId, isManageModalOpened: true });
  };

  private openCreditorsAssignmentModal = (userUId: string, fullName: string) => {
    ModuleEventSubscriber.emitEvent({
      name: getModalOpenEvent(this.module.CreditorsAssignmentModalModuleInstanceId),
      data: {
        modalData: {
          MODAL_HEADER_TEXT:
            TranslationService.translateModule('ActionCreditorsAssignment', ModuleNamesList.GroupUsersList) +
            ': ' +
            fullName,
          MODAL_HEADER_ICON: 'fas fa-user-tie',
          className: 'c-modal--lg',
        },
        otherData: {
          userUId,
        },
      },
    });
  };

  private openRolesAssignmentModal = (userUId: string, fullName: string) => {
    ModuleEventSubscriber.emitEvent({
      name: getModalOpenEvent(this.module.RolesAssignmentModalModuleInstanceId),
      data: {
        modalData: {
          MODAL_HEADER_TEXT:
            TranslationService.translateModule('ActionRolesAssignment', ModuleNamesList.GroupUsersList) +
            ': ' +
            fullName,
          MODAL_HEADER_ICON: 'fas fa-address-card',
          className: 'c-modal--lg',
        },
        otherData: {
          userUId,
        },
      },
    });
  };

  private openRemoveGroupUserModal = (item: IGroupUser) => {
    this.setState({ isRemoveUserModalVisible: true, selectedUser: item });
  };

  private removeGroupUser = async () => {
    if (this.state.selectedUser) {
      this.setState({ isModalContentLoading: true });
      const result = await GroupUsersListApiClient.removeUser(this.state.selectedUser.uId);

      if (result.status === Status.Success) {
        const usersTable = ComponentTableHelper.removeElement(this.state.usersTable, this.state.selectedUser, 'uId');
        this.setState({
          usersTable,
          isModalContentLoading: false,
          isRemoveUserModalVisible: false,
        });
        InfoMessageService.success(
          TranslationService.translateModule('SuccessUserRemoved', ModuleNamesList.GroupUsersList)
        );
      } else {
        InfoMessageService.displayActionStatus(result);
      }
    }
  };
}
