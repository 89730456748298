import * as React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import { IInvoiceDetails } from '../types/IInvoiceDetails';
import DateTimeParser from '../../../core/helpers/DateTimeParser';

interface IProps {
  invoices: IInvoiceDetails[];
}

class InvoicesDetails extends React.Component<IProps> {
  public render() {
    return (
      <>
        <h6>{TranslationService.translateModule('Invoices', ModuleNamesList.PrintCase)}</h6>
        <table className="table table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th>{TranslationService.translateModule('InvoicesInvoiceNumber', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('InvoicesDescription', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('InvoicesInvoiceDate', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('InvoicesDueDate', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('InvoicesCurrency', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('InvoicesOriginalAmount', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('InvoicesMovement', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('InvoicesBalance', ModuleNamesList.PrintCase)}</th>
              <th>{TranslationService.translateModule('InvoicesInterestBalance', ModuleNamesList.PrintCase)}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.invoices.map((item) => {
              return (
                <tr key={item.invoiceNumber}>
                  <td>{item.invoiceNumber}</td>
                  <td>{item.description}</td>
                  <td>{DateTimeParser.toLocaleDateString(item.invoiceDate)}</td>
                  <td>{DateTimeParser.toLocaleDateString(item.dueDate)}</td>
                  <td>{item.currency}</td>
                  <td>{CurrencyParser.toLocaleString(item.originalAmount)}</td>
                  <td>{CurrencyParser.toLocaleString(-1 * item.movement)}</td>
                  <td>{CurrencyParser.toLocaleString(item.balance)}</td>
                  <td>{CurrencyParser.toLocaleString(item.interestBalance)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default InvoicesDetails;
