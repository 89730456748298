import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import fetchApplicationSettings from './actionCreators/fetchApplicationSettings';
import fetchCreditors from './actionCreators/fetchCreditors';
import fetchLanguages from './actionCreators/fetchLanguages';
import fetchLeftMenu from './actionCreators/fetchLeftMenu';
import fetchPages from './actionCreators/fetchPages';
import fetchPermissions from './actionCreators/fetchPermissions';
import fetchStylingSettings from './actionCreators/fetchStylingSettings';
import fetchTranslations from './actionCreators/fetchTranslations';
import fetchUserCreditorRoles from './actionCreators/fetchUserCreditorRoles';
import App from './App';
import Loader from './core/components/Loading/Loader';
import UserIdentityService from './core/services/UserIdentityService';
import { IStore } from './reducers/IStore';
import { LanguageCode } from './core/types/ILanguage';
import { UserManager } from 'oidc-client';
import { IDictionary } from './core/types/IDictionary';
import { ITranslationCollection } from './core/types/ITranslationCollection';

type Props = {
  fetchStylingSettings: () => void;
  fetchApplicationSettings: () => void;
  fetchTranslations: () => void;
  fetchLanguages: () => void;
  fetchPages: () => void;
  fetchCreditors: () => void;
  fetchUserCreditorRoles: () => void;
  fetchLeftMenu: () => void;
  fetchPermissions: () => void;
  language: string;
  translations: IDictionary<ITranslationCollection>;
};

const AppLoader = (props: Props) => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [userManager, setUserManager] = useState<UserManager>();

  const { language } = props;

  const userIdentityService = new UserIdentityService();

  const excludeRoutes = (location: Location) => {
    const pathname = location.pathname.toLowerCase();
    const exclude = ['/silent-renew'];

    for (const key in exclude) {
      if (!exclude.hasOwnProperty(key)) {
        continue;
      }

      const excludePathname = exclude[key];

      if (excludePathname === pathname) {
        return true;
      }
    }

    return false;
  };

  const load = async () => {
    setIsDataLoading(true);

    try {
      const stylingsTask = props.fetchStylingSettings();
      const settingsTask = props.fetchApplicationSettings();
      const translationsTask = props.fetchTranslations();
      const languagesTask = props.fetchLanguages();
      const pagesTask = props.fetchPages();

      await stylingsTask;
      await settingsTask;
      await translationsTask;
      await languagesTask;
      await pagesTask;

      const userManager = await userIdentityService.getManager();

      if (userIdentityService.IsLoggedIn()) {
        await props.fetchPermissions();
        await props.fetchCreditors();
        await props.fetchUserCreditorRoles();
        await props.fetchLeftMenu();
      }

      setUserManager(userManager);
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    setIsDataLoading(true);

    const sessionLanguage = localStorage.getItem('selectedLanguage') as string;

    if (sessionLanguage && sessionLanguage !== language) {
      localStorage.setItem('selectedLanguage', language as LanguageCode);
    }

    if (!excludeRoutes(window.location)) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return isDataLoading || !userManager ? <Loader main={true} opacity={1} /> : <App userManager={userManager} />;
};

const mapStateToProps = (state: IStore) => ({
  language: state.currentCultureCode,
  translations: state.translations,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, AnyAction>) => ({
  fetchPages: () => dispatch(fetchPages()),
  fetchTranslations: () => dispatch(fetchTranslations()),
  fetchLanguages: () => dispatch(fetchLanguages()),
  fetchCreditors: () => dispatch(fetchCreditors()),
  fetchApplicationSettings: () => dispatch(fetchApplicationSettings()),
  fetchStylingSettings: () => dispatch(fetchStylingSettings()),
  fetchUserCreditorRoles: () => dispatch(fetchUserCreditorRoles()),
  fetchLeftMenu: () => dispatch(fetchLeftMenu()),
  fetchPermissions: () => dispatch(fetchPermissions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLoader);
