import React from 'react';
import classNames from 'classnames';
import styles from './Typography.module.scss';
import TypographyVariants from './types/variants';

type TypographyPropstype = {
  class?: string;
  variant: string;
  uppercase?: boolean;
  children: React.ReactChild;
};

const Typography = (props: TypographyPropstype) => {
  const classTag = classNames(
    styles.heading,
    props.class,
    props.variant && styles[`heading--${props.variant}`],
    props.uppercase && styles['heading--uppercase']
  );

  return (() => {
    switch (props.variant) {
      case TypographyVariants.H1:
        return <h1 className={classTag}>{props.children}</h1>;
      case TypographyVariants.H2:
        return <h2 className={classTag}>{props.children}</h2>;
      case TypographyVariants.H3:
        return <h3 className={classTag}>{props.children}</h3>;
      case TypographyVariants.Span:
        return <span className={classTag}>{props.children}</span>;
      case TypographyVariants.Paragraph:
        return <p className={classTag}>{props.children}</p>;
      default:
        return null;
    }
  })();
};

export { Typography, TypographyVariants };
