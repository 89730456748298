import * as React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { INoteDetails } from '../types/INoteDetails';
import DateTimeParser from '../../../core/helpers/DateTimeParser';

interface IProps {
  notes: INoteDetails[];
}

class NotesDetails extends React.Component<IProps> {
  public render() {
    return (
      <>
        <h6>{TranslationService.translateModule('Notes', ModuleNamesList.PrintCase)}</h6>
        {this.props.notes.map((item) => {
          return (
            <div className="w-100" key={item.id}>
              <label className="font-weight-bold mb-1 w-100">{DateTimeParser.toLocaleDateTimeString(item.date)}</label>
              <label className="small w-100">{item.text}</label>
            </div>
          );
        })}
      </>
    );
  }
}

export default NotesDetails;
