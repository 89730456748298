import React from 'react';
import classNames from 'classnames';
import styles from './Card.module.scss';

enum CardVariants {
  Secondary = 'secondary',
}

type CardPropsType = {
  variant?: CardVariants;
  children: React.ReactNode;
  class?: string;
  container?: boolean;
};

const Card = (props: CardPropsType) => {
  return (
    <div className={classNames(props.container && 'container container--force')}>
      <div className={classNames(styles.card, props.class, props.variant && styles[`card--${props.variant}`])}>
        {props.children}
      </div>
    </div>
  );
};

export { Card, CardVariants };
