import React, { useState } from 'react';
import CheckboxVariants from './types/CheckboxVariants';
import styles from './Checkbox.module.scss';
import classNames from 'classnames';

type CheckboxInputPropsType = {
  id: string;
  name: string;
  label: string;
  variant?: CheckboxVariants;
  onChange: () => void;
};

const CheckboxInput = (props: CheckboxInputPropsType) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked && e.target.name === props.name);

    props.onChange();
  };

  return (
    <div
      className={classNames(
        styles.checkboxInput,
        styles[`checkboxInput--${props.variant}`],
        isChecked && styles.checkboxInputChecked
      )}
    >
      {/* <div className={styles.checkboxInput}> */}
      <label htmlFor={props.id}>
        <i className="fas fa-check" />
        Standard repporter
      </label>
      <input type="checkbox" id={props.id} name={props.name} onChange={handleCheckboxChange} />
      {/* </div> */}
    </div>
  );
};

export default CheckboxInput;
