export interface IAutoOnboardingTokenData {
  token: string;
  data: string;
  userEmail: string;
  status: number;
}

export interface IAutoOnboardingCreditorAddressModel {
  street: string;
  postalCode: string;
  city: string;
}

export interface IAutoOnboardingTokenDataModel {
  user: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    ssn: string;
  };
  creditor: {
    organizationNumber: string;
    email: string;
    country: string;
    phoneNumber: string;
    address: IAutoOnboardingCreditorAddressModel;
    companyName: string;
    vatNo: string;
    legalType: string;
  };
  feedback: {
    agreement: string;
    feedbackUrl: string;
    refId: string;
  };
}

export enum AutoOnboardingTokenStatus {
  Unknown = 0,
  Created = 1,
  PendingUserRegistration = 2,
  PassedBankIdVerification = 3,
  FailedBankIdVerification = 4,
  PassedBisnodeVerification = 5,
  FailedBisnodeVerification = 6,
  PendingCreditorRegistration = 7,
  Done = 8,
}

export const emptyTokenData: IAutoOnboardingTokenData = {
  token: '',
  data: '',
  userEmail: '',
  status: 0,
};
