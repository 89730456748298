import RestApiClient from '../../../core/api/RestApiClient';
import { IChatMessage } from '../types/IChatMessage';
import { IActionStatus } from 'core/api/types/IActionStatus';

class ChatApiClient {
  public async sendChatMessages(chatMessages: IChatMessage[]): Promise<IActionStatus> {
    return await RestApiClient.post<IActionStatus>('modules/Chat/sendChatMessages', chatMessages);
  }
}

export default new ChatApiClient();
