import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import TranslationService from './../../../core/services/TranslationService';

interface IListInputs {
  name: string;
  label?: string;
  multiselect: boolean;
  availableSelectAll?: boolean;
  allInputsSelected?: boolean;
  listInputs: any[];
  selectedInputs: any[];
  onAllSelect?: () => void;
  onSelect: (data: any) => void;
  handleChange: (data: any[]) => void;
}

const ListInputs = (props: IListInputs) => {
  const [selectedValues, setSelectValue] = useState([...props.selectedInputs]);

  useEffect(() => {
    setSelectValue([...props.selectedInputs]);
  }, [props.selectedInputs, props.allInputsSelected]);

  const handleOnAllSelect = () => {
    if (props.onAllSelect) {
      props.onAllSelect();
    }
  };

  const onClickHandler = (item: any) => {
    const { value } = item;
    let values: any[] = [];
    let indexValue;

    if (props.multiselect) {
      values = [...selectedValues];
    } else {
      values = [];
    }

    indexValue = values.indexOf(value);

    if (indexValue > -1) {
      values.splice(indexValue, 1);
    } else {
      values.push(value);
    }

    props.handleChange([...values]);

    setSelectValue([...values]);
  };

  const isRequired = (values: any) => {
    return values && values.length > 0 ? undefined : TranslationService.translate('ValidationMessageMinSelection');
  };

  return (
    <Field name={props.name} validate={isRequired}>
      {({ field, form }: any) => (
        <div className="c-select-list">
          <p className="c-select-list__label">{props.label}</p>

          <select {...field} multiple={true} value={[...selectedValues]}>
            {props.listInputs &&
              props.listInputs.map((item: any, index: number) => {
                return (
                  <option key={index} value={item.value} className="c-creator-list__item">
                    {item.key}
                  </option>
                );
              })}
          </select>

          <ul className="c-list c-creator-list c-creator-list--2">
            {props.availableSelectAll && (
              <li key="0" className="c-creator-list__item" onClick={handleOnAllSelect}>
                {TranslationService.translate('SelectAll')}
                {props.allInputsSelected ? <i className="far fa-check-square" /> : <i className="far fa-square" />}
              </li>
            )}

            {props.listInputs &&
              props.listInputs.map((item: any, index: number) => {
                return (
                  <li key={index} className="c-creator-list__item" onClick={() => onClickHandler(item)}>
                    {item.name || item.key}

                    {selectedValues.indexOf(item.value) > -1 ? (
                      <i className="far fa-check-square" />
                    ) : (
                      <i className="far fa-square" />
                    )}
                  </li>
                );
              })}
          </ul>

          {form.errors[field.name] && form.touched[field.name] && (
            <div className="c-input-error">{form.errors[field.name]}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default ListInputs;
