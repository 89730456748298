import CaseListApiClient from './CaseListApiClient';
import { ICasesRequest } from './types/ICasesRequest';
import { ICaseListResponse } from './types/ICaseListResponse';
import { ICaseInitialSettings } from './types/ICaseInitialSettings';
import { CancelToken } from 'axios';

class CaseListService {
  public async getCasesList(request: ICasesRequest, cancelToken: CancelToken): Promise<ICaseListResponse> {
    return await CaseListApiClient.getCasesList(request, cancelToken);
  }
  public async getInitialSettings(moduleInstanceId: number, cancelToken: CancelToken): Promise<ICaseInitialSettings> {
    return await CaseListApiClient.getInitialSettings(moduleInstanceId, cancelToken);
  }
  public async getCasesListFile(request: ICasesRequest): Promise<Blob> {
    return await CaseListApiClient.getCasesListFile(request);
  }
}

export default new CaseListService();
