import React from 'react';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import SettingsTypeCellEditor from './SettingsTypeCellEditor';
import TranslationService from '../../../core/services/TranslationService';
import { SettingsType } from '../../../core/Enums/SettingsType';

export const getSimpleColumns = (
  config: { isResetAvailable: boolean },
  handleChange: (value: any, id: number) => void,
  handleResetClick: (id: number) => void
) => {
  const settingTypeOnBlur = [
    SettingsType.String,
    SettingsType.Int,
    SettingsType.HtmlString,
    SettingsType.TextArea,
    SettingsType.Dictionary,
  ];
  const columns = [
    {
      dataField: 'description',
      sort: true,
      text: TranslationService.translateModule('Name', ModuleNamesList.ModuleSettings),
      editable: false,
      formatter: (cell: string) => {
        return cell;
      },
      headerStyle: () => ({ width: '50%' }),
    },
    {
      dataField: 'value',
      sort: false,
      text: TranslationService.translateModule('Value', ModuleNamesList.ModuleSettings),
      editable: true,
      formatter: (cell: any, row: any) => {
        return (
          <SettingsTypeCellEditor
            value={cell}
            id={row.id}
            type={row.type}
            row={row}
            onBlur={(value) => {
              if (settingTypeOnBlur.includes(row.type)) {
                handleChange(value, row.id);
              }
            }}
            onKeyUp={({ keyCode, target }) => {
              if (keyCode === 13) {
                target.blur();
              }
            }}
            onUpdate={(value) => {
              if (!settingTypeOnBlur.includes(row.type)) {
                handleChange(value, row.id);
              }
            }}
          />
        );
      },
      headerStyle: () => ({ width: '50%' }),
    },
  ] as IComponentTableColumn[];

  if (config.isResetAvailable) {
    columns.push({
      dataField: 'actions',
      sort: false,
      text: 'Actions',
      formatter: (cell: any, row: any) => {
        return row.isDefault ? (
          ''
        ) : (
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTON_SIZE.MD}
            type="button"
            label={TranslationService.translateModule('Reset', ModuleNamesList.PagesList)}
            onClick={() => handleResetClick(row.id)}
          />
        );
      },
      headerStyle: () => ({ width: '50%' }),
    });
  }

  return columns;
};
