export type IPaymentsHistory = {
  zeroTo7: number;
  sevenTo14: number;
  fourteenTo30: number;
};

export const PaymentHistoryDataInit = {
  fourteenTo30: 0,
  sevenTo14: 0,
  zeroTo7: 0,
};
