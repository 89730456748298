import { ErrorMessage, Form } from 'formik';
import React, { Component } from 'react';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import DatePicker from '../../../core/components/Forms/DatePicker';
import { FormFieldset } from '../../../core/components/Forms/FormFieldset';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../../core/components/Forms/FormGroup';
import SimpleCheckbox from '../../../core/components/Forms/SimpleCheckbox';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import { ISelectedValue } from '../../../core/components/Forms/types/ISelectedValue';
import { CaseProgressType } from '../../../core/Enums/CaseProgressType';
import { decimalRegex } from '../../../core/helpers/utils';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { INumberDictionary } from '../../../core/types/IDictionary';
import { CaseActivity } from '../enums/CaseActivity';
import { ICaseListFilterRequest } from '../types/ICaseListFilterRequest';

type Props = {
  filterCases: (request: ICaseListFilterRequest) => void;
  baseRequest: ICaseListFilterRequest;
  filterByOptions: INumberDictionary<string>;
  filterByDisabled?: boolean;
};

const initialState = {
  filterRequest: {
    caseProgress: [],
    caseActivity: [],
    filteredByValue: '',
    amountLargerThan: '',
  } as ICaseListFilterRequest,
  isAmountLargerThanValid: true,
  isAmountLargerThanTouched: false,
  aditionalFiltersVisible: false,
};
type State = typeof initialState;

class CaseListFilter extends Component<Props, State> {
  state = initialState;

  caseProgressOptions: any[] = [
    {
      [CaseProgressType.Reminder]: TranslationService.translate('CaseProgressReminder'),
    },
    {
      [CaseProgressType.PreLegal]: TranslationService.translate('CaseProgressPreLegal'),
    },
    {
      [CaseProgressType.Legal]: TranslationService.translate('CaseProgressLegal'),
    },
    {
      [CaseProgressType.Surveillance]: TranslationService.translate('CaseProgressSurveillance'),
    },
  ];

  caseActivityOptions: INumberDictionary<string> = {
    [CaseActivity.Opened]: TranslationService.translateModule('FilterLabelCaseOpened', ModuleNamesList.CaseList),
    [CaseActivity.Closed]: TranslationService.translateModule('FilterLabelCaseClosed', ModuleNamesList.CaseList),
  };

  componentDidMount = () => {
    const caseActivities = this.props.baseRequest.caseActivity.map(
      (element: CaseActivity) => CaseActivity[element.toString()]
    );
    const caseProgresses = this.props.baseRequest.caseProgress.map(
      (element: CaseProgressType) => CaseProgressType[element.toString()]
    );
    const filteredBy = this.props.baseRequest.filteredBy;
    const filteredByValue = this.props.baseRequest.filteredByValue;
    this.setState({
      filterRequest: {
        ...this.state.filterRequest,
        caseActivity: caseActivities,
        caseProgress: caseProgresses,
        filteredBy,
        filteredByValue,
      },
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      this.props.baseRequest.filteredBy !== prevProps.baseRequest.filteredBy ||
      this.props.baseRequest.filteredByValue !== prevProps.baseRequest.filteredByValue
    ) {
      this.setState({
        filterRequest: {
          ...this.state.filterRequest,
          filteredBy: this.props.baseRequest.filteredBy,
          filteredByValue: this.props.baseRequest.filteredByValue,
        },
      });
    }
  };

  render() {
    return (
      <>
        <Form className="c-form">
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4">
              <FormFieldset
                className="br"
                label={TranslationService.translate('FilterBy')}
                render={() => (
                  <div className="row">
                    <div className="col">
                      <FormGroup
                        variant={FORM_GROUP_VARIANTS.INLINE}
                        name="invoiceNoFrom"
                        render={(name) => (
                          <>
                            <SimpleSelect
                              id="case-list-select-filter"
                              value={
                                this.state.filterRequest.filteredBy
                                  ? this.state.filterRequest.filteredBy.toString()
                                  : undefined
                              }
                              onChangeHandle={(value: ISelectedValue) => {
                                this.setState({
                                  filterRequest: {
                                    ...this.state.filterRequest,
                                    filteredBy: parseInt(value.key),
                                  },
                                });
                              }}
                              disabled={this.props.filterByDisabled}
                              emptyFirstRow={true}
                              options={this.props.filterByOptions}
                            />

                            <ErrorMessage name={name} component="div" className="error error-message" />
                          </>
                        )}
                      />
                    </div>
                    <div className="col">
                      <FormGroup
                        variant={FORM_GROUP_VARIANTS.INLINE}
                        name="invoiceNoTo"
                        render={(name) => (
                          <>
                            <SimpleInput
                              id="filteredByValue"
                              value={this.state.filterRequest.filteredByValue}
                              onChange={(value: string) => {
                                this.setState({
                                  filterRequest: {
                                    ...this.state.filterRequest,
                                    filteredByValue: value,
                                  },
                                });
                              }}
                              disabled={this.props.filterByDisabled}
                            />

                            <ErrorMessage name={name} component="div" className="error error-message" />
                          </>
                        )}
                      />
                    </div>
                  </div>
                )}
              />
            </div>

            <div className="col-12 col-sm-6 col-lg-5">
              <FormFieldset
                className="br"
                label={TranslationService.translateModule('DateRange', ModuleNamesList.CaseList)}
                render={() => (
                  <div className="row">
                    <div className="col">
                      <FormGroup
                        variant={FORM_GROUP_VARIANTS.INLINE}
                        name="invoiceNoFrom"
                        label={TranslationService.translateModule('From', ModuleNamesList.TransferToDebtCollection)}
                        render={(name) => (
                          <>
                            <DatePicker
                              inputId={name}
                              inputName={name}
                              placeholder={TranslationService.translateModule(
                                'FilterDateFrom',
                                ModuleNamesList.CaseList
                              )}
                              onChange={(date: Date) => {
                                this.setState({
                                  filterRequest: {
                                    ...this.state.filterRequest,
                                    dateFrom: date,
                                  },
                                });
                              }}
                            />

                            <ErrorMessage name={name} component="div" className="error error-message" />
                          </>
                        )}
                      />
                    </div>
                    <div className="col">
                      <FormGroup
                        variant={FORM_GROUP_VARIANTS.INLINE}
                        name="invoiceNoTo"
                        label={TranslationService.translateModule('To', ModuleNamesList.TransferToDebtCollection)}
                        render={(name) => (
                          <>
                            <DatePicker
                              inputId={name}
                              inputName={name}
                              placeholder={TranslationService.translateModule('FilterDateTo', ModuleNamesList.CaseList)}
                              onChange={(date: Date) => {
                                this.setState({
                                  filterRequest: {
                                    ...this.state.filterRequest,
                                    dateTo: date,
                                  },
                                });
                              }}
                            />

                            <ErrorMessage name={name} component="div" className="error error-message" />
                          </>
                        )}
                      />
                    </div>
                  </div>
                )}
              />
            </div>
          </div>

          {this.state.aditionalFiltersVisible && (
            <div className="row mt-3">
              <div className="col-12 col-md-2 col-lg-2">
                <FormGroup
                  name="amountLargerThan"
                  variant={FORM_GROUP_VARIANTS.BLOCK}
                  label={TranslationService.translateModule('FilterLabelAmountLargerThan', ModuleNamesList.CaseList)}
                  render={(name) => (
                    <>
                      <SimpleInput
                        id="amountLargerThan"
                        className={
                          !this.state.isAmountLargerThanValid && this.state.isAmountLargerThanTouched
                            ? 'c-form-control error'
                            : 'c-form-control'
                        }
                        value={this.state.filterRequest.amountLargerThan}
                        onBlur={() => {
                          this.setState({ isAmountLargerThanTouched: true });
                        }}
                        onChange={(value: string) => {
                          const isAmountLargerThanValid = !value || decimalRegex.test(value);
                          this.setState({
                            filterRequest: {
                              ...this.state.filterRequest,
                              amountLargerThan: value,
                            },
                            isAmountLargerThanValid,
                          });
                        }}
                      />
                      {!this.state.isAmountLargerThanValid && this.state.isAmountLargerThanTouched && (
                        <div className="error error-message">
                          {TranslationService.translate('ValidationMessageMustBeDecimalNumberError')}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="col-12 col-md-2 col-lg-2">
                <FormGroup
                  name="zipCode"
                  variant={FORM_GROUP_VARIANTS.BLOCK}
                  label={TranslationService.translateModule('FilterLabelZip', ModuleNamesList.CaseList)}
                  render={(name) => (
                    <>
                      <SimpleInput
                        id="zipCode"
                        value={this.state.filterRequest.zipCode}
                        onChange={(value: string) => {
                          this.setState({
                            filterRequest: {
                              ...this.state.filterRequest,
                              zipCode: value,
                            },
                          });
                        }}
                      />

                      <ErrorMessage name={name} component="div" className="error error-message" />
                    </>
                  )}
                />
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <FormGroup
                  name="productType"
                  variant={FORM_GROUP_VARIANTS.BLOCK}
                  label={TranslationService.translateModule('FilterLabelCaseProgress', ModuleNamesList.CaseList)}
                  render={(name) => (
                    <>
                      <SimpleCheckbox
                        name={name}
                        options={this.caseProgressOptions}
                        asArray={true}
                        value={this.state.filterRequest.caseProgress.map((element: CaseProgressType) =>
                          CaseProgressType[element].toString()
                        )}
                        onChange={(values: string[]) =>
                          this.setState({
                            filterRequest: {
                              ...this.state.filterRequest,
                              caseProgress: values.map((element: string) => CaseProgressType[element]),
                            },
                          })
                        }
                        displayInline={true}
                      />

                      <ErrorMessage name={name} component="div" className="error error-message" />
                    </>
                  )}
                />
              </div>

              <div className="col-12 col-md-4 col-lg-3">
                <FormGroup
                  name="caseActivity"
                  variant={FORM_GROUP_VARIANTS.BLOCK}
                  label={TranslationService.translateModule('FilterLabelCaseActivity', ModuleNamesList.CaseList)}
                  render={(name) => (
                    <>
                      <SimpleCheckbox
                        name="caseActivity"
                        options={this.caseActivityOptions}
                        value={this.state.filterRequest.caseActivity.map((element: CaseActivity) =>
                          CaseActivity[element].toString()
                        )}
                        onChange={(values: string[]) =>
                          this.setState({
                            filterRequest: {
                              ...this.state.filterRequest,
                              caseActivity: values.map((element: string) => CaseActivity[element]),
                            },
                          })
                        }
                        displayInline={true}
                      />

                      <ErrorMessage name={name} component="div" className="error error-message" />
                    </>
                  )}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col">
              <Button
                type="button"
                id="caseListFilterBtn"
                className="mr-2"
                variant={BUTTON_VARIANTS.PRIMARY}
                disabled={!this.state.isAmountLargerThanValid}
                size={BUTTON_SIZE.SM}
                label={TranslationService.translateModule('Filter', ModuleNamesList.CaseList)}
                onClick={() => this.props.filterCases(this.state.filterRequest)}
              />

              <Button
                type="button"
                id="caseListMoreFiltersBtn"
                variant={BUTTON_VARIANTS.LINK}
                size={BUTTON_SIZE.SM}
                label={TranslationService.translateModule('MoreFilters', ModuleNamesList.CaseList)}
                onClick={() =>
                  this.setState({
                    aditionalFiltersVisible: !this.state.aditionalFiltersVisible,
                  })
                }
              />
            </div>
          </div>
        </Form>
      </>
    );
  }
}

export default CaseListFilter;
