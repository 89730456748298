import React from 'react';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { IComponentTable, emptyComponentTable } from '../../core/types/IComponentTable';
import { IGuarantor, GuarantorType } from './types/IGuarantor';
import Loader from '../../core/components/Loading/Loader';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import GuarantorsApiClient from './api/GuarantorsApiClient';
import { IComponentTableColumn } from '../../core/types/IComponentTableColumn';
import TranslationService from '../../core/services/TranslationService';

type IState = {
  guarantorsTable: IComponentTable<IGuarantor>;
  isDataLoading: boolean;
};

class Guarantors extends React.Component<IExtendedModuleProps, IState> {
  state: IState = {
    guarantorsTable: { ...emptyComponentTable },
    isDataLoading: false,
  };

  componentDidMount = async () => {
    this.setState({ isDataLoading: true });
    const { id } = this.props.routeParameters;
    const guarantorsTable = await GuarantorsApiClient.getGuarantorsTable(this.props.module.id, id as string);
    this.setState({ guarantorsTable, isDataLoading: false });
  };

  render = () => {
    return (
      <div className="position-relative mt-4">
        <section className={`l-module__section ${this.state.isDataLoading ? 'l-module__section--loading' : ''}`}>
          {this.state.isDataLoading ? <Loader opacity={0.5} /> : null}
          <BootstrapTableContainer
            remote={false}
            wrapperClasses="bt"
            paginationProps={{}}
            classes="bt__table bt-table"
            keyField="id"
            data={this.state.guarantorsTable.values}
            columns={this.getColumns(this.state.guarantorsTable.columns)}
          />
        </section>
      </div>
    );
  };

  getColumns = (columns: IComponentTableColumn[]) => {
    const typeCol = columns.filter((c) => c.dataField === 'guarantorType');
    if (typeCol && typeCol.length === 1) {
      typeCol[0].formatter = (val: any) => {
        return TranslationService.translateModule(`GuarantorType_${GuarantorType[val]}`, this.props.module.name);
      };
    }

    return columns;
  };
}

export default Guarantors;
