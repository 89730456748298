import CaseSummaryApiClient from '../api/CaseSummaryApiClient';

class CaseSummaryService {
  public async getCaseSummary(caseId: string) {
    return await CaseSummaryApiClient.getCaseSummary(caseId);
  }
  public async uploadCaseFile(caseNumber: string, moduleInstanceId: string, file: any) {
    return await CaseSummaryApiClient.uploadCaseFile(caseNumber, moduleInstanceId, file);
  }
}

export default new CaseSummaryService();
