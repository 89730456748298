import RestApiClient from '../../../core/api/RestApiClient';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

export default {
  uploadFiles: async (moduleInstanceId: string, comment: string, files: FileList): Promise<IActionStatus> => {
    const headers = {
      'Content-Type': 'multipart/form-data;',
    };
    const form = new FormData();
    Array.from(files).forEach((f) => {
      form.append('files', f);
    });
    form.append('comment', comment);
    form.append('moduleInstanceId', moduleInstanceId);

    return await RestApiClient.post(`modules/CreditorFileUploadModule/upload`, form, headers, false);
  },
};
