import { Dispatch } from 'redux';
import * as ACTIONS from '../actions';
import PermissionsService from '../core/services/PermissionsService';

const fetchPermissionsInProgress = () => ({
  type: ACTIONS.FETCH_PERMISSIONS_IN_PROGRESS,
});

const fetchPermissionsSucceeded = (data: any) => ({
  type: ACTIONS.FETCH_PERMISSIONS_SUCCEEDED,
  permissions: data,
});

const fetchPermissionsFailed = () => ({
  type: ACTIONS.FETCH_PERMISSIONS_FAILED,
});

export default () => (dispatch: Dispatch<any>) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(fetchPermissionsInProgress());
    return PermissionsService.getUserPermissions()
      .then((data) => {
        dispatch(fetchPermissionsSucceeded(data));
        localStorage.setItem('user.permissions', JSON.stringify(data));
        resolve();
      })
      .catch((_) => {
        dispatch(fetchPermissionsFailed());
        reject();
      });
  });
};
