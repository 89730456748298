import React from 'react';
import { IProps, IState } from './types/IStepFrom';

class StepForm extends React.Component<IProps, IState> {
  public state = {
    hideControlBtn: false,
  };

  render() {
    const { isActive, displaySubmit, children, component, visitedSteps } = this.props;

    if (isActive === false) return null;

    const isLoaded = this.props.isLoaded === undefined ? true : this.props.isLoaded;

    return (
      <>
        {isLoaded && (
          <>
            {component ? (
              React.createElement(component)
            ) : (
              <>
                {React.Children.map(children, (child) => {
                  return React.cloneElement(child, {
                    visitedSteps,
                    displaySubmit,
                    goToPreviousStep: this.props.goToPreviousStep,
                    goToNextStep: this.goToNextStep,
                  });
                })}
              </>
            )}
          </>
        )}
      </>
    );
  }

  goToNextStep = (stepData?: {}, isLastStep?: boolean) => {
    if (isLastStep) {
      this.props.onSubmit(stepData);
    } else {
      this.props.goToNextStep(stepData);
    }
  };
}

export default StepForm;
