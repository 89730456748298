import RestApiClient from '../../../core/api/RestApiClient';
import { IRole } from '../types/IRole';
import { IAccessPermission } from '../types/IAccessPermission';
import { IPermission } from '../types/IPermission';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

const ManagePagePermissionsApiClient = {
  getRoles: async () => {
    return await RestApiClient.get<IRole[]>('modules/ManagePagePermissionsModule/roles');
  },
  getPermissions: async () => {
    return await RestApiClient.get<IPermission[]>('modules/ManagePagePermissionsModule/permissions');
  },
  getPageAccessPermissions: async (pageId: number) => {
    return await RestApiClient.get<IAccessPermission[]>(`modules/ManagePagePermissionsModule/page/${pageId}`);
  },
  savePageAccessPermissions: async (pageId: number, accessPermissions: IAccessPermission[]) => {
    return await RestApiClient.post<IActionStatus>(`modules/ManagePagePermissionsModule/page/${pageId}`, {
      accessPermissions,
    });
  },
};

export default ManagePagePermissionsApiClient;
