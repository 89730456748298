import React from 'react';

type PropsType = {
  key: string;
  value: any;
};

const useLocalStorageState = (props: PropsType) => {
  const parsedLocalStorage = JSON.parse(sessionStorage.getItem(props.key) || '{}');
  const initialValue = Object.keys(parsedLocalStorage).length > 0 ? parsedLocalStorage : props.value;

  const [localStorageState, setLocalStorageState] = React.useState(initialValue);

  const handleUpdateLocalStorageState = React.useCallback(
    (x, force) => {
      if (sessionStorage.getItem(props.key) === null || force) {
        setLocalStorageState(x);
      }

      sessionStorage.setItem(props.key, JSON.stringify(x));
    },
    [props.key]
  );

  const clearLocalStorage = (key: string) => {
    sessionStorage.removeItem(key);
  };

  return [localStorageState, handleUpdateLocalStorageState, clearLocalStorage];
};

export default useLocalStorageState;
