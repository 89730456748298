import React from 'react';
// import OnlineReportsApiClient from '../../OnlineReports/OnlineReportsApiClient';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
// import { EventsList } from '../../../core/lists/EventsList';
import { IOnlineReportsFolder } from '../types/IOnlineReportsFolder';
// import { IModuleEventSubscription } from '../../../core/types/IModuleEventSubscription';
// import ModuleEventSubscriber from '../../../core/helpers/ModuleEventSubscriber';
import TranslationService from '../../../core/services/TranslationService';
// import InfoMessageService from '../../../core/services/InfoMessageService';
import { IOnlineReport } from '../types/IOnlineReport';
// import { IOnlineReportsVm } from '../types/IOnlineReportsVm';
import { IScheduleData } from './../types/IScheduleData';
// import Loader from '../../../core/components/Loading/Loader';
import Input from '../../../core/components/Forms/Input';
import { Typography, TypographyVariants } from '../../../core/components/Typography/Typography';
import Select from '../../../core/components/Forms/Select';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';

interface IProps {
  folders: IOnlineReportsFolder[];
  data?: IScheduleData;
  moduleId: number;
  updateData: (data: {
    reportViewerUrl?: string;
    formData?: {
      folder?: IOnlineReportsFolder;
      report?: IOnlineReport;
    };
  }) => void;
  goToNextStep?: () => void;
}

interface IState {
  formData: {
    folder: IOnlineReportsFolder;
    report?: IOnlineReport;
  };
}

class ReportType extends React.PureComponent<IProps, IState> {
  public state: IState = {
    formData: {
      folder: {
        id: '',
        name: '',
        reports: [],
      },
    },
  };

  // private moduleEvents: IModuleEventSubscription[];

  componentDidMount() {
    // this.registerModuleEvents();
    // this.fetchData();
  }

  // componentWillUnmount() {
  //     ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
  // }

  render() {
    return (
      <>
        <Typography variant={TypographyVariants.Paragraph}>
          {TranslationService.translateModule('LabelSelectTypeReport', ModuleNamesList.OnlineReports)}
        </Typography>

        <div className="mb-3">
          <Input>
            <Input.RadioWrapper>
              {this.props.folders.map((folder) => {
                const name = folder.name.replace(/ /g, '');

                return (
                  <Input.Radio
                    key={folder.id}
                    id={`${name}${folder.id}`}
                    variant={Input.RadioVariants.Secondary}
                    label={folder.name}
                    name="raportFolders"
                    value={folder.name}
                    onChange={() => this.selectFolderReport(folder)}
                  />
                );
              })}
            </Input.RadioWrapper>
          </Input>
        </div>

        <div className="row">
          <div className="mb-3 col-auto col-sm-6">
            <Select>
              <>
                <Select.Label>
                  <Typography variant={TypographyVariants.Paragraph}>
                    {TranslationService.translateModule('LabelSelectReport', ModuleNamesList.OnlineReports)}
                  </Typography>
                </Select.Label>

                <Select.Options
                  name="reportFolders"
                  id="reportFolders"
                  disabled={!this.state.formData.folder.reports.length}
                  options={this.state.formData.folder.reports}
                  onChange={(option: any) => this.selectTypeReport(option)}
                />
              </>
            </Select>
          </div>
        </div>

        <div className={`c-creator__footer c-creator__footer--btn-action c-creator__footer--right`}>
          <Button
            label={TranslationService.translate('Next')}
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTON_SIZE.MD}
            onClick={this.props.goToNextStep}
          />
        </div>
      </>
    );
  }

  selectTypeReport(report: IOnlineReport) {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          report,
        },
      },
      () => {
        this.props.updateData({
          formData: this.state.formData,
        });
      }
    );
  }

  selectFolderReport(folder: IOnlineReportsFolder) {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          folder,
          report: folder.reports[0],
        },
      },
      () => {
        this.props.updateData({
          formData: this.state.formData,
        });
      }
    );
  }

  // fetchData() {
  //     OnlineReportsApiClient.getReports(this.props.moduleId)
  //         .then(this.prepareData)
  //         .catch(this.fetchFailed);
  // }

  // prepareData = (vm: IOnlineReportsVm) => {
  //     this.setState({
  //         isDataLoading: false,
  //     });

  //     this.props.updateData({
  //         reportViewerUrl: vm.reportViewerUrl
  //     })
  // }

  // fetchFailed = () => {
  //     InfoMessageService.error(TranslationService.translate('ErrorLoadingData'));

  //     this.setState({
  //         isDataLoading: false
  //     });
  // }

  // registerModuleEvents() {
  //     this.moduleEvents = [
  //         { name: EventsList.CHANGED_CURRENT_CREDITOR, callback: this.fetchData }
  //     ];

  //     ModuleEventSubscriber.registerEvents(this.moduleEvents);
  // }
}

export default ReportType;
