import { AutoOnboardingTokenStatus, IAutoOnboardingTokenData } from '../types/IAutoOnboardingTokenData';
import RestApiClient from './RestApiClient';

class AutoOnboardingApiClient {
  public async GetOnboardingInitialData(token: string): Promise<IAutoOnboardingTokenData> {
    return await RestApiClient.get<IAutoOnboardingTokenData>(
      `modules/AutoOnboardingModule/loadOnBoardingTokenData/${token}`
    );
  }
  public async ShouldRegisterNewCreditor(token: string): Promise<boolean> {
    return await RestApiClient.get<boolean>(`modules/AutoOnboardingModule/${token}/shouldRegisterCreditor`);
  }
  public async UpdateOnboardingTokenStatus(token: string, status: AutoOnboardingTokenStatus): Promise<boolean> {
    return await RestApiClient.post<boolean>(`modules/AutoOnboardingModule/${token}/update`, status);
  }
}

export default new AutoOnboardingApiClient();
