import RestApiClient from '../../core/api/RestApiClient';
import { IComponentTable } from '../../core/types/IComponentTable';
import { IActionStatus } from '../../core/api/types/IActionStatus';

class CreditorsVerificationApiClient {
  public async getCreditorsTable(moduleInstanceId: number): Promise<IComponentTable> {
    return await RestApiClient.get<IComponentTable>(
      `modules/CreditorsVerification/getNotVerifiedCreditorsTable/${moduleInstanceId}`,
      null,
      null,
      true
    );
  }

  public async makeVerified(organizationNumber: string, moduleInstanceId: number): Promise<IActionStatus> {
    return await RestApiClient.get<IActionStatus>(
      `modules/CreditorsVerification/makeVerified/${organizationNumber}/${moduleInstanceId}`
    );
  }

  public async makeDeclined(organizationNumber: string): Promise<IActionStatus> {
    return await RestApiClient.get<IActionStatus>(`modules/CreditorsVerification/makeDeclined/${organizationNumber}`);
  }
}

export default new CreditorsVerificationApiClient();
