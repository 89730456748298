import React, { useState, useCallback, useRef } from 'react';
import Loader from '../../core/components/Loading/Loader';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';

type Props = {
  moduleName: string;
  options: OptionsType;
  openDirectPaymentModal: () => void;
  openWithdrawCaseModal: () => void;
  openPrintCaseModal: () => void;
  uploadFile: (file: File) => Promise<void>;
  openUserCaseNoteModal: () => void;
};

type OptionsType = {
  showDirectPaymentButton?: boolean;
  showWithdrawCaseButton?: boolean;
  showPrintCaseButton?: boolean;
  showUserCaseNote?: boolean;
  showUploadFileButton?: boolean;
};

export const CaseSummaryActions = (props: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleOpenDirectPaymentModal = useCallback(() => {
    props.openDirectPaymentModal();
  }, []);

  const handleOpenWithdrawCaseModal = useCallback(() => {
    props.openWithdrawCaseModal();
  }, []);

  const handleOpenPrintCaseModal = useCallback(() => {
    props.openPrintCaseModal();
  }, []);

  const handleOpenUserCaseNoteModal = useCallback(() => {
    props.openUserCaseNoteModal();
  }, []);

  const handleOnUploadClick = useCallback(() => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const handleOnUploadChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      if (event.currentTarget.files.length > 5) {
        InfoMessageService.error(
          TranslationService.translateModule('MaximumNumberOfUploadedFilesError', ModuleNamesList.CaseSummary)
        );
        if (fileInputRef && fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else {
        setIsUploading(true);

        await props.uploadFile(event.currentTarget.files[0]);
        if (fileInputRef && fileInputRef.current) {
          fileInputRef.current.value = '';
        }

        setIsUploading(false);
      }
    }
  }, []);

  return (
    <section className="case-summary__actions">
      {props.options.showDirectPaymentButton && (
        <div className="case-summary__action-btn">
          <button className={'c-btn c-btn--1 c-btn--xl'} id="directPayment" onClick={handleOpenDirectPaymentModal}>
            <i className="fas fa-credit-card" />
            {TranslationService.translateModule('DirectPaymentCreditNote', props.moduleName)}
          </button>
        </div>
      )}

      {props.options.showWithdrawCaseButton && (
        <div className="case-summary__action-btn">
          <button className={'c-btn c-btn--1 c-btn--xl'} id="directPayment" onClick={handleOpenWithdrawCaseModal}>
            <i className="fas fa-ban" />
            {TranslationService.translateModule('WithdrawCase', props.moduleName)}
          </button>
        </div>
      )}

      {props.options.showPrintCaseButton && (
        <div className="case-summary__action-btn">
          <button className={'c-btn c-btn--1 c-btn--xl'} id="printCase" onClick={handleOpenPrintCaseModal}>
            <i className="fas fa-print" />
            {TranslationService.translateModule('PrintCase', props.moduleName)}
          </button>
        </div>
      )}

      {props.options.showUserCaseNote && (
        <div className="case-summary__action-btn">
          <button className={'c-btn c-btn--1 c-btn--xl'} id="printCase" onClick={handleOpenUserCaseNoteModal}>
            <i className="far fa-sticky-note" />
            {TranslationService.translateModule('UserCaseNote', props.moduleName)}
          </button>
        </div>
      )}

      {props.options.showUploadFileButton && (
        <div className="case-summary__action-btn">
          <button className="c-btn c-btn--1 c-btn--xl" disabled={isUploading} onClick={handleOnUploadClick}>
            {isUploading && <Loader main={false} opacity={0.5} small={true} />}
            <i className="fas fa-upload" />
            {TranslationService.translateModule('UploadFile', props.moduleName)}
          </button>

          <input
            type="file"
            id="file-input"
            ref={fileInputRef}
            onChange={handleOnUploadChange}
            multiple={true}
            style={{ display: 'none' }}
          />
        </div>
      )}
    </section>
  );
};
