import RestApiClient from '../../../core/api/RestApiClient';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IGroupUser } from '../types/IGroupUser';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

class GroupUsersListApiClient {
  public async getGroupUsersTable(moduleInstanceId: number): Promise<IComponentTable<IGroupUser>> {
    return await RestApiClient.get<IComponentTable<IGroupUser>>(
      `modules/GroupUsersList/getGroupUsers/${moduleInstanceId}`
    );
  }

  public async removeUser(userUId: string): Promise<IActionStatus> {
    return await RestApiClient.get<IActionStatus>(`modules/GroupUsersList/removeUser/${userUId}`);
  }
}

export default new GroupUsersListApiClient();
