import RestApiClient from '../../../core/api/RestApiClient';
import { ISelectListModuleDetails } from '../types/ISelectListModuleDetails';
import { IModuleSetting } from '../types/IModuleSetting';
import { INumberDictionary } from '../../../core/types/IDictionary';
import { IActionStatus } from '../../../core/api/types/IActionStatus';
import { IUpdateModuleSettingRequest } from '../types/IUpdateModuleSettingRequest';

class ModuleSettingsApiClient {
  public async getTransferableInvoicesTable(moduleInstanceId: number): Promise<ISelectListModuleDetails[]> {
    return await RestApiClient.get<ISelectListModuleDetails[]>(
      `modules/ModuleSettings/getAvailableModules/${moduleInstanceId}`,
      null,
      null,
      true
    );
  }
  public async loadModuleSettings(moduleId: number): Promise<INumberDictionary<IModuleSetting[]>> {
    return await RestApiClient.get<INumberDictionary<IModuleSetting[]>>(
      `modules/ModuleSettings/getModuleSettings/${moduleId}`
    );
  }
  public async updateSetting(request: IUpdateModuleSettingRequest): Promise<IActionStatus> {
    return await RestApiClient.post<IActionStatus>(`modules/ModuleSettings/updateSetting`, request);
  }
  public async updateComplexSetting(moduleSetting: IModuleSetting): Promise<IActionStatus> {
    return await RestApiClient.post<IActionStatus>(`modules/ModuleSettings/updateComplexSettingValues`, moduleSetting);
  }
}

export default new ModuleSettingsApiClient();
