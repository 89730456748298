import RestApiClient from '../../../core/api/RestApiClient';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IActivitiesListRequest } from '../types/IActivitiesListRequest';
import { IActivityListElement } from '../types/IActivityListElement';

class ActivitiesListApi {
  public async getActivities(request: IActivitiesListRequest): Promise<IComponentTable<IActivityListElement>> {
    return await RestApiClient.post<IComponentTable>('modules/ActivitiesListModule/getActivities', request);
  }
}

export default new ActivitiesListApi();
