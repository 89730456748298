import RestApiClient from '../../../core/api/RestApiClient';
import { MessageTemplateModel } from '../types/MessageTemplateModel';
import { MessageTemplatesModel } from './MessageTemplatesModel';
import { IActionStatusTyped } from '../../../core/api/types/IActionStatusTyped';
import { MessageTemplateValidationResult } from '../types/MessageTemplateValidationResult';

export default {
  getMessageTemplatesCodes: async (): Promise<string[]> => {
    return await RestApiClient.get<string[]>('modules/MessageTemplatesManagement', null, null, true);
  },

  getMessageTemplatesByCode: async (code: string): Promise<MessageTemplatesModel> => {
    return await RestApiClient.get<MessageTemplatesModel>(`modules/MessageTemplatesManagement/${code}`);
  },

  saveMessageTemplate: async (request: MessageTemplateModel) => {
    return await RestApiClient.post<IActionStatusTyped<MessageTemplateValidationResult>>(
      'modules/MessageTemplatesManagement',
      request
    );
  },

  validateMessageTemplate: async (request: MessageTemplateModel) => {
    return await RestApiClient.post<IActionStatusTyped<MessageTemplateValidationResult>>(
      'modules/MessageTemplatesManagement/validate',
      request
    );
  },
};
