import moment from 'moment';

class ScheduleReportService {
  setTimePicker = (startTimeHours: string, startTimeMinutes: string) => {
    const currentTime = moment();
    let computedTime = currentTime.add(10, 'minutes');
    const currentMinute = computedTime.minute();
    const moduloValue = 5;
    const moduloRest = currentMinute % moduloValue;

    if (startTimeHours && startTimeMinutes) {
      computedTime = moment().hour(parseInt(startTimeHours)).minute(parseInt(startTimeMinutes));
    } else if (moduloRest > 0) {
      const minutesToAdd = moduloValue - moduloRest;

      computedTime = computedTime.add(minutesToAdd, 'minutes');
    }

    return computedTime;
  };
}

export default new ScheduleReportService();
