import React from 'react';
import TranslationService from '../../core/services/TranslationService';
import CurrencyParser from '../../core/helpers/CurrencyParser';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';

type Props = {
  moduleName: string;
  currency: string;
  caseSummary: any;
};

export const CaseSummaryPayments = (props: Props) => {
  return (
    <section className="case-summary__payments">
      <div className="case-summary__payments-box c-box c-box--1">
        <div className="c-box__amount">
          <span className="c-box__amount-value case-summary__payments-box-amount">
            {CurrencyParser.toLocaleString(props.caseSummary.originalCapital)}
          </span>
          <span className="c-box__currency">{props.currency}</span>
        </div>
        <div className="c-box__desc">{TranslationService.translateModule('OriginalClaim', props.moduleName)}</div>
      </div>

      <div className="case-summary__payments-box c-box c-box--2">
        <div className="c-box__amount">
          <span className="c-box__amount-value case-summary__payments-box-amount">
            {CurrencyParser.toLocaleString(props.caseSummary.paidCapital)}
          </span>
          <span className="c-box__currency">{props.currency}</span>
        </div>
        <div className="c-box__desc">{TranslationService.translateModule('PaymentOnCase', props.moduleName)}</div>
      </div>

      <div className="case-summary__payments-box c-box c-box--6">
        <div className="c-box__amount">
          <span className="c-box__amount-value case-summary__payments-box-amount">
            {CurrencyParser.toLocaleString(props.caseSummary.balanceCapital)}
          </span>
          <span className="c-box__currency">{props.currency}</span>
        </div>
        <div className="c-box__desc">{TranslationService.translateModule('PendingPayment', props.moduleName)}</div>
      </div>

      <div className="case-summary__payments-box c-box c-box--3">
        <div className="c-box__amount">
          <span className="c-box__amount-value case-summary__payments-box-amount">
            {CurrencyParser.toLocaleString(props.caseSummary.totalPayable)}
          </span>
          <span className="c-box__currency">{props.currency}</span>
        </div>
        <div className="c-box__desc">
          {TranslationService.translateModule('TotalPayable', ModuleNamesList.CaseSummary)}
        </div>
      </div>
    </section>
  );
};
