export enum UnitCasesColumns {
  CaseNo,
  CaseReference,
  RegDate,
  CloseDate,
  CaseProgress,
  CreditorName,
  DebtorName,
  OriginalClaim,
  TotalBalance,
  PaidBalance,
  RemainBalance,
}
