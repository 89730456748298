import React from 'react';
import { IModule } from '../api/types/IModule';
import ModuleList from '../lists/ModuleList';
import { ParsedQuery } from 'query-string';
import StyleWrapper from '../../core/components/StyleWrapper/StyleWrapperHOC';

const MissingModule = () => <div>[missing module]</div>;
const ModuleNotFound = () => <div>[module not found]</div>;

interface IExtendedProps extends IProps {
  [key: string]: any;
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type SingleModuleRelevantProps = Omit<IProps, 'modules' | 'moduleIndex'>;

interface IProps {
  modules: IModule[];
  moduleIndex: number;
  routeParameters: ParsedQuery;
}

const styleConfig = [
  {
    searchMethod: 'element',
    instructions: {
      filter: '.l-module .l-module__section.l-module__section--filter',
      title: '.l-module .l-module__section.l-module__section--head',
    },
  },
];

export const ModuleFactory = StyleWrapper(
  'module:section',
  styleConfig
)((props: IExtendedProps) => {
  if (!props.modules || props.modules.length === 0) {
    return <MissingModule />;
  }

  if (props.modules.length <= props.moduleIndex) {
    return <MissingModule />;
  }

  const module = props.modules[props.moduleIndex];
  if (!module) {
    return <MissingModule />;
  }

  const Module = ModuleList[module.name];
  return Module ? <Module module={module} {...(props as SingleModuleRelevantProps)} /> : <ModuleNotFound />;
});
