import WithdrawCaseApi from '../api/WithdrawCaseApi';
import { IWithdrawCaseRequest } from '../types/IWithdrawCaseRequest';

class WithdrawCaseService {
  public async withdrawCase(request: IWithdrawCaseRequest) {
    return await WithdrawCaseApi.withdrawCase(request);
  }
}

export default new WithdrawCaseService();
