import RestApiClient from './RestApiClient';
import { IUserCreditorRoles } from '../types/IUserCreditorRoles';

class UserCreditorRolesApiClient {
  public async getUserCreditorRoles(): Promise<IUserCreditorRoles> {
    return await RestApiClient.get<IUserCreditorRoles>('UserCreditors/Roles');
  }
}

export default new UserCreditorRolesApiClient();
