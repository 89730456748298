import DirectPaymentApi from '../api/DirectPaymentApi';
import { IInvoicesResult } from '../types/IInvoicesResult';
import { IInvoicesListWithPaymentMethod } from '../types/IInvoicePayment';
import { PaymentMethod } from '../types/PaymentMethod';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

class DirectPaymentService {
  public async getCaseInvoices(caseId: string, moduleInstanceId: number): Promise<IInvoicesResult> {
    return await DirectPaymentApi.getCaseInvoices(caseId, moduleInstanceId);
  }
  public async savePayment(moduleInstanceId: number, data: IInvoicesListWithPaymentMethod): Promise<IActionStatus> {
    if (data.paymentMethod === PaymentMethod.DirectPayment) {
      return await DirectPaymentApi.saveDirectPayment(moduleInstanceId, {
        caseId: data.caseId,
        paymentData: {
          currency: data.currency,
          automaticDistribution: data.automaticDistribution,
          amount: data.amount,
          paymentReceivedOn: data.paymentReceivedOn,
          data: [...data.invoices.map((i) => ({ invoiceId: i.id, order: i.order }))],
        },
      });
    } else {
      return await DirectPaymentApi.saveCreditNotePayment(moduleInstanceId, {
        caseId: data.caseId,
        paymentData: {
          paymentNote: data.creditNoteNumber
            ? `${TranslationService.translateModule(
                'CreditNoteNo',
                ModuleNamesList.DirectPayment
              )}:${data.creditNoteNumber}`
            : TranslationService.translateModule('CreditNote', ModuleNamesList.DirectPayment),
          data: [...data.invoices.map((i) => ({ invoiceId: i.invoiceNumber, amount: i.payAmount }))],
        },
      });
    }
  }
}

export default new DirectPaymentService();
