import * as React from 'react';
import { ModuleFactory } from '../factories/ModuleFactory';
import { IContainerProps } from '../../core/types/IContainerProps';

export const SkinContainer = (props: IContainerProps) => {
  const container = props.container;

  if (!container) {
    return <div>[missing container]</div>;
  }

  return (
    <div>
      <ModuleFactory modules={container.modules} moduleIndex={0} routeParameters={props.routeParameters} />
      <ModuleFactory modules={container.modules} moduleIndex={1} routeParameters={props.routeParameters} />
    </div>
  );
};
