import * as React from 'react';
import LogoBanner from '../../../modules/LogoBanner/LogoBanner';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../components/Button/Button';
import RouteParamsService from '../../services/RouteParamsService';
import TranslationService from '../../services/TranslationService';
import { ISkinProps } from '../../types/ISkinProps';
import './AutoOnboardingUserMessageSkin.scss';

class AutoOnboardingUserMessageSkin extends React.Component<ISkinProps> {
  public GoToHomePage() {
    const queryString = RouteParamsService.getQueryParameters();
    let redirectUrl = '/';
    if (queryString['token']) {
      redirectUrl += 'token=' + queryString['token'];
    }

    window.location.href = redirectUrl;
  }

  public render() {
    return (
      <div className="error-container">
        <LogoBanner />

        <div className="error-window">
          <p className="error-window-title">{TranslationService.translate('AutoOnboardingUserMessagePageTitle')}</p>

          <p className="error-window-content">{TranslationService.translate('AutoOnboardingUserMessagePageContent')}</p>

          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTON_SIZE.SM}
            type="button"
            label="GO HOME"
            onClick={() => {
              this.GoToHomePage();
            }}
            icon={{ className: 'fas fa-home', position: 'left' }}
          />
        </div>
      </div>
    );
  }
}

export default AutoOnboardingUserMessageSkin;
