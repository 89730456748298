export enum SettingsType {
  String = 1,
  Int = 2,
  Bool = 3,
  HtmlString = 4,
  TextArea = 5,
  Table = 6,
  Select = 7,
  Url = 8,
  ColorPicker = 9,
  ColorPickerArray = 10,
  Dictionary = 11,
  PageInternal = 12,
  File = 13,
}
