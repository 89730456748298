import React from 'react';
import './_CookieDisclaimer.scss';
import TranslationService from '../../core/services/TranslationService';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../reducers/IStore';

interface IProps {
  applicationSettings: any;
}

const CookieDisclaimer = (props: IProps) => {
  const [closed, setClosed] = useState('');

  useEffect(() => {
    setClosed(localStorage.getItem('cookied_disclaimer_closed') as string);
  }, []);

  function handleClick() {
    localStorage.setItem('cookied_disclaimer_closed', 'true');
    setClosed(localStorage.getItem('cookied_disclaimer_closed') as string);
  }

  return (
    <>
      {closed !== 'true' && props.applicationSettings.IsCookieDisclaimerVisibe === 'true' && (
        <div className="cookied">
          <div className="cookied__body">
            <div className="cookied__cookieicon">
              <i className="fas fa-cookie-bite fa-2x" />
            </div>
            <div className="cookied__cookietext">{TranslationService.translate('CookieDisclaimer')}</div>
            <div className="cookied__closeicon">
              <i className="far fa-window-close" onClick={handleClick} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  applicationSettings: state.applicationSettings,
});

export default connect(mapStateToProps)(CookieDisclaimer);
