import { IComponentTable } from '../../core/types/IComponentTable';

class ComponentTableHelper {
  public addOrUpdateRow(originalTable: IComponentTable, itemElement: any, compareKey: string) {
    const table = { ...originalTable };
    const tableElement = table.values.find((item) => item[compareKey] === itemElement[compareKey]);
    let index = table.values.indexOf(tableElement);
    if (index < 0) {
      index = table.values.length;
    }
    table.values[index] = { ...itemElement };
    return table;
  }

  public removeElement(originalTable: IComponentTable, itemElement: any, compareKey: string) {
    const table = { ...originalTable };
    const tableElement = table.values.find((item) => item[compareKey] === itemElement[compareKey]);
    const index = table.values.indexOf(tableElement);
    table.values.splice(index, 1);
    return table;
  }
}

export default new ComponentTableHelper();
