import * as React from 'react';
import Loader from '../../core/components/Loading/Loader';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import { IComponentTable, emptyComponentTable } from '../../core/types/IComponentTable';
import { IModuleProps } from '../../core/types/IModuleProps';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import NotesListTableConfigurator from './helpers/NotesListTableConfigurator';
import NotesListService from './services/NotesListService';
import { INotesListElement } from './types/INotesListElement';

interface IState {
  notesTable: IComponentTable<INotesListElement>;
  isDataLoading: boolean;
}

export default class NotesList extends React.Component<IModuleProps, IState> {
  public state: IState = {
    notesTable: { ...emptyComponentTable },
    isDataLoading: false,
  };

  public async componentDidMount() {
    await this.fetchNotes();
  }

  public render() {
    return (
      <div className="notes-list position-relative mt-4">
        <section className={`l-module__section ${this.state.isDataLoading ? 'l-module__section--loading' : ''}`}>
          {this.state.isDataLoading ? <Loader opacity={0.5} /> : null}
          <BootstrapTableContainer
            remote={false}
            wrapperClasses="bt"
            paginationProps={{}}
            classes="bt__table bt-table"
            keyField="id"
            data={this.state.notesTable.values}
            columns={
              this.state.notesTable.columns.length === 0
                ? []
                : NotesListTableConfigurator.getNotesListTableColumns(this.state.notesTable)
            }
          />
        </section>
      </div>
    );
  }

  private fetchNotes = async () => {
    this.setState({
      isDataLoading: true,
    });

    try {
      const { id } = this.props.routeParameters;
      const request = {
        caseId: id as string,
        moduleInstanceId: this.props.module.id,
      };

      const data = await NotesListService.getNotes(request);
      this.setState({
        notesTable: data,
        isDataLoading: false,
      });
    } catch {
      InfoMessageService.error(TranslationService.translateModule('ErrorLoadingNotes', this.props.module.name));
      this.setState({
        isDataLoading: false,
      });
    }
  };
}
