import * as React from 'react';
import { IMessageProps } from '../types/IMessageProps';
import { IMessageType } from '../enums/IMessageType';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import DateTimeParser from '../../../core/helpers/DateTimeParser';

class Message extends React.Component<IMessageProps> {
  public trySendAgain = async () => {
    await this.props.sendMessageAgain(this.props.message);
  };

  public render() {
    return (
      <li
        className={
          this.props.isMessageSending === true || this.props.isMessageNotSend === true ? ' message-action-active' : ''
        }
      >
        <div className={'message-item' + (this.props.isMessageNotSend === true ? ' not-send' : '')}>
          <div
            className={'message-content' + (this.props.message.type === IMessageType.Received ? ' received' : ' send')}
          >
            <div className="row mb-2">
              <div className="col-12">
                <div className="message-sender-details">{this.props.message.senderDetails}</div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12">{this.props.message.content}</div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="date">{DateTimeParser.toLocaleDateString(this.props.message.createDate)}</div>
              </div>
            </div>
          </div>
        </div>
        {this.props.isMessageSending === true ? (
          <div className="spinner-border spinner-border-sm text-success" role="status">
            <span className="sr-only" />
          </div>
        ) : (
          ''
        )}
        {this.props.isMessageNotSend === true ? (
          <>
            <div className="error-icon" role="status">
              <i className="fas fa-exclamation-triangle" />
            </div>
            <span className="error-link" onClick={this.trySendAgain}>
              {TranslationService.translateModule('MessageNotSent', ModuleNamesList.Messages)}
            </span>
          </>
        ) : (
          ''
        )}
      </li>
    );
  }
}

export default Message;
