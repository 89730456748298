export interface IGuarantor {
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  type: GuarantorType;
}

export enum GuarantorType {
  Guarantor = 1,
  CoOwner = 2,
}
