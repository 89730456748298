import RestApiClient from '../../../../core/api/RestApiClient';
import { CreditorContactInfoType } from '../types/CreditorContactInfo';
import { OrderProductType } from '../types/OrderProduct';
import { ProductType } from '../types/Product';
import { IActionStatus } from 'core/api/types/IActionStatus';

class OrderPaperApi {
  public async GetCreditorContact(creditorId: string): Promise<CreditorContactInfoType> {
    return await RestApiClient.get<CreditorContactInfoType>(
      `modules/OrderPaperModule/getCreditorContact/${creditorId}`
    );
  }

  public async OrderPaper(orderPaper: OrderProductType, moduleId: number) {
    return await RestApiClient.post<IActionStatus>(`modules/OrderPaperModule/orderPaper/${moduleId}`, orderPaper);
  }

  public async GetProducts(moduleId: number) {
    return await RestApiClient.get<ProductType[]>(`modules/OrderPaperModule/getProducts/${moduleId}`);
  }
}

export default new OrderPaperApi();
