import * as React from 'react';
import { ErrorMessage } from 'formik';
import { IFormGroupDatePickerProps } from '../../types/IFormGroupDatePickerProps';
import DatePicker from './DatePicker';

class FormGroupDatePicker extends React.Component<IFormGroupDatePickerProps> {
  public getErrorClass() {
    const properties: string[] = this.props.fieldName.split('.');
    if (properties.length === 1) {
      return this.props.errors[this.props.fieldName] && this.props.touched[this.props.fieldName] ? 'error' : '';
    }

    let errorProperty = this.props.errors[properties[0]];
    let touchProperty = this.props.touched[properties[0]];
    if (!errorProperty || !touchProperty) {
      return '';
    }

    let iter = 1;
    while (iter < properties.length) {
      errorProperty = (errorProperty as any)![properties[iter]];
      touchProperty = (touchProperty as any)![properties[iter]];
      if (!errorProperty || !touchProperty) {
        return '';
      }
      iter++;
    }

    if (errorProperty && touchProperty) {
      return 'error';
    }
    return '';
  }

  public handleChange = (date: Date) => {
    const changeEvent = {} as React.ChangeEvent<any>;
    changeEvent.target = {};
    changeEvent.target.value = date;
    changeEvent.target.name = this.props.fieldName;
    changeEvent.target.id = this.props.fieldName;
    this.props.handleChange(changeEvent);
  };

  public render() {
    return (
      <div className="c-control row row--aligned">
        <label
          className={(this.props.labelClass ? this.props.labelClass : 'col-sm-3') + ' col-form-label c-control__label'}
          htmlFor={this.props.fieldName}
        >
          {this.props.label}

          {this.props.required && <span className="c-control__required-sign"></span>}
        </label>

        <div className={this.props.inputContainerClass ? this.props.inputContainerClass : 'col-sm-9'}>
          <DatePicker
            onChange={this.handleChange}
            inputId={this.props.fieldName}
            inputName={this.props.fieldName}
            className={this.getErrorClass()}
            value={this.props.value}
          />

          <ErrorMessage name={this.props.fieldName} component="div" className="error error-message" />
        </div>
      </div>
    );
  }
}

export default FormGroupDatePicker;
