import * as React from 'react';
import TranslationService from '../../../core/services/TranslationService';

export const ActionButtons = (props: { onSave?: () => Promise<void>; onCancel?: () => void }) => {
  return (
    <div className="iddp__action-buttons">
      {props.onSave ? (
        <button type="button" className="btn btn-default" onClick={props.onSave}>
          <i className="fas fa-save" /> {TranslationService.translate('Save')}
        </button>
      ) : null}
      {props.onCancel ? (
        <button type="button" className="btn btn-default btn-cancel" onClick={props.onCancel}>
          <i className="fas fa-times" /> {TranslationService.translate('Cancel')}
        </button>
      ) : null}
    </div>
  );
};
