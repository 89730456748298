import React, { useEffect, useState } from 'react';
import { ButtonType } from './ButtonType';
import classNames from 'classnames';

export const BUTTON_VARIANTS = {
  PRIMARY: 'c-btn--primary',
  SECONDARY: 'c-btn--secondary',
  LINK: 'c-btn--link',
  TRANSPARENT: 'c-btn--transparent',
  DANGER: 'c-btn--danger',
  DELETE: 'c-btn--delete',
  ICON: 'c-btn--icon',
};

export const BUTTON_SIZE = {
  LG: 'c-btn--lg',
  MD: 'c-btn--md',
  SM: 'c-btn--sm',
  XS: 'c-btn--xs',
};

export const BUTTON_COLORS = {
  PRIMARY: 'c-btn--color-primary',
  SECONDARY: 'c-btn--color-secondary',
};

export const Button = ({
  id = '',
  className = '',
  variant = BUTTON_VARIANTS.SECONDARY,
  color = BUTTON_COLORS.SECONDARY,
  size = BUTTON_SIZE.MD,
  disabled = false,
  showLoader = false,
  type = 'submit',
  icon = {
    className: '',
    position: '',
  },
  ...props
}: ButtonType) => {
  const primaryClassName = 'c-btn';
  const [showIcon, setShowIcon] = useState(false);

  const buttonClass = classNames(
    primaryClassName,
    variant,
    color,
    size,
    className,
    { 'c-btn--loader': showLoader },
    { 'c-btn--disabled': disabled }
  );

  const handleClick = (e: any) => {
    if (props.onClick && !disabled && !showLoader) {
      props.onClick(e);
    }
  };

  const init = () => {
    if (icon.className.length) {
      setShowIcon(true);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [disabled]);

  return (
    <button type={type} id={id} disabled={disabled} className={buttonClass} onClick={handleClick}>
      {props.children ? (
        props.children
      ) : showIcon ? (
        <>
          <i className={icon.className} /> {props.label}
        </>
      ) : (
        props.label
      )}

      {showLoader && <div className="c-btn__loader spinner-border spinner-border-sm" role="status" />}
    </button>
  );
};
