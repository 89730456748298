import UserCreditorRolesApiClient from '../api/UserCreditorRolesApiClient';
import { IUserCreditorRoles } from '../types/IUserCreditorRoles';

class UserCreditorRolesService {
  public async getUserCreditorRoles(): Promise<IUserCreditorRoles> {
    return await UserCreditorRolesApiClient.getUserCreditorRoles();
  }
}

export default new UserCreditorRolesService();
