import React from 'react';
import { Table } from 'reactstrap';

type Props = {
  children: any;
  headers?: string[];
  firstHeaderEmpty?: boolean;
  className?: string;
  striped?: boolean;
  headersClassName?: string;
};

export default (props: Props) => (
  <Table responsive striped={props.striped ? props.striped : false} className={props.className}>
    {props.headers ? (
      <thead>
        <tr>
          {props.firstHeaderEmpty ? <th key="empty_th"></th> : null}
          {props.headers.map((h: string, index: number) => (
            <th
              key={`table_header_${index}`}
              className={`${typeof props.headersClassName !== 'undefined' ? props.headersClassName : 'c-table__cell--text-right'}`}
            >
              {h}
            </th>
          ))}
        </tr>
      </thead>
    ) : null}
    <tbody className={props.headers ? '' : `${props.className} ${props.className}--fixed-body`}>{props.children}</tbody>
  </Table>
);
