import { DisputeType } from '../types/DisputeType';
import { CollectionNotice } from '../types/CollectionNotice';
import { CaseType } from '../types/CaseType';

export interface IClaimInfo {
  caseType: CaseType;
  collectionNoticeType: CollectionNotice;
  disputeType: DisputeType;
  selectedCreditor: string;
}

export const emptyClaimInfo: IClaimInfo = {
  caseType: CaseType.CollectionNotice,
  collectionNoticeType: CollectionNotice.No,
  disputeType: DisputeType.WithDispute,
  selectedCreditor: '',
};
