import RestApiClient from '../../../core/api/RestApiClient';
import { ICasePrintDetails } from '../types/ICasePrintDetails';

class PrintCaseApiClient {
  public async getPrintCaseData(caseId: string): Promise<ICasePrintDetails> {
    return await RestApiClient.get<ICasePrintDetails>(`modules/PrintCase/${caseId}`);
  }
}

export default new PrintCaseApiClient();
