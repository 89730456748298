import React from 'react';
import { Formik, Form, Field } from 'formik';
import TranslationService from './../../../core/services/TranslationService';
import { IScheduleData } from '../../Reports/types/IScheduleData';
import InputStringArray from '../../Reports/views/InputStringArray';
import Loader from '../../../core/components/Loading/Loader';
import { ItemListType } from '../../Reports/types/ItemListType';
import { IDeliveryFormatVM } from '../types/IDeliveryFormatVM';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';

interface IProps {
  initData: IDeliveryFormatVM[];
  data: IScheduleData;
  setScheduleOutputdata: (data: IScheduleData) => void;
  goToNextStep?: () => void;
  goToPreviousStep?: () => void;
}

interface IState {
  isLoading: boolean;
  outputData: IScheduleData;
  listInputs: ItemListType[];
  formData: {};
}

class ScheduleFormats extends React.PureComponent<IProps, IState> {
  public state: IState = {
    isLoading: true,
    outputData: this.props.data,
    listInputs: [],
    formData: {
      fileformat: '',
    },
  };

  componentDidMount() {
    this.prepareListInputs();
  }

  render() {
    return this.state.isLoading ? (
      <Loader />
    ) : (
      <Formik
        initialValues={this.state.formData}
        onSubmit={() => {
          this.handleFormSubmit();
        }}
      >
        {({ handleChange }) => {
          return (
            <Form>
              <div className="c-creator__box">
                <p>{TranslationService.translateModule('LabelSelectFormats', 'OnlineReports')}</p>

                <div className="row">
                  <div className="col-6">
                    <Field
                      name="fileformat"
                      validate={this.validateInputStringArray}
                      render={({ field, form }: any) => {
                        const error =
                          form.errors[field.name] && form.touched[field.name] ? form.errors[field.name] : false;

                        return (
                          <InputStringArray
                            error={error}
                            name={field.name}
                            value={field.value}
                            multiple={false}
                            listInputs={this.state.listInputs}
                            handleChange={(value: any) => {
                              const handleChangeEvent = {
                                target: {
                                  id: 'fileformat',
                                  name: 'fileformat',
                                  value,
                                },
                              } as React.ChangeEvent<any>;

                              handleChange(handleChangeEvent);

                              this.handleFormatsChange(value);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="c-creator__footer c-creator__footer--btn-action">
                <Button
                  type="button"
                  label={TranslationService.translate('Back')}
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTON_SIZE.MD}
                  onClick={this.props.goToPreviousStep}
                />

                <Button
                  label={TranslationService.translate('Next')}
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTON_SIZE.MD}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  validateInputStringArray = (value: string | []) => {
    return value && value.length > 0 ? null : TranslationService.translate('ValidationMessageMinSelection');
  };

  handleFormatsChange = (deliveryFormat: number[]) => {
    const format = deliveryFormat[0];

    this.setState({
      outputData: {
        ...this.state.outputData,
        deliveryFormat: format,
      },
    });
  };

  handleFormSubmit = () => {
    if (this.props.goToNextStep) {
      this.props.setScheduleOutputdata(this.state.outputData);
      this.props.goToNextStep();
    }
  };

  prepareListInputs = () => {
    const outputData = { ...this.state.outputData };
    const listInputsArray: ItemListType[] = this.props.initData.map((item: IDeliveryFormatVM) => {
      if (item.id === this.props.data.deliveryFormat) {
        outputData.deliveryFormat = item.id;
      }

      return {
        name: item.name,
        value: `${item.id}`,
      };
    });

    this.setState({
      isLoading: false,
      formData: { fileformat: `${outputData.deliveryFormat || ''}` },
      listInputs: listInputsArray,
      outputData,
    });
  };
}

export default ScheduleFormats;
