import React, { useEffect } from 'react';
import { UserManager } from 'oidc-client';

const OidcProvider = (props: { userManager: UserManager; children?: JSX.Element[] | JSX.Element | string | any }) => {
  useEffect(() => {
    props.userManager.events.addAccessTokenExpiring(accessTokenExpiring);
    props.userManager.events.addAccessTokenExpired(accessTokenExpired);

    return () => {
      props.userManager.events.removeAccessTokenExpiring(accessTokenExpiring);
      props.userManager.events.removeAccessTokenExpired(accessTokenExpired);
    };
    // eslint-disable-next-line
  }, []);

  const accessTokenExpired = () => {};

  const accessTokenExpiring = () => {};

  return React.Children.only(props.children);
};

export default OidcProvider;
