import React, { useState, useEffect } from 'react';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
// import Loader from '../../../core/components/Loading/Loader';
import { BootstrapTableContainer } from '../../../core/components/BootstrapTable/BootstrapTableContainer';
import withAbortRequest, { AbortRequestPropsType } from '../../../core/hoc/AbortRequest';
import {
  GetCreditorAliases,
  GetInitialSettings,
  AddOrUpdateCreditorAlias,
  RemoveCreditorAlias,
} from '../CreditorsManagmentApiClient';
import { IDictionary } from '../../../core/types/IDictionary';
import { Formik, Form } from 'formik';
// import { AliasType } from '../types/AliasType';
import { SettingsType } from '../../../core/Enums/SettingsType';
import { InputContainer } from '../../../core/components/Forms/InputContainer';
import { FORM_GROUP_VARIANTS, FormGroup } from 'core/components/Forms/FormGroup';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { Status } from '../../../core/api/Enums/Status';
import InfoMessageService from '../../../core/services/InfoMessageService';
import Loader from '../../../core/components/Loading/Loader';
// import SimpleInput from '../../../core/components/Forms/SimpleInput';
import InputField from '../../../core/components/InputField/InputField';
import { Typography, TypographyVariants } from '../../../core/components/Typography/Typography';
import { AliasType } from '../types/AliasType';
import { AliasValueType } from '../types/AliasValueType';

type CreditorType = {
  uId: string;
  domainId: string;
};

type Props = {
  creditor: CreditorType;
  moduleInstanceId: number;
  onClose: () => void;
};

type TableDataType = {
  id: number;
  name: string;
  value: AliasValueType[];
};

const ManageAliases = (props: Props & AbortRequestPropsType) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [aliasTypes, setAliasTypes] = useState<AliasType[]>([]);
  const [aliasesTypesOptions, setAliasesTypesOptions] = useState<any>([]);
  const [tableData, setTableData] = useState<TableDataType[]>([]);

  const tableColumns = [
    {
      dataField: 'id',
      text: '',
      hidden: true,
      sort: false,
    },
    {
      dataField: 'name',
      text: TranslationService.translateModule('LabelAliasName', ModuleNamesList.CreditorsManagment),
      sort: false,
      headerStyle: () => {
        return { width: '240px' };
      },
    },
    {
      dataField: 'value',
      text: TranslationService.translateModule('LabelAliasValue', ModuleNamesList.CreditorsManagment),
      sort: false,
      headerStyle: () => {
        return { width: '300px' };
      },
      formatter: (cell: any, element: TableDataType, rowIndex: any, formatterExtraData: any) => {
        return (
          <>
            {Object.keys(cell).map((c: any, index: number) => {
              const id = c.replace(/\s/g, '-');

              return (
                <FormGroup
                  key={index}
                  name={c}
                  label={c}
                  variant={FORM_GROUP_VARIANTS.INLINE}
                  render={(name) => (
                    <>
                      <InputField
                        id={`${id}${rowIndex}`}
                        name={name}
                        defaultValue={cell[c]}
                        onChange={() => {}}
                        onBlur={(event) => {
                          handleAliasBlur(event.target.value, name, element);
                        }}
                      />
                    </>
                  )}
                />
              );
            })}
          </>
        );
      },
      formatExtraData: { aliasTypes },
    },
    {
      dataField: 'actions',
      text: TranslationService.translateModule('Actions', ModuleNamesList.CreditorsManagment),
      sort: false,
      headerStyle: () => {
        return { width: '100px' };
      },
      formatter: (value: any, element: TableDataType) => {
        return (
          <Button
            id="DeleteAlias"
            variant={BUTTON_VARIANTS.SECONDARY}
            size={BUTTON_SIZE.SM}
            type="button"
            label={TranslationService.translate('Delete')}
            onClick={() => handleDeleteAlias(element.id)}
          />
        );
      },
    },
  ];

  const loadAliases = async () => {
    const initialSettings = await GetInitialSettings(props.moduleInstanceId, props.cancelTokenSource.token);
    const aliases = await GetCreditorAliases(props.creditor.domainId, props.cancelTokenSource.token);
    const tableData = aliases.map((alias) => {
      return {
        id: alias.id,
        name: alias.name,
        value: JSON.parse(alias.value),
      };
    });

    setTableData(tableData);
    setAliasTypes(initialSettings.aliasTypes);
    setIsLoading(false);
  };

  const setAliasesTypeOtions = () => {
    const options: IDictionary<string> = {};
    const tableDataNames = tableData.map((data) => data.name);

    aliasTypes.forEach((item) => {
      if (!tableDataNames.includes(item.name)) {
        options[item.id] = item.name;
      }
    });

    setAliasesTypesOptions(options);
  };

  const handleAliasBlur = async (value: string, name: string, element: any) => {
    let aliasTypeId;
    const newValue = { ...element.value };

    newValue[name] = value;

    for (let i = 0; i < aliasTypes.length; i++) {
      if (aliasTypes[i].name === element.name) {
        aliasTypeId = aliasTypes[i].id;
      }
    }

    const submitData = {
      aliasTypeId,
      value: JSON.stringify(newValue),
      creditorDomainId: props.creditor.domainId,
    };

    try {
      const result = await AddOrUpdateCreditorAlias(submitData);

      if (result.status === Status.Success) {
        InfoMessageService.success(
          TranslationService.translateModule('ActionMessageUpdatedSuccessfully', ModuleNamesList.ApplicationSettings)
        );
      } else {
        InfoMessageService.error(
          TranslationService.translateModule('ActionMessageUpdateError', ModuleNamesList.ApplicationSettings)
        );
      }
    } catch {
      InfoMessageService.error(
        TranslationService.translateModule('ActionMessageUpdateError', ModuleNamesList.ApplicationSettings)
      );
    }
  };

  const handleDeleteAlias = async (id: number) => {
    setIsLoading(true);

    try {
      const result = await RemoveCreditorAlias(id, props.cancelTokenSource.token);

      if (result.status === Status.Success) {
        const newTableData = tableData.filter((data) => {
          return id !== data.id;
        });

        setTableData(newTableData);

        InfoMessageService.success(
          TranslationService.translateModule('ActionMessageUpdatedSuccessfully', ModuleNamesList.ApplicationSettings)
        );
      } else {
        InfoMessageService.error(
          TranslationService.translateModule('ActionMessageUpdateError', ModuleNamesList.ApplicationSettings)
        );
      }
    } catch {
      InfoMessageService.error(
        TranslationService.translateModule('ActionMessageUpdateError', ModuleNamesList.ApplicationSettings)
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadAliases();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (aliasTypes.length) {
      setAliasesTypeOtions();
    }
    // eslint-disable-next-line
  }, [aliasTypes, tableData]);

  return (
    <>
      <Typography variant={TypographyVariants.H3} class="mb-4" uppercase={true}>
        {TranslationService.translateModule('TitleManageAliases', ModuleNamesList.CreditorsManagment)}
      </Typography>

      <Formik
        initialValues={{
          aliasTypeId: '',
          aliasValue: {},
        }}
        onSubmit={async (values, actions) => {
          setIsLoading(true);

          const submitData = {
            aliasTypeId: parseInt(values.aliasTypeId),
            creditorDomainId: props.creditor.domainId,
            value: JSON.stringify(values.aliasValue),
          };

          try {
            const resultAddAlias = await AddOrUpdateCreditorAlias(submitData);

            if (resultAddAlias.status === Status.Success) {
              await loadAliases();

              InfoMessageService.success(
                TranslationService.translateModule(
                  'ActionMessageUpdatedSuccessfully',
                  ModuleNamesList.ApplicationSettings
                )
              );
            } else {
              InfoMessageService.error(
                TranslationService.translateModule('ActionMessageUpdateError', ModuleNamesList.ApplicationSettings)
              );
            }
          } finally {
            setIsLoading(false);
            actions.setSubmitting(false);
            actions.resetForm();
          }
        }}
      >
        {({ values, handleChange, isSubmitting }) => {
          return (
            <Form className="C-form" autoComplete="off">
              <div className="row">
                <div className="col-4">
                  <FormGroup
                    name="aliasTypeId"
                    label={TranslationService.translateModule('LabelAliasType', ModuleNamesList.CreditorsManagment)}
                    variant={FORM_GROUP_VARIANTS.INLINE}
                    render={(name) => (
                      <>
                        <InputContainer
                          id={name}
                          name={name}
                          type={SettingsType.Select}
                          options={aliasesTypesOptions}
                          value={values.aliasTypeId}
                          eventTypeChange={true}
                          onChange={handleChange}
                          disabled={Object.keys(aliasesTypesOptions).length === 0}
                        />
                      </>
                    )}
                  />
                </div>

                <div className="col-4">
                  {aliasTypes
                    .filter((a) => a.id === parseInt(values.aliasTypeId))
                    .map((alias) =>
                      alias.values.map((v) => {
                        const id = v.name.replace(/\s/g, '-');

                        return (
                          <FormGroup
                            key={v.id}
                            name={v.name}
                            label={v.name}
                            variant={FORM_GROUP_VARIANTS.INLINE}
                            render={(name) => (
                              <>
                                <InputContainer
                                  id={id}
                                  name={`aliasValue.${name}`}
                                  value={values.aliasValue[name]}
                                  disabled={Object.keys(aliasesTypesOptions).length === 0}
                                  type={SettingsType.String}
                                  onChange={(value) => {
                                    const handleChangeEvent = {
                                      target: {
                                        id: `aliasValue.${name}`,
                                        name: `aliasValue.${name}`,
                                        value: value,
                                      },
                                    } as React.ChangeEvent<any>;

                                    handleChange(handleChangeEvent);
                                  }}
                                />
                              </>
                            )}
                          />
                        );
                      })
                    )}
                </div>
              </div>

              <div className="row">
                <div className="col-auto">
                  <Button
                    type="submit"
                    showLoader={isSubmitting}
                    disabled={isSubmitting || Object.keys(aliasesTypesOptions).length === 0}
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTON_SIZE.MD}
                    label={TranslationService.translate('Add')}
                  />

                  {Object.keys(aliasesTypesOptions).length === 0 && (
                    <span className="form-info form-info--alert ml-2">
                      {TranslationService.translateModule('AllAliasesUsed', ModuleNamesList.CreditorsManagment)}
                    </span>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      {isLoading && <Loader opacity={0.5} />}

      <BootstrapTableContainer
        isDataLoading={isLoading}
        remote={false}
        wrapperClasses="bt"
        classes="bt__table bt-table bt--small bt--selectable"
        keyField="id"
        data={tableData}
        columns={tableColumns}
      />
    </>
  );
};

export default withAbortRequest(ManageAliases);
