export interface ICaseListSummary {
  count: number;
  totalBalance: number;
  paidBalance: number;
  remainBalance: number;
}

export const emptyCaseListSummary: ICaseListSummary = {
  count: 0,
  totalBalance: 0,
  paidBalance: 0,
  remainBalance: 0,
};
