export enum InvoicesAgeAnalysisColumns {
  AccountNo,
  CreditorNo,
  CreditorName,
  DebtorNo,
  DebtorName,
  Balance,
  NotDue,
  ZeroTo30,
  ThirtyTo60,
  SixtyTo90,
  NinetyPlus,
}
