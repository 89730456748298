import React from 'react';
import styles from './Input.module.scss';
import CheckboxWrapper from './Checkbox2';
import CheckboxInput from './CheckboxInput';
import RadioWrapper from './RadioWrapper';
import { Radio, RadioVariants } from './Radio';
import RadioLabel from './RadioLabel';
import RadioInput from './RadioInput';
import classNames from 'classnames';

type InputPropsType = {};

class Input extends React.PureComponent<InputPropsType> {
  static CheckboxWrapper = CheckboxWrapper;
  static Checkbox = CheckboxInput;
  static RadioWrapper = RadioWrapper;
  static Radio = Radio;
  static RadioLabel = RadioLabel;
  static RadioInput = RadioInput;
  static RadioVariants = RadioVariants;

  render() {
    return <div className={classNames(styles.input)}>{this.props.children}</div>;
  }
}

export default Input;
