export const emptyCaseSummary = {
  accountNumber: '',
  balanceCapital: 0,
  balanceFees1: 0,
  balanceFees2: 0,
  balanceLegalFees: 0,
  balanceSpecialFees: 0,
  balanceTotalFees: 0,
  caseHandler: '',
  caseNumber: '',
  closeDate: null,
  creditorId: '',
  creditorName: '',
  creditorRef: '',
  installmentPlanId: 0,
  interest: 0,
  interests: 0,
  invoicesNumber: 0,
  isWaitingPayment: false,
  kid: '',
  originalCapital: 0,
  originalClaim: 0,
  originalFees1: 0,
  originalFees2: 0,
  originalLegalFees: 0,
  originalSpecialFees: 0,
  originalTotalFees: 0,
  originalTotalInterest: 0,
  paid: 0,
  paidCapital: 0,
  paidFees1: 0,
  paidFees2: 0,
  paidLegalFees: 0,
  paidSpecialFees: 0,
  paidTotalFees: 0,
  paidTotalInterest: 0,
  paymentOnCase: 0,
  pendingPayment: 0,
  refNumber: '',
  registrationDate: null,
  status: 0,
  totalPayable: 0,
};

export type CaseSummary = typeof emptyCaseSummary;
