import { useState, useEffect } from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import * as React from 'react';

export const DirectPaymentAmount = (props: { maximumAmount: number; onChange: (value: number) => void }) => {
  const [amount, setAmount] = useState(props.maximumAmount);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setAmount(props.maximumAmount);
  }, [props.maximumAmount]);

  const onChangeHandler = (event: any) => {
    const { value } = event.target;

    setAmount(value);
    props.onChange(value);
  };

  return (
    <>
      <div className="d-inline-flex align-items-baseline">
        <input
          id="directPaymentAmount"
          type="text"
          className="form-control"
          value={amount}
          onFocus={() => setTouched(true)}
          onChange={onChangeHandler}
        />
      </div>

      <div
        className={`iddp__validation-error ${(props.maximumAmount >= amount && amount > 0) || !touched ? 'd-none' : null}`}
      >
        {props.maximumAmount < amount
          ? TranslationService.translateModule('PaidAmountMustNotExceedMaximumError', ModuleNamesList.DirectPayment)
          : null}
        {amount <= 0
          ? TranslationService.translateModule('PaidAmountMustBePositiveError', ModuleNamesList.DirectPayment)
          : null}
      </div>
    </>
  );
};
