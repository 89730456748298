import * as React from 'react';
import { useEffect, useState } from 'react';
import { INumberDictionary, IDictionary } from '../../types/IDictionary';

interface ISimpleCheckboxProps {
  name?: string;
  options: INumberDictionary<string> | IDictionary<string> | INumberDictionary<string>[] | IDictionary<string>[];
  asArray?: boolean;
  displayInline?: boolean;
  value: string[];
  onChange: (value: string[]) => void;
}

const SimpleCheckbox = (props: ISimpleCheckboxProps) => {
  const [elements, setElements] = useState<any[]>([]);

  const onChange = (value: any) => {
    const selected = props.value;
    if (selected.includes(value)) {
      const index = selected.indexOf(value);
      selected.splice(index, 1);
    } else {
      selected.push(value);
    }
    props.onChange(selected);
  };

  const getIdName = (name: string | undefined) => {
    let idName = name ? name.replace(/\s/g, '') : '';
    idName = idName.charAt(0).toLowerCase() + idName.substring(1);

    return idName;
  };

  useEffect(() => {
    let elementComputed = [];

    if (props.asArray) {
      elementComputed = (props.options as (INumberDictionary<string> | IDictionary<string>)[]).map((element) => {
        const key = Object.getOwnPropertyNames(element)[0];
        return {
          key,
          value: element[key],
        };
      });
    } else {
      elementComputed = Object.getOwnPropertyNames(props.options).map((key: string, index: number) => {
        return {
          key,
          value: props.options[key],
        };
      });
    }

    setElements(elementComputed);
  }, []);
  return (
    <>
      {elements.map((element) => {
        const idName = getIdName(props.name);

        return (
          <label className={props.displayInline ? 'checkbox-inline' : ''} key={element.key}>
            <input
              id={idName}
              type="checkbox"
              checked={props.value.includes(element.key)}
              value={element.key}
              onChange={() => onChange(element.key)}
            />
            {element.value}
          </label>
        );
      })}
    </>
  );
};

export default SimpleCheckbox;
