import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LocalBootstrapTable } from '../../core/components/BootstrapTable/RemoteBootstrapTable';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import TranslationService from '../../core/services/TranslationService';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { ILanguage } from '../../core/types/ILanguage';
import { IStore } from '../../reducers/IStore';
import Filters from './components/Filters';
import { getColumns } from './helpers/TranslationsManagementTableConfigurator';
import TranslationsManagementService from './services/TranslationsManagementService';
import { TranslationItem } from './types/TranslationItem';
import { TranslationsFilters } from './types/TranslationsFilters';
// import InfoMessageService from '../../core/services/InfoMessageService';

const TranslationsManagement = (props: { languages: ILanguage[] } & IExtendedModuleProps) => {
  const [filters, setFilters] = useState<TranslationsFilters>({ searchValue: '', langCode: 'en-us', moduleName: '' });
  const [translations, setTranslations] = useState<TranslationItem[]>([]);

  const onFilter = async (searchValue: string, langCode: string, moduleName: string) => {
    await setFilters({
      searchValue,
      langCode,
      moduleName: moduleName === 'GLOBAL' ? '' : moduleName,
    });
  };

  const fetchData = async () => {
    const result = await TranslationsManagementService.getTranslations({
      moduleInstanceId: props.module.id,
      languageCode: filters.langCode,
      moduleName: filters.moduleName,
    });

    setTranslations(
      result.filter((value) =>
        filters.searchValue ? doesTranslationContainsSearchPhrase(value, filters.searchValue) : true
      )
    );
  };

  const doesTranslationContainsSearchPhrase = (translation: TranslationItem, searchValue: string) => {
    const phrase = searchValue ? searchValue.toLowerCase() : '';
    const name = translation.name ? translation.name.toLowerCase() : '';
    const val = translation.value ? translation.value.toLowerCase() : '';
    const cust = translation.custom ? translation.custom.toLowerCase() : '';

    return name.indexOf(phrase) > -1 || val.indexOf(phrase) > -1 || cust.indexOf(phrase) > -1;
  };

  useEffect(() => {
    const load = async () => {
      await fetchData();
    };
    load();
    // eslint-disable-next-line
  }, [props, filters]);

  return (
    <article className="l-module">
      <div className="l-module__item">
        <header className="l-module__header">
          <h5 className="l-module__title">
            <i className="fas fa-font mr-2" />
            {TranslationService.translateModule('TranslationsManagementHeader', ModuleNamesList.TranslationsManagement)}
          </h5>
        </header>
      </div>

      <section className="l-module__section l-module__section--filter">
        <Filters languages={props.languages} onFilter={onFilter} />
      </section>

      <section className="l-module__section mt-5">
        <LocalBootstrapTable
          data={translations}
          classes="bt__table bt-table"
          keyField="id"
          wrapperClasses="bt"
          columns={getColumns(props.module.id)}
        />
      </section>
    </article>
  );
};

const mapStateToProps = (state: IStore) => ({
  languages: state.languages,
});

export default connect(mapStateToProps)(TranslationsManagement);
