import { IActionStatus } from '../../../core/api/types/IActionStatus';
import { IComponentTable } from '../../../core/types/IComponentTable';
import InvoiceDetailsDirectPaymentApi from '../api/InvoiceDetailsDirectPaymentApi';
import { GetDirectPaymentInvoicesRequest } from '../types/GetDirectPaymentInvoicesRequest';
import { IInvoiceListElement } from '../types/InvoiceElement';
import { SaveInvoiceDetailsDirectPaymentRequest } from '../types/SaveInvoiceDetailsDirectPaymentRequest';

class InvoiceDetailsDirectPaymentService {
  public async getInvoices(request: GetDirectPaymentInvoicesRequest): Promise<IComponentTable<IInvoiceListElement>> {
    return await InvoiceDetailsDirectPaymentApi.getInvoices(request);
  }
  public async savePayment(request: SaveInvoiceDetailsDirectPaymentRequest): Promise<IActionStatus> {
    return await InvoiceDetailsDirectPaymentApi.saveInvoiceDetailsDirectPayment(request);
  }
}

export default new InvoiceDetailsDirectPaymentService();
