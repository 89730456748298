import { IComponentTable } from '../../../core/types/IComponentTable';
import ActivitiesListApi from '../api/ActivitiesListApi';
import { IActivitiesListRequest } from '../types/IActivitiesListRequest';
import { IActivityListElement } from '../types/IActivityListElement';

class ActivitiesListService {
  public async getActivities(request: IActivitiesListRequest): Promise<IComponentTable<IActivityListElement>> {
    return await ActivitiesListApi.getActivities(request);
  }
}

export default new ActivitiesListService();
