enum TypographyVariants {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H6 = 'h6',
  Span = 'span',
  Paragraph = 'p',
}

export default TypographyVariants;
