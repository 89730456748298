import RestApiClient from './RestApiClient';
import { ICreditor } from '../types/ICreditor';

class CreditorsApiClient {
  public async getCreditorsForLoggedUser() {
    return await RestApiClient.get<ICreditor[]>('UserCreditors');
  }
  public async getActiveCreditorsForLoggedUser() {
    return await RestApiClient.get<ICreditor[]>('UserCreditors/GetUserActiveCreditors');
  }
}

export default new CreditorsApiClient();
