export interface IStandardSearch {
  firstname: string;
  identity: string;
  lastName: string;
  domainId: string;
  debtorType: number;
}

export const emptyStandardSearch: IStandardSearch = {
  firstname: '',
  identity: '',
  lastName: '',
  domainId: '',
  debtorType: 2,
};
