export const PermissionNames = {
  CanAddDirectPayment: 'CanAddDirectPayment',
  CanAddInvoiceDirectPayment: 'CanAddInvoiceDirectPayment',
  CanAddInvoiceMessage: 'CanAddInvoiceMessage',
  CanGetInvoiceActivitiesList: 'CanGetInvoiceActivitiesList',
  CanGetInvoiceMessagesList: 'CanGetInvoiceMessagesList',
  CanGetInvoiceNotesList: 'CanGetInvoiceNotesList',
  CanGetInvoicePaymentsList: 'CanGetInvoicePaymentsList',
  CanGetTimelineActions: 'CanGetTimelineActions',
  CanManageCreditorUsers: 'CanManageCreditorUsers',
  CanPostponeArmInvoice: 'CanPostponeArmInvoice',
  CanReadAgeAnalysisList: 'CanReadAgeAnalysisList',
  CanReadArmTransactionsList: 'CanReadArmTransactionsList',
  CanReadCaseList: 'CanReadCaseList',
  CanReadMessages: 'CanReadMessages',
  CanViewCaseDetails: 'CanViewCaseDetails',
  CanViewInvoiceDetails: 'CanViewInvoiceDetails',
  CanViewReport: 'CanViewReport',
  CanWriteMessages: 'CanWriteMessages',
  CanViewGroupUsers: 'CanViewGroupUsers',
};
