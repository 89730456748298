import React, { useCallback, useState, memo } from 'react';
import { useQuery, useMutation } from 'react-query';
import UserCaseNotesApi from './api/UserCaseNotesApi';
import { Button, BUTTON_VARIANTS } from '../../core/components/Button/Button';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import Loader from '../../core/components/Loading/Loader';
import { CaseNoteType } from './types/CaseNoteType';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';

const UserCaseNotes = (props: IExtendedModuleProps) => {
  const [selectedCaseNote, setSelectedCaseNote] = useState<CaseNoteType>({} as CaseNoteType);

  const userCaseNotes = useQuery(
    ['userCaseNotes'],
    () => UserCaseNotesApi.getUserCaseNotes(props.routeParameters.id as string),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retryOnMount: true,
      onSuccess: (data) => {
        setSelectedCaseNote(data.data);
      },
      onError: () => {
        InfoMessageService.error(TranslationService.translate('GeneralErrorOccured'));
      },
    }
  );

  const addOrUpdateUserNote = useMutation(
    () =>
      UserCaseNotesApi.addOrUpdateUserNote({
        caseId: props.routeParameters.id as string,
        content: selectedCaseNote?.memo as string,
      }),
    {
      onSuccess: () => {
        InfoMessageService.success(TranslationService.translate('ActionEndsWithSuccess'));

        if (props.exit) {
          props.exit();
        }
      },
      onError: () => {
        InfoMessageService.error(TranslationService.translate('ErrorSthWentWrong'));
      },
    }
  );

  const handleOnNoteSubmit = useCallback(() => {
    addOrUpdateUserNote.mutate();
  }, [selectedCaseNote]);

  const handleOnNoteContentChange = useCallback((eventValue: React.ChangeEvent<any>) => {
    const value = eventValue.target.value;
    setSelectedCaseNote((prevSelectedCaseNote) => {
      return {
        ...prevSelectedCaseNote,
        memo: value,
      };
    });
  }, []);

  const handleOnClose = () => {
    if (props.exit) {
      props.exit();
    }
  };

  return (
    <>
      {userCaseNotes.isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <textarea
              value={selectedCaseNote?.memo || ''}
              name="caseNoteContent"
              className="form-control"
              rows={10}
              onChange={handleOnNoteContentChange}
            />
          </div>

          <div className="row">
            <div className="col-auto">
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                showLoader={addOrUpdateUserNote.isLoading}
                disabled={addOrUpdateUserNote.isLoading}
                onClick={handleOnNoteSubmit}
              >
                {TranslationService.translate('Save')}
              </Button>
            </div>

            <div className="col-auto">
              <Button onClick={handleOnClose} disabled={addOrUpdateUserNote.isLoading}>
                {TranslationService.translate('Cancel')}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(UserCaseNotes);
