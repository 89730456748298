import { useEffect } from 'react';

const useEffectAsync = (effect: any, inputs: any[]) => {
  useEffect(() => {
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
};

export default useEffectAsync;
