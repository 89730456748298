import { Dispatch } from 'redux';
import * as ACTIONS from '../actions';
import LeftMenuApiClient from '../core/components/LeftNavMenu/api/LeftMenuApiClient';
import { LeftMenuModel } from '../core/types/LeftMenuModel';

const fetchLeftMenuInProgress = () => ({
  type: ACTIONS.FETCH_LEFTMENU_IN_PROGRESS,
});

const fetchLeftMenuSucceeded = (data: LeftMenuModel) => ({
  type: ACTIONS.FETCH_LEFTMENU_SUCCEEDED,
  leftMenuModel: data,
});

const fetchLeftMenuFailed = () => ({
  type: ACTIONS.FETCH_LEFTMENU_FAILED,
});

export default () => (dispatch: Dispatch<any>) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(fetchLeftMenuInProgress());
    return LeftMenuApiClient.getMenuElements()
      .then((data) => {
        dispatch(fetchLeftMenuSucceeded(data));
        resolve();
      })
      .catch((_) => {
        dispatch(fetchLeftMenuFailed());
        reject();
      });
  });
};
