import { IInvoicesAgeAnalysisSelectFilter } from './IInvoicesAgeAnalysisSelectFilter';

export interface IInvoicesAgeAnalysisRequestFilters {
  filteredBy?: IInvoicesAgeAnalysisSelectFilter;
  filterByValue?: string;
  creditor: string;
}

export const emptyIInvoicesAgeAnalysisRequestFilters: IInvoicesAgeAnalysisRequestFilters = {
  creditor: '',
  filterByValue: undefined,
  filteredBy: undefined,
};
