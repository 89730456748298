import React, { useEffect, useState } from 'react';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { DataSet, Timeline as VisTimeline } from 'vis-timeline/standalone';
import moment from 'moment';

type TimelineNavType = {
  timelineData: any;
  timelineOptions: any;
  timelineMoveLeft: () => void;
  timelineMoveRight: () => void;
};

const TimelineNav = (props: TimelineNavType) => {
  const [rangeTimelineDataSet, setRangeTimelineDataSet] = useState<any>();

  const prepareRangeTimeline = (timelineOptions: any) => {
    let rangeTimelineDataSet: any;

    const diffDate = moment(timelineOptions.maxDate).diff(timelineOptions.minDate, 'days');
    const container = document.getElementById('rangeTimeline');
    const dataTimeline = [
      {
        id: 1,
        start: moment(timelineOptions.minDate).toISOString(true),
        end: moment(timelineOptions.maxDate).toISOString(true),
        className: `range-timeline ${diffDate < 7 ? 'range-timeline--hide-vertical-scroll' : ''}`,
        type: 'background',
      },
    ];
    const options = {
      min: moment(timelineOptions.minDate).toISOString(true),
      max: moment(timelineOptions.maxDate).toISOString(true),
      height: 6,
      showMajorLabels: false,
      showMinorLabels: false,
    };

    rangeTimelineDataSet = new DataSet(dataTimeline);

    if (container) {
      new VisTimeline(container, rangeTimelineDataSet, options);
      setRangeTimelineDataSet(rangeTimelineDataSet);
    }
  };

  const updateRangeTimeline = (data: any) => {
    if (rangeTimelineDataSet) {
      rangeTimelineDataSet.update({
        ...rangeTimelineDataSet.get(1),
        ...data,
      });
    }
  };

  useEffect(() => {
    prepareRangeTimeline(props.timelineOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.timelineData) {
      updateRangeTimeline(props.timelineData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.timelineData]);

  return (
    <>
      <div className="c-timeline__nav">
        <Button
          variant={BUTTON_VARIANTS.TRANSPARENT}
          className="c-btn--icon-only"
          size={BUTTON_SIZE.MD}
          icon={{ className: 'fas fa-caret-left', position: '' }}
          onClick={props.timelineMoveLeft}
        />

        <div id="rangeTimeline" className="c-timeline__scrollbar" />

        <Button
          variant={BUTTON_VARIANTS.TRANSPARENT}
          className="c-btn--icon-only"
          size={BUTTON_SIZE.MD}
          icon={{ className: 'fas fa-caret-right', position: '' }}
          onClick={props.timelineMoveRight}
        />
      </div>
    </>
  );
};

export default TimelineNav;
