import React from 'react';
import { IState, IProps } from './types/IMultiStepFormTypes';
import StepList from '../../../modules/StepListWizard/StepList';
import { Card } from '../Card/Card';
import { CardContent } from '../Card/components/CardContent';
import { CardHeader, CardHeaderVariants } from '../Card/components/CardHeader';

class MultiStepForm extends React.PureComponent<IProps, IState> {
  public state = {
    currentStep: 0,
    isLoaded: false,
    totalSteps: React.Children.count(this.props.children) - 1,
  };

  componentDidMount() {
    this.setState({
      isLoaded: true,
      currentStep: this.props.currentStep || 0,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.currentStep !== prevProps.currentStep) {
      this.setState({
        currentStep: this.props.currentStep,
      });
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return null;
    }

    const children = React.Children.map(this.props.children, (child: any, index) => {
      const { currentStep, totalSteps } = this.state;
      if (index < currentStep) {
        return null;
      }

      return React.cloneElement(child, {
        labels: this.props.labels,
        hideControlBtn: this.props.hideControlBtn,
        isActive: index === currentStep,
        displayPrevious: currentStep > 0,
        displayNext: currentStep < totalSteps,
        displaySubmit: currentStep === totalSteps,
        onSubmit: this.props.onSubmit,
        goToPreviousStep: this.goToPreviousStep,
        goToNextStep: this.goToNextStep,
      });
    });

    return (
      <Card class="mb-4">
        <>
          <CardHeader variant={CardHeaderVariants.Secondary}>
            <StepList stepList={this.props.stepList} currentStep={this.state.currentStep} leftDesktop={true} />
          </CardHeader>
          <CardContent>
            <>
              <div className="c-creator">{children}</div>
            </>
          </CardContent>
        </>
      </Card>
    );
  }

  goToPreviousStep = () => {
    this.setState(
      {
        currentStep: this.state.currentStep - 1,
      },
      () => {
        this.props.onUpdateStep(this.state.currentStep);
      }
    );
  };

  goToNextStep = () => {
    this.setState(
      {
        currentStep: this.state.currentStep + 1,
      },
      () => {
        this.props.onUpdateStep(this.state.currentStep);
      }
    );
  };
}

export default MultiStepForm;
