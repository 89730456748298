import RestApiClient from '../../../api/RestApiClient';
import { LeftMenuModel } from '../../../types/LeftMenuModel';

class LeftMenuApiClient {
  public async getMenuElements(): Promise<LeftMenuModel> {
    return await RestApiClient.get<LeftMenuModel>(`modules/LeftMenu/getMenuElements`);
  }
}

export default new LeftMenuApiClient();
