import RestApiClient from '../../../../../api/RestApiClient';
import { IDictionary } from '../../../../../types/IDictionary';

class CreditorSwitcherApi {
  public async getModuleSettings(): Promise<IDictionary<string>> {
    return await RestApiClient.get<IDictionary<string>>('modules/CreditorSwitcher/getModuleSettings');
  }
}

export default new CreditorSwitcherApi();
