import * as React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { IPaymentDetails } from '../types/IPaymentDetails';
import CurrencyParser from '../../../core/helpers/CurrencyParser';

interface IProps {
  payments: IPaymentDetails;
}

class PaymentOverview extends React.Component<IProps> {
  public render() {
    return (
      <>
        <h6>{TranslationService.translateModule('PaymentsOverview', ModuleNamesList.PrintCase)}</h6>
        <table className="table table-bordered table-sm mb-0">
          <tbody>
            <tr>
              <td>{TranslationService.translateModule('PaymentsOverviewTotalBalance', ModuleNamesList.PrintCase)}</td>
              <td>{CurrencyParser.toLocaleString(this.props.payments.totalBalance)}</td>
            </tr>
            <tr>
              <td>{TranslationService.translateModule('PaymentsOverviewTotalPaid', ModuleNamesList.PrintCase)}</td>
              <td>{CurrencyParser.toLocaleString(-1 * this.props.payments.paidBalance)}</td>
            </tr>
            <tr>
              <td>{TranslationService.translateModule('PaymentsOverviewBalance', ModuleNamesList.PrintCase)}</td>
              <td>{CurrencyParser.toLocaleString(this.props.payments.balance)}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default PaymentOverview;
