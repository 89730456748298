import RestApiClient from '../../../core/api/RestApiClient';
import { ICaseTabsSettings } from '../types/CaseTabsSettings';

class CaseTabsApi {
  public async getModuleSettings(caseId: string, moduleInstanceId: number) {
    return await RestApiClient.get<ICaseTabsSettings>(`modules/CaseTabs/getTabs/${caseId},${moduleInstanceId}`);
  }
}

export default new CaseTabsApi();
