export default [
  {
    code: 'Order',
    color: '#324d5c',
    icon: 'fas fa-envelope-open-text',
  },
  {
    color: '#395667',
    icon: 'fas fa-file-invoice',
    code: 'Invoice',
  },
  {
    color: '#ff8d1c',
    icon: 'fas fa-gavel',
    code: 'Legal',
  },
  {
    color: '#e16652',
    icon: 'far fa-eye',
    code: 'Surveillance',
  },
  {
    color: '#dd5b49',
    icon: 'fas fa-lock-open',
    code: 'Active',
  },
  {
    color: 'var(--brand-color-1)',
    icon: 'fas fa-lock',
    code: 'Closed',
  },
  {
    color: '#0094d4',
    icon: 'fas fa-scroll',
    code: 'Reminder',
  },
  {
    color: '#00ac6e',
    icon: 'fas fa-calendar-times',
    code: 'PreLegal',
  },
  {
    color: '#5da7a2',
    icon: 'far fa-envelope',
    code: 'Messages',
  },
  {
    color: '#54c6d0',
    icon: 'fas fa-file',
    code: 'OrderPaper',
  },
];
