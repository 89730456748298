import * as React from 'react';
import { IUserProperty } from '../types/IUserProperty';
import ManageUserPropsTableConfigurator from '../helpers/ManageUserPropsTableConfigurator';
import { BootstrapTableContainer } from '../../../core/components/BootstrapTable/BootstrapTableContainer';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';

interface IProps {
  userProperties: IUserProperty[];
  removeUserProperty: (id: number) => void;
}

export default class ManageUserProperties extends React.Component<IProps> {
  private rowClasses = (row: IUserProperty) => {
    let classes = '';
    if (row.isRemoved) {
      classes = 'bt-table__row--half-opacity';
    }
    return classes;
  };

  public render() {
    return (
      <div>
        <label className="c-form-group__label">
          {TranslationService.translateModule('PropertiesList', ModuleNamesList.GroupUsersList)}
        </label>
        <BootstrapTableContainer
          remote={true}
          wrapperClasses="bt"
          paginationProps={{}}
          classes="bt__table bt-table"
          keyField="index"
          rowClasses={this.rowClasses}
          data={this.props.userProperties}
          columns={ManageUserPropsTableConfigurator.getTableColumns(this.props.removeUserProperty)}
        />
      </div>
    );
  }
}
