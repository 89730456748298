import * as React from 'react';
import * as Reactstrap from 'reactstrap';
import { IModule } from '../../core/api/types/IModule';
import Loader from '../../core/components/Loading/Loader';
import { ModuleFactory } from '../../core/factories/ModuleFactory';
import { getModalCloseEvent, getModalOpenEvent } from '../../core/helpers/Modal/ModalEvents';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { EventsList } from '../../core/lists/EventsList';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import './Modal.scss';
import ModalService from './services/ModalService';

interface IState {
  isLoading: boolean;
  isOpen: boolean;
  contentModule: IModule;
  modalData?: any;
  otherData?: any;
}

export default class Modal extends React.Component<IExtendedModuleProps, IState> {
  public state: IState = {
    isLoading: false,
    isOpen: false,
    contentModule: {
      id: 0,
      name: '',
    },
  };

  private modalOpenSub: IModuleEventSubscription;
  private modalCloseSub: IModuleEventSubscription;

  public render() {
    const { modalData, isOpen, isLoading, contentModule } = this.state;

    return (
      <>
        {modalData && (
          <Reactstrap.Modal
            isOpen={isOpen}
            className={'flex-modal modal-module modal-lg' + (modalData.className ? ` ${modalData.className}` : '')}
            toggle={() => {
              this.toggle();
            }}
          >
            <Reactstrap.ModalHeader
              toggle={() => {
                this.toggle();
              }}
              className="modal-header"
            >
              {modalData.MODAL_HEADER_ICON && <i className={modalData.MODAL_HEADER_ICON} />}
              {modalData.MODAL_HEADER_TEXT}
            </Reactstrap.ModalHeader>

            <Reactstrap.ModalBody>
              {(isOpen || contentModule.id !== 0) && isLoading ? (
                <Loader opacity={1} />
              ) : (
                <ModuleFactory
                  moduleIndex={0}
                  modules={[contentModule]}
                  routeParameters={this.props.routeParameters}
                  {...{ MODAL_MODULE_INSTANCE_ID: this.props.module.id, ...this.state.otherData }}
                />
              )}
            </Reactstrap.ModalBody>
          </Reactstrap.Modal>
        )}
      </>
    );
  }

  public componentDidMount() {
    this.registerModuleEvents();

    ModalService.getModalContent(this.props.module.id).then((contentModule) => {
      ModuleEventSubscriber.emitEvent({
        name: EventsList.MODULE_LOADED,
        data: {
          moduleName: contentModule.name,
          moduleId: this.props.module.id,
        },
      });
    });
  }

  public componentWillUnmount() {
    ModuleEventSubscriber.unsubscribeEvents([this.modalOpenSub, this.modalCloseSub]);
  }

  public async componentDidUpdate(prevProps: IExtendedModuleProps, prevState: IState) {
    if (this.state.isOpen !== prevState.isOpen && this.state.isOpen && this.state.contentModule.id === 0) {
      await this.loadData();
    }
  }

  private registerModuleEvents() {
    if (!this.modalOpenSub) {
      this.modalOpenSub = {
        name: getModalOpenEvent(this.props.module.id),
        callback: this.configureAndToggle,
      };
    }

    if (!this.modalCloseSub) {
      this.modalCloseSub = {
        name: getModalCloseEvent(this.props.module.id),
        callback: this.toggle,
      };
    }

    ModuleEventSubscriber.registerEvents([this.modalOpenSub, this.modalCloseSub]);
  }

  private configureAndToggle = (data: { modalData: any; otherData: any }) => {
    if (data && Object.keys(data).length !== 0) {
      this.setState(
        {
          modalData: data.modalData,
          otherData: { ...data.otherData, exit: this.close },
        },
        this.toggle
      );
    } else {
      this.toggle();
    }
  };

  private close = (actionSuccess?: boolean) => {
    if (this.state.isOpen) {
      this.toggle(actionSuccess);
    }
  };

  private toggle = (actionSuccess?: boolean) => {
    const isStillOpen = !this.state.isOpen;
    this.setState({
      isOpen: isStillOpen,
    });

    if (isStillOpen) {
      if (this.state.modalData && this.state.modalData.onOpen) {
        this.state.modalData.onOpen();
      }
    } else {
      if (this.state.modalData && this.state.modalData.onClose) {
        this.state.modalData.onClose(actionSuccess);
      }
    }
  };

  private loadData = async () => {
    this.setState({
      isLoading: true,
    });

    try {
      const contentModule = await ModalService.getModalContent(this.props.module.id);

      this.setState({
        isLoading: false,
        contentModule,
      });
    } catch (err: any) {
      this.setState({
        isLoading: false,
      });
    }
  };
}
