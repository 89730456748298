import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../../core/components/Forms/FormGroup';
import { Formik, Form, ErrorMessage } from 'formik';
import { InputContainer } from '../../../core/components/Forms/InputContainer';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { SettingsType } from '../../../core/Enums/SettingsType';
import * as yup from 'yup';
import React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { CreditorOnboardingAgreement, emptyCreditorOnboardingAgreement } from '../types/CreditorOnboardingAgreement';

type Props = {
  data?: CreditorOnboardingAgreement;
  onClose: () => void;
  onSubmit: (data: CreditorOnboardingAgreement) => void;
};

const EditOnboardingAgreement = (props: Props) => {
  const validationSchema = yup.object().shape({
    agreementCode: yup.string().required(TranslationService.translate('ValidationMessageRequiredField')),
    baseCreditorIdentity: yup.string().required(TranslationService.translate('ValidationMessageRequiredField')),
    agreementActivityCode: yup.string().required(TranslationService.translate('ValidationMessageRequiredField')),
  });

  const handleSubmitForm = (dataProvider: any) => {
    props.onSubmit(dataProvider);
  };

  return (
    <>
      <Formik
        onSubmit={handleSubmitForm}
        validationSchema={validationSchema}
        initialValues={props.data ? props.data : { ...emptyCreditorOnboardingAgreement }}
      >
        {({ values, handleChange }) => (
          <Form>
            <div className="row">
              <div className="col-12">
                <FormGroup
                  variant={FORM_GROUP_VARIANTS.INLINE}
                  name="agreementCode"
                  render={(name) => (
                    <div className="row">
                      <div className="col-5 d-flex align-items-center justify-content-end">
                        {TranslationService.translateModule('AgreementCode', ModuleNamesList.ExternalLoginProviders)}
                      </div>

                      <div className="col-7">
                        <InputContainer
                          id={name}
                          name={name}
                          type={SettingsType.String}
                          value={values[name]}
                          onChange={(value) => {
                            const handleChangeEvent = {
                              target: {
                                value,
                                id: name,
                                name: name,
                              },
                            } as React.ChangeEvent<any>;

                            handleChange(handleChangeEvent);
                          }}
                        />

                        <ErrorMessage name={name} component="div" className="error error-message" />
                      </div>
                    </div>
                  )}
                />
              </div>

              <div className="col-12">
                <FormGroup
                  variant={FORM_GROUP_VARIANTS.INLINE}
                  name="baseCreditorIdentity"
                  render={(name) => (
                    <div className="row">
                      <div className="col-5 d-flex align-items-center justify-content-end">
                        {TranslationService.translateModule(
                          'BaseCreditorIdentity',
                          ModuleNamesList.ExternalLoginProviders
                        )}
                      </div>

                      <div className="col-7">
                        <InputContainer
                          id={name}
                          name={name}
                          type={SettingsType.String}
                          value={values[name]}
                          onChange={(value) => {
                            const handleChangeEvent = {
                              target: {
                                value,
                                id: name,
                                name: name,
                              },
                            } as React.ChangeEvent<any>;

                            handleChange(handleChangeEvent);
                          }}
                        />

                        <ErrorMessage name={name} component="div" className="error error-message" />
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="col-12">
                <FormGroup
                  variant={FORM_GROUP_VARIANTS.INLINE}
                  name="agreementActivityCode"
                  render={(name) => (
                    <div className="row">
                      <div className="col-5 d-flex align-items-center justify-content-end">
                        {TranslationService.translateModule(
                          'AgreementActivityCode',
                          ModuleNamesList.ExternalLoginProviders
                        )}
                      </div>

                      <div className="col-7">
                        <InputContainer
                          id={name}
                          name={name}
                          type={SettingsType.String}
                          value={values[name]}
                          onChange={(value) => {
                            const handleChangeEvent = {
                              target: {
                                value,
                                id: name,
                                name: name,
                              },
                            } as React.ChangeEvent<any>;

                            handleChange(handleChangeEvent);
                          }}
                        />

                        <ErrorMessage name={name} component="div" className="error error-message" />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="row justify-content-end">
              <div className="col-auto">
                <Button
                  type="button"
                  id="editProviderCancelBtn"
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTON_SIZE.SM}
                  label={TranslationService.translate('Cancel')}
                  onClick={props.onClose}
                />
              </div>

              <div className="col-auto">
                <Button
                  type="submit"
                  id="editProviderSubmitBtn"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTON_SIZE.SM}
                  label={TranslationService.translateModule('Submit', ModuleNamesList.ExternalLoginProviders)}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditOnboardingAgreement;
