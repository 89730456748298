import InfoMessageService from './InfoMessageService';
import TranslationService from './TranslationService';
import UserIdentityService from './UserIdentityService';

class ErrorResponseHandler {
  public defaultHandler(error: any, forbiddenHandled?: boolean) {
    if (error.response && error.response.data) {
      InfoMessageService.displayActionStatus(error.response.data, true);
    } else if (!this.hasAccess(error)) {
      if (!forbiddenHandled) {
        InfoMessageService.warning(TranslationService.translate('HasNoPermission'));
      }
    } else {
      InfoMessageService.error(TranslationService.translate('GeneralErrorOccured'));
    }

    if (error.response && error.response.status) {
      this.reLoginOnUnauthorized(error.response.status);
    }
  }

  private reLoginOnUnauthorized(status: number) {
    if (status === 401) {
      const userService = new UserIdentityService();
      userService.StartAuthentication(window.location.href);
    }
  }

  public hasAccess(error: any) {
    if (error.response && error.response.status === 403) {
      return false;
    }

    return true;
  }
}

export default new ErrorResponseHandler();
