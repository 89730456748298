/*
 *
 * WILL BE DEPRECATED SOON!
 *
 * Please use InputField component instead this
 *
 */
import React from 'react';

interface ISimpleTextAreaProps {
  className?: string;
  disabled?: boolean;
  id: string;
  name?: string;
  placeholder?: string;
  value?: string;
  onChange: (value: string, e: any) => void;
  onFocus?: () => void;
  onBlur?: (value: string, e: any) => void;
  onKeyUp?: (e: any) => void;
}

/*
 *
 * WILL BE DEPRECATED SOON!
 *
 * Please use InputField component instead this
 *
 */
const SimpleInput = ({
  className = 'c-form-control',
  disabled = false,
  name = '',
  placeholder = '',
  value = '',
  ...props
}: ISimpleTextAreaProps) => {
  const { id, onChange, onFocus, onBlur } = props;

  return (
    <textarea
      id={id}
      className={className}
      name={name}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onKeyUp={(e: any) => {
        if (props.onKeyUp) {
          props.onKeyUp(e);
        }
      }}
      onChange={(e: any) => onChange(e.target.value, e)}
      onBlur={(e: any) => {
        if (onBlur) {
          onBlur(e.target.value, e);
        }
      }}
      onFocus={onFocus}
    />
  );
};

export default SimpleInput;
