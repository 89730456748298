import React from 'react';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';

export default (props: {
  amount: number;
  icon?: string;
  daysRange: string;
  showDaysLabel?: boolean;
  currency: string;
  amountLabel?: string;
}) => (
  <div className="payment-row">
    <div className="payment-row__category">
      {props.icon ? <span className={`${props.icon} fa-2x`} /> : null}
      <strong className="payment-row__category-number">{props.daysRange}</strong>
      {props.showDaysLabel && (
        <span className="payment-row__category--unit">
          {TranslationService.translateModule('Days', ModuleNamesList.Payments)}
        </span>
      )}
    </div>
    <div className="payment-row__tile">
      <h6 className="payment-row__amount">
        {CurrencyParser.toLocaleString(props.amount)} <span className="payment-row__currency">{props.currency}</span>
      </h6>
      <span className="payment-row__sub">{props.amountLabel ? props.amountLabel : ''}</span>
    </div>
  </div>
);
