import InvoicesListApi from '../api/InvoicesListApi';
import { IInvoicesListRequest } from '../types/IInvoicesListRequest';
import { IInvoicesListResponse } from '../types/IInvoicesListResponse';

class InvoicesListService {
  public async GetInvoices(request: IInvoicesListRequest): Promise<IInvoicesListResponse> {
    return await InvoicesListApi.GetInvoicesList(request);
  }
  public async GetInvoicePayments(invoiceLineNumber: string, moduleInstanceId: number, caseId: string) {
    return await InvoicesListApi.GetInvoicePayments(invoiceLineNumber, moduleInstanceId, caseId);
  }
}

export default new InvoicesListService();
