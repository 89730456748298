export enum ModuleNamesList {
  ApplicationSettings = 'ApplicationSettings',
  CaseList = 'CaseList',
  CaseStatisticsCasesOverdue = 'CaseStatisticsCasesOverdue',
  CaseStatisticsInvoiceAging = 'CaseStatisticsInvoiceAging',
  CaseStatisticsPortfolio = 'CaseStatisticsPortfolio',
  CaseStatisticsPortfolioAmounts = 'CaseStatisticsPortfolioAmounts',
  CaseStatisticsSumOfAllCases = 'CaseStatisticsSumOfAllCases',
  CaseSummary = 'CaseSummary',
  CaseTimeline = 'CaseTimeline',
  Cashflow = 'Cashflow',
  CreateNewCase = 'CreateNewCase',
  CreditorAccountSummary = 'CreditorAccountSummary',
  CreditorFileUpload = 'CreditorFileUpload',
  CreditorRegistration = 'CreditorRegistration',
  CreditorsAssignment = 'CreditorsAssignment',
  CreditorsManagment = 'CreditorsManagment',
  CreditorsVerification = 'CreditorsVerifycation',
  CreditorOnboardingAgreements = 'CreditorOnboardingAgreements',
  CreditorSwitcher = 'CreditorSwitcher',
  CustomerTransactionsList = 'CustomerTransactionsList',
  DcaRegisterUser = 'DcaRegisterUser',
  DirectPayment = 'DirectPayment',
  DnnMigration = 'DnnMigration',
  DocumentsList = 'DocumentsList',
  ExternalLoginProviders = 'ExternalLoginProviders',
  GeneratedReports = 'GeneratedReports',
  GeneratedScheduleReports = 'GeneratedScheduleReports',
  GroupUsersAssignment = 'GroupUsersAssignment',
  GroupUsersList = 'GroupUsersList',
  Guarantors = 'Guarantors',
  HomeMessages = 'MessagesHome',
  Invitation = 'Invitation',
  InvoiceDetailsMessages = 'InvoiceDetailsMessages',
  InvoicesAgeAnalysis = 'InvoicesAgeAnalysis',
  InvoicesList = 'InvoicesList',
  LeftMenu = 'LeftMenu',
  ManageModulesInstances = 'ManageModulesInstances',
  ManageUserProperties = 'ManageUserProperties',
  Messages = 'Messages',
  MessageTemplatesManagement = 'MessageTemplatesManagement',
  ModuleSettings = 'ModuleSettings',
  NavbarSearchForm = 'NavbarSearchForm',
  NotesList = 'NotesList',
  OnlineReports = 'OnlineReports',
  OrderPaper = 'OrderPaper',
  PagesList = 'PagesList',
  Payments = 'Payments',
  PortalUsersList = 'PortalUsersList',
  PostponeInvoice = 'PostponeInvoice',
  PrintCase = 'PrintCase',
  RegisterUserModuleName = 'RegisterUser',
  ReportViewer = 'ReportViewer',
  ResetPassword = 'ResetPassword',
  RolesAssignment = 'RolesAssignment',
  RolesManagement = 'RolesManagement',
  ScheduleReport = 'ScheduleReport',
  ScheduleReports = 'ScheduleReports',
  SimulatedBalance = 'SimulatedBalance',
  StylingSettings = 'StylingSettings',
  TransferToDebtCollection = 'TransferToDebtCollection',
  TranslationsManagement = 'TranslationsManagement',
  UnitCases = 'UnitCases',
  UnitIdSearch = 'UnitIdSearch',
  UnitInvoices = 'UnitInvoices',
  UnitOverview = 'UnitOverview',
  Users = 'Users',
  UserCaseNotes = 'UserCaseNotes',
  UsersStatistics = 'UsersStatistics',
  WithdrawCase = 'WithdrawCase',
  ManageLeftMenu = 'ManageLeftMenu',
  ManagePagePermissions = 'ManagePagePermissions',
}
