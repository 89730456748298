import RestApiClient from '../../../core/api/RestApiClient';
import { IInvoicesListRequest } from '../types/IInvoicesListRequest';
import { IInvoicesListResponse } from '../types/IInvoicesListResponse';

class InvoicesListApi {
  public async GetInvoicesList(request: IInvoicesListRequest): Promise<IInvoicesListResponse> {
    return await RestApiClient.post<IInvoicesListResponse>('modules/InvoicesListModule', request);
  }
  public async GetInvoicePayments(invoiceLineNumber: string, moduleInstanceId: number, caseId: string) {
    return await RestApiClient.get<any>('modules/InvoicesListModule/payments', {
      invoiceLineNumber,
      moduleInstanceId,
      caseId,
    });
  }
}

export default new InvoicesListApi();
