import TranslationService from '../../services/TranslationService';
import * as React from 'react';

export default () => {
  return (
    <div className="module">
      <div className="module-body">
        <h5 className="module-title">{TranslationService.translate('NoPermissionsToModuleTitle')}</h5>
        <p>{TranslationService.translate('NoPermissionsToModuleMessage')}</p>
      </div>
    </div>
  );
};
