export enum UnitInvoicesColumns {
  Product,
  DocNo,
  Description,
  RegDate,
  DueDate,
  CaseNo,
  DebtorName,
  Capital,
  OverdueDays,
  Balance,
}
