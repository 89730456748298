import RestApiClient from '../../../core/api/RestApiClient';
import { ExternalLoginProviderType } from '../types/ExternalLoginProviderType';
import { ProvidersResponse } from '../types/ProvidersResponse';
import { IActionStatus } from 'core/api/types/IActionStatus';

class ExternalProviderApiClient {
  public async getExternalLoginProviders(moduleInstanceId: number) {
    return await RestApiClient.get<ProvidersResponse>(
      `modules/ExternalLoginProviders/getProviders/${moduleInstanceId}`
    );
  }

  public async saveExternalLoginProvider(provider: ExternalLoginProviderType, moduleInstanceId: number) {
    return await RestApiClient.post<IActionStatus>(
      `modules/ExternalLoginProviders/saveProvider/${moduleInstanceId}`,
      provider
    );
  }

  public async deleteExternalLoginProvider(loginProvider: string) {
    return await RestApiClient.get<IActionStatus>(`modules/ExternalLoginProviders/removeProvider/${loginProvider}`);
  }
}

export default new ExternalProviderApiClient();
