import { Dispatch } from 'redux';
import * as ACTIONS from '../actions';
import AppSettingsApiClient from '../core/api/AppSettingsApiClient';

const fetchApplicationSettingsFailed = () => ({
  type: ACTIONS.FETCH_APPLICATION_SETTINGS_FAILED,
});

const fetchApplicationSettingsSucceded = (appSettings: any) => ({
  type: ACTIONS.FETCH_APPLICATION_SETTINGS_SUCCEEDED,
  appSettings,
});

const fetchApplicationSettings = () => ({
  type: ACTIONS.FETCH_APPLICATION_SETTINGS_IN_PROGRESS,
});

export default () => (dispatch: Dispatch<any>) => {
  return new Promise<void>(async (resolve, reject) => {
    dispatch(fetchApplicationSettings());

    try {
      const applicationSettings = await AppSettingsApiClient.getApplicationSettings();
      window.document.title = applicationSettings['PortalName'];

      dispatch(fetchApplicationSettingsSucceded(applicationSettings));
      resolve();
    } catch {
      dispatch(fetchApplicationSettingsFailed());
      reject();
    }
  });
};
