import Axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { store } from '../../index';
import UserIdentityService from '../services/UserIdentityService';
import { IFileDetails } from '../types/IFileDetails';
import GlobalConfig from './GlobalConfig';
import ErrorResponseHandler from '../services/ErrorResponseHandler';

const AuthHeader = 'Authorization';
const CreditorsHeader = 'Creditors';
const LanguageCodeHeader = 'LanguageCode';

class RestApiClient {
  private userIdentityService: UserIdentityService | undefined;

  public getCancelToken() {
    return Axios.CancelToken.source();
  }

  public isCancelAxiosRequest(error: any) {
    return Axios.isCancel(error);
  }

  public async get<TModel>(url: string, params?: any, cancelToken?: any, forbiddenHandled?: boolean): Promise<TModel> {
    let config = this.getAxiosConfig();

    if (cancelToken) {
      config = { ...config, cancelToken };
    }

    if (params) {
      config = { ...config, params };
    }

    try {
      const response = await Axios.get<TModel>(url, config);
      if (response.status >= 200 && response.status <= 300) {
        return Promise.resolve(response.data as TModel);
      }

      return Promise.reject(response);
    } catch (error: any) {
      if (Axios.isCancel(error)) {
        // handle error after canceling request
      } else {
        ErrorResponseHandler.defaultHandler(error, forbiddenHandled);
      }

      return Promise.reject(error);
    }
  }

  public async downloadFile(url: string): Promise<any> {
    const file = await this.get<IFileDetails>(url);
    const bytes = Uint8Array.from(atob(file.base64String), (c) => c.charCodeAt(0));
    const blob = new Blob([bytes], { type: file.contentType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = file.fileName;
    link.click();
  }

  public async downloadBlob(url: string, request: any, customConfigs: any = { responseType: 'blob' }): Promise<any> {
    const response = await this.post(url, request, undefined, true, customConfigs);
    return response;
  }

  public async post<TModel>(
    url: string,
    data?: any,
    customHeaders?: any,
    isJsonRequest: boolean = true,
    customConfigs: any = null,
    forbiddenHandled?: boolean,
    cancelToken?: CancelToken
  ): Promise<TModel> {
    let config = this.getAxiosConfig(isJsonRequest);

    if (cancelToken) {
      config = { ...config, cancelToken };
    }

    if (customConfigs) {
      config = { ...config, ...customConfigs };
    }

    if (customHeaders !== undefined && customHeaders !== null) {
      config.headers = customHeaders;
    }

    try {
      const response = await Axios.post<TModel>(url, data, config);
      if (response.status >= 200 && response.status <= 300) {
        return Promise.resolve(response.data as TModel);
      }
      return Promise.reject(response);
    } catch (error: any) {
      ErrorResponseHandler.defaultHandler(error, forbiddenHandled);

      return Promise.reject(error);
    }
  }

  public async put<TModel>(
    url: string,
    data?: any,
    customHeaders?: any,
    isJsonRequest: boolean = true,
    forbiddenHandled?: boolean
  ): Promise<TModel> {
    const config = this.getAxiosConfig(isJsonRequest);

    if (customHeaders !== undefined) {
      config.headers = customHeaders;
    }

    try {
      const response = await Axios.put<TModel>(url, data, config);
      if (response.status >= 200 && response.status <= 300) {
        return Promise.resolve(response.data as TModel);
      }

      return Promise.reject(response);
    } catch (error: any) {
      ErrorResponseHandler.defaultHandler(error, forbiddenHandled);

      return Promise.reject(error);
    }
  }

  public async delete<TModel>(
    url: string,
    customHeaders?: any,
    isJsonRequest: boolean = true,
    forbiddenHandled?: boolean
  ): Promise<TModel> {
    const config = this.getAxiosConfig(isJsonRequest);

    if (customHeaders !== undefined) {
      config.headers = customHeaders;
    }

    try {
      const response = await Axios.delete(url, config);
      if (response.status >= 200 && response.status <= 300) {
        return Promise.resolve(response.data as TModel);
      }

      return Promise.reject(response);
    } catch (error: any) {
      ErrorResponseHandler.defaultHandler(error, forbiddenHandled);

      return Promise.reject(error);
    }
  }

  private getAxiosConfig(isJsonRequest: boolean = true): AxiosRequestConfig {
    const config = {
      baseURL: GlobalConfig.val().GatewayApiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
      transformRequest: (data: any, headers: any) => {
        const token = this.getBearerToken();
        if (token !== '') {
          headers[AuthHeader] = token;
        }
        const creditors = this.getCreditorsJson();
        if (creditors !== '') {
          headers[CreditorsHeader] = creditors;
        }
        const currentLanguageCode = this.getCurrentLocale();

        if (creditors !== '' && !!currentLanguageCode.length) {
          headers[LanguageCodeHeader] = currentLanguageCode;
        }
        if (isJsonRequest) {
          return JSON.stringify(data);
        }
        return data;
      },
    };
    return config;
  }

  private getCreditorsJson(): string {
    if (store !== undefined) {
      const state = store.getState();
      if (state.currentCreditors.length >= 100) {
        return 'all';
      }
      return JSON.stringify(state.currentCreditors);
    }
    return '';
  }

  private getCurrentLocale(): string {
    if (store !== undefined) {
      const state = store.getState();
      return state.currentCultureCode;
    }
    return '';
  }

  private getBearerToken(): string {
    if (!this.userIdentityService) {
      this.userIdentityService = new UserIdentityService();
    }
    return this.userIdentityService.GetAuthorizationHeaderValue();
  }
}

export default new RestApiClient();
