import React from 'react';
import classNames from 'classnames';

type Props = {
  value: any;
  id: any;
  error: [];
  touched: [];
  label?: string;
  className?: any;
  onChange: (valueArray: any) => void;
};

class CheckboxGroup extends React.PureComponent<Props> {
  handleChange = ({ currentTarget }: any) => {
    const checked = currentTarget.checked;
    const value = parseInt(currentTarget.value);
    let valueArray = [...this.props.value] || [];

    if (checked) {
      valueArray.push(value);
    } else {
      valueArray.splice(valueArray.indexOf(value), 1);
    }

    this.props.onChange(valueArray);
  };

  render() {
    const { value, error, touched, label, className, children } = this.props;
    const classes = classNames(
      'input-field',
      {
        'is-success': value || (!error && touched),
        'is-error': !!error && touched,
      },
      className
    );

    return (
      <div className={classes}>
        <fieldset>
          {label && <legend>{label}</legend>}

          <div className="c-control-group c-control-group--wraped">
            {React.Children.map(children, (child: any) => {
              return React.cloneElement(child, {
                field: {
                  checked: value.includes(child.props.value),
                  value: child.props.value,
                  onChange: this.handleChange,
                },
              });
            })}
          </div>

          {touched && <div>{error}</div>}
        </fieldset>
      </div>
    );
  }
}

export default CheckboxGroup;
