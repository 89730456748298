import React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import { IInvoicesAgeAnalysisSummary } from '../types/IInvoicesAgeAnalysisSummary';

export default (props: { summary: IInvoicesAgeAnalysisSummary; currency: string }) => {
  return (
    <>
      <div className="iaal__footer iaal-footer">
        <div className="row iaal-footer__row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-2 mt-bg-0">
            <div className="iaal-footer__box green">
              <div className="icon">
                <i className="far fa-file-alt" />
              </div>
              <div className="box">
                <h3>{props.summary.total}</h3>
                <h4>{TranslationService.translateModule('TotalItems', ModuleNamesList.InvoicesAgeAnalysis)}</h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-2 mt-bg-0">
            <div className="iaal-footer__box">
              <div className="box">
                <div className="iaal-footer__amounts">
                  <h3>{CurrencyParser.toLocaleString(props.summary.totalBalance)}</h3>
                  <h5>{props.currency}</h5>
                </div>
                <h4>{TranslationService.translateModule('TotalBalance', ModuleNamesList.InvoicesAgeAnalysis)}</h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-2 mb-bg-0">
            <div className="iaal-footer__box">
              <div className="box">
                <div className="iaal-footer__amounts">
                  <h3>{CurrencyParser.toLocaleString(props.summary.totalZeroTo30)}</h3>
                  <h5>{props.currency}</h5>
                </div>
                <h4>{TranslationService.translateModule('ZeroTo30', ModuleNamesList.InvoicesAgeAnalysis)}</h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-2 mb-bg-0">
            <div className="iaal-footer__box">
              <div className="box">
                <div className="iaal-footer__amounts">
                  <h3>{CurrencyParser.toLocaleString(props.summary.totalThirtyTo60)}</h3>
                  <h5>{props.currency}</h5>
                </div>
                <h4>{TranslationService.translateModule('ThirtyTo60', ModuleNamesList.InvoicesAgeAnalysis)}</h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-2 mb-bg-0">
            <div className="iaal-footer__box">
              <div className="box">
                <div className="iaal-footer__amounts">
                  <h3>{CurrencyParser.toLocaleString(props.summary.totalSixtyTo90)}</h3>
                  <h5>{props.currency}</h5>
                </div>
                <h4>{TranslationService.translateModule('SixtyTo90', ModuleNamesList.InvoicesAgeAnalysis)}</h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-2 mb-bg-0">
            <div className="iaal-footer__box">
              <div className="box">
                <div className="iaal-footer__amounts">
                  <h3>{CurrencyParser.toLocaleString(props.summary.totalNinetyPlus)}</h3>
                  <h5>{props.currency}</h5>
                </div>
                <h4>{TranslationService.translateModule('NinetyPlus', ModuleNamesList.InvoicesAgeAnalysis)}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
