import { INumberDictionary } from '../../../core/types/IDictionary';

export interface ICaseInitialSettings {
  filterByOptions: INumberDictionary<string>;
  enableDirectPayment: boolean;
  enableWithdrawCase: boolean;
  enableFiltersSection: boolean;
  enableSummarySection: boolean;
  displayedCreditorNumberType: number;
}

export const emptyCaseInitialSettings: ICaseInitialSettings = {
  filterByOptions: {},
  enableDirectPayment: false,
  enableWithdrawCase: false,
  enableFiltersSection: true,
  enableSummarySection: false,
  displayedCreditorNumberType: 1,
};
