import React from 'react';
import StringInput from './inputs/StringInput';
import ColorPickerInput from './inputs/ColorPickerInput';
import ColorPickerArray from './inputs/ColorPickerArray';
import SwitchInput from './inputs/SwitchInput';
import { IAttributesStyling } from './../../../modules/StylingSettings/types/IAttributesStyling';

interface IProps {
  value: string;
  id: number;
  attributes: IAttributesStyling;
  handleChange: (value: string) => void;
}

enum ESettingType {
  String = 'String',
  ColorPicker = 'ColorPicker',
  ColorPickerArray = 'ColorPickerArray',
  Bool = 'Bool',
}

class InputTypeManager extends React.PureComponent<IProps, {}> {
  render() {
    return this.getInputComponent();
  }

  getInputComponent = () => {
    const { name, settingType } = this.props.attributes;
    const { value, handleChange, id } = this.props;
    const params = { name, value, handleChange };

    switch (settingType) {
      case ESettingType.String:
        return <StringInput id={`${id}`} {...params} />;
      case ESettingType.ColorPicker:
        return <ColorPickerInput id={`${id}`} {...params} />;
      case ESettingType.ColorPickerArray:
        return <ColorPickerArray id={`${id}`} {...params} />;
      case ESettingType.Bool:
        return <SwitchInput id={`${id}`} {...params} />;
      default:
        return null;
    }
  };
}

export default InputTypeManager;
