import PagesApiClient from '../../core/api/PagesApiClient';
import { IPage } from '../../core/api/types/IPage';

export class PagesService {
  public async GetPublic(): Promise<IPage[]> {
    return await PagesApiClient.GetPublic();
  }
}

export default new PagesService();
