import * as React from 'react';
import StickyFooter from '../../modules/StickyFooter/StickyFooter';
import Header from '../components/Header/Header';
import { ContainerFactory } from '../factories/ContainerFactory';
import { SkinFactory } from '../factories/SkinFactory';
import Topbar from '../../modules/Topbar/Topbar';
import StyleWrapper from '../../core/components/StyleWrapper/StyleWrapperHOC';

const CentralContainerForLoggedUser = (props: any) => {
  const containers = props.layout.containers;
  return (
    <div className="layout">
      <div className="no-footer-content-wrapper">
        <Topbar />
        <Header />
        <SkinFactory skin={props.layout.skin} routeParameters={props.routeParameters} />
        <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={0} />
      </div>
      <StickyFooter />
    </div>
  );
};

const styleConfig = [
  {
    searchMethod: 'module',
    instructions: {
      backgroundColor: '.layout',
    },
  },
];

export default StyleWrapper('module:skin', styleConfig)(CentralContainerForLoggedUser);
