import InvoiceDetailsApi from '../api/InvoiceDetailsApi';
import { IInvoiceDetailsRequest } from '../types/IInvoiceDetailsRequest';

class InvoiceDetailsService {
  public async getInvoiceDetails(request: IInvoiceDetailsRequest) {
    return await InvoiceDetailsApi.getInvoiceDetails(request);
  }
}

export default new InvoiceDetailsService();
