import { Dispatch } from 'redux';
import * as ACTIONS from '../actions';
import StyleSettingsService from 'core/services/StyleSettingsService';

const fetchStylingSettingsInProgress = () => ({
  type: ACTIONS.FETCH_STYLING_SETTINGS_IN_PROGRESS,
});

const fetchStylingSettingsSucceeded = (data: any) => ({
  type: ACTIONS.FETCH_STYLING_SETTINGS_SUCCEEDED,
  stylingSettings: data,
});

const fetchStylingSettingsFailed = () => ({
  type: ACTIONS.FETCH_STYLING_SETTINGS_FAILED,
});

export default () => (dispatch: Dispatch<any>) => {
  return new Promise<void>(async (resolve, reject) => {
    dispatch(fetchStylingSettingsInProgress());

    try {
      const styleSettings = await StyleSettingsService.getStyleSettings();
      dispatch(fetchStylingSettingsSucceeded(styleSettings));
      resolve();
    } catch {
      dispatch(fetchStylingSettingsFailed());
      reject();
    }
  });
};
