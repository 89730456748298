export enum InvoicesColumns {
  InvoiceNo,
  RegDate,
  DueDate,
  Description,
  OriginalAmount,
  RemainingAmount,
  Movement,
  Currency,
}
